import React, { useEffect } from 'react'
import './HomePage.css'


const panelStyles = {
  heading: {
    fontFamily: "Roboto, sans-serif",
    fontSize: "18px",
    fontWeight: 800,
    lineHeight: "21.09px",
    textAlign: "left",
  },
};

export default function HomePage({ removeFocused, setFocusedMode, showComaprePopup, setCompareMode, showAnalytics, setAnalyticsComponent, removeTags, showPurposeTree, setPurposeTreeMode, setShowHomePage, openAccessManagement, openTeam, openEditTemplate }) {

  // console.log({ removeFocused, setFocusedMode, setCompareMode, setAnalyticsComponent, removeTags, setPurposeTreeMode, setShowHomePage });

  useEffect(() => {
    document.body.classList.add("home-page");

    // Toggle chevron direction
    document.querySelectorAll('.panel-title a').forEach(function (link) {
      link.addEventListener('click', function () {
        const chevron = this.querySelector('.chevron-icon');
        if (chevron) {
          const panel = document.querySelector(this.getAttribute('href'));
          const isCollapsed = panel.classList.contains('in');
          chevron.classList.toggle('fa-chevron-down', !isCollapsed);
          chevron.classList.toggle('fa-chevron-right', isCollapsed);
        }
      });
    });

  });

  return (
    <div className="home-page container-fluid">
      <div className="row home-header">
        <div className="col-md-12">
          <div className="tracking-main">
            <div className="tracking-part">
              <h2>🧰 Toolbox</h2>
              <p>Welcome to teamdecoder! Discover a powerful set of professional tools crafted for team architects like you to help teams thrive. Need to return to this page? Just click on the toolbox icon anytime!</p>
            </div>
          </div>
        </div>
      </div>

      <div className="row home-body">
        <div className="col-md-12">
          <div className="panel-group" id="accordion">
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a data-toggle="collapse" /* data-parent="#accordion" */ href="#collapse1">
                    <i className="chevron-icon fa fa-chevron-down"></i> 01 Dashboard <small>Create, assign and manage roles in the teamdecoder framework</small></a>
                </h4>
              </div>
              <div id="collapse1" className="panel-collapse collapse in">
                <div className="panel-body">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Feature</th>
                          <th>Description</th>
                          <th>Info</th>
                          <th>Go to</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Lists</td>
                          <td>The framework has 7 lists you can adjust to suit your needs.</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/what-do-the-7-lists-stand-for", "_blank")} /></td>
                          <td><a href="#" onClick={() => setShowHomePage(false)}>Dashboard <img src="/images/icon-external-link.png" /></a> <a href="#" onClick={() => window.open("https://teamdecoder.tawk.help/article/personalization", "_blank")}>Personalization <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td>Focus Mode</td>
                          <td>Put a person or a group in focus to assign roles</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/what-is-focus-mode", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Groups</td>
                          <td>Green lists are groups—check them out in the overview modes</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/circles-projects", "_blank")} /></td>
                          <td><a href="#"
                            onClick={() => {
                              removeFocused(); // Exit focus mode
                              setCompareMode({
                                active: false,
                                startComparing: false,
                                compareTypes: [],
                                compareBetween: [],
                              });
                              setAnalyticsComponent(false);
                              removeTags(); // Remove tags
                              setPurposeTreeMode(false);
                              setShowHomePage(false);
                              setFocusedMode("Circle");
                            }}
                          >Circles <img src="/images/icon-external-link.png" /></a> <a href="#"
                            onClick={() => {
                              removeFocused(); // Exit focus mode
                              setCompareMode({
                                active: false,
                                startComparing: false,
                                compareTypes: [],
                                compareBetween: [],
                              });
                              setAnalyticsComponent(false);
                              removeTags(); // Remove tags
                              setPurposeTreeMode(false);
                              setShowHomePage(false);
                              setFocusedMode("Project")
                            }}
                          >Projects <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td>Meetings</td>
                          <td>Gain an overview of all groups' meetings</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/meetings", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Compare</td>
                          <td>Put people or roles next to each other and drag and drop work</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/compare-mode", "_blank")} /></td>
                          <td><a href="#"
                            onClick={() => {
                              showComaprePopup();
                            }}
                          >Compare mode <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td>Teams</td>
                          <td>Create and manage more than one team</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/teams", "_blank")} /></td>
                          <td><a href="#" onClick={openTeam}>Create / Edit teams <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td>Templates</td>
                          <td>Don't start from scratch—find some inspiration!</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/working-with-templates", "_blank")} /></td>
                          <td><a href="#" onClick={openEditTemplate}>Download templates <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                        <tr>
                          <td>Tags & Pills</td>
                          <td>Order your entries by using filters</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/tags", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Fading roles</td>
                          <td>Mark assigned roles as fading-on or fading-out</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/fading-in-out", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Team Transfer</td>
                          <td>Send the team-board to your client</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/team-transfers", "_blank")} /></td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>Co-Admins</td>
                          <td>Invite other people to manage the teams with you</td>
                          <td><img src="/images/icon-info.png" onClick={() => window.open("https://teamdecoder.tawk.help/article/admins-co-admins", "_blank")} /></td>
                          <td><a href="#" onClick={openAccessManagement}>Access Management <img src="/images/icon-external-link.png" /></a></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        removeFocused(); // Exit focus mode
                        setCompareMode({
                          active: false,
                          startComparing: false,
                          compareTypes: [],
                          compareBetween: [],
                        });
                        setAnalyticsComponent(false);
                        removeTags(); // Remove tags
                        setPurposeTreeMode(false);
                        setShowHomePage(false);
                        setFocusedMode("");
                      }}
                    >Go to dashboard</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a data-toggle="collapse" /* data-parent="#accordion" */ href="#collapse2">
                    <i className="chevron-icon fa fa-chevron-down"></i> 02 Workload Planning<small>Plan roles with FTE and manage utilization and prioritization</small></a>
                </h4>
              </div>
              <div id="collapse2" className="panel-collapse collapse in">
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/workloadplanning", "_blank")}>More Info</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a data-toggle="collapse" /* data-parent="#accordion" */ href="#collapse3">
                    <i className="chevron-icon fa fa-chevron-down"></i> 03 Purpose Tree<small>Align company purpose with purposes of each group and role</small></a>
                </h4>
              </div>
              <div id="collapse3" className="panel-collapse collapse in">
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        showPurposeTree();
                      }}
                    >Go to purpose tree</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/purpose-tree", "_blank")}>How to</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a data-toggle="collapse" /* data-parent="#accordion" */ href="#collapse4">
                    <i className="chevron-icon fa fa-chevron-down"></i> 04 Reports<small>Invite all team members to experience clarity (What are my roles?) and transparency (Who does what?)</small></a>
                </h4>
              </div>
              <div id="collapse4" className="panel-collapse collapse in">
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary" onClick={() => window.open("https://app.teamdecoder.com/request-report", "_blank")}>Share this link</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/reports", "_blank")}>How to</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a data-toggle="collapse" /* data-parent="#accordion" */ href="#collapse5">
                    <i className="chevron-icon fa fa-chevron-down"></i> 05 Surveys & Scores<small>Working with teamdecoder improves a team’s wellbeing, resilience and performance. We backed this on an entire set of research. Ask your team to check how well it works!</small></a>
                </h4>
              </div>
              <div id="collapse5" className="panel-collapse collapse in">
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary"
                      onClick={() => {
                        showAnalytics();
                      }}
                    >Go to surveys</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/surveys", "_blank")}>How to</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel panel-default">
              <div className="panel-heading">
                <h4 className="panel-title" style={panelStyles.heading}>
                  <a data-toggle="collapse" /* data-parent="#accordion" */ href="#collapse6">
                    <i className="chevron-icon fa fa-chevron-down"></i> 06 Campfire<small>We live in a world of constant change - teamdecoder supports teams in integrating change into their daily work, Campfire is a regular guided process to assess and think forward.</small></a>
                </h4>
              </div>
              <div id="collapse6" className="panel-collapse collapse in">
                <div className="panel-body">
                  <div className="action-buttons">
                    <button type="button" className="primary" style={{ display: "none" }}>Go to campfire</button>
                    <button type="button" className="secondary" onClick={() => window.open("https://teamdecoder.tawk.help/article/campfire-meetings", "_blank")}>Go to agenda</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row home-footer">
        <div className="col-md-12">
          <div className="tracking-main">
            <div className="tracking-part">
              <h2><img src="/images/icon-restart.png" /> Restart</h2>
              <p>Use the learnings from surveys and campfire to constantly improve roles, groups, workloads and purposes in the dashboard.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
