import React, { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import Navbar from '../../Component/Header/Navbar';
import Footer from '../../Component/Footer/Footer';

function PNF() {
    const navigate = useNavigate();

    const [countdown, setCountdown] = useState(5);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prev) => prev - 1);
        }, 1000);

        // Redirect to home page when countdown reaches 0
        if (countdown === 0) {
            navigate("/");
        }

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, [countdown, navigate]);

    document.body.style = '';
    document.body.classList = 'no-app-pages body1';

    return (
        <div>
            <Navbar />

            <div className="container container d-flex align-items-center justify-content-center main-height">
                <div className="row align-items-center">
                    <div className="col-md-12">
                        <div className="">
                            <form id="mytpt-login" style={{ transform: "translate(0%, 500%)" }}>
                                {/* <img src="images/404.png" style={{ "width": "320px", "margin-top": "30px" }} alt=""/> */}
                                <div className="form-row">
                                    <div className="form-heading text-center">
                                        <h2 style={{ color: "#fff" }}>404 - Page Not Found</h2>
                                        <p style={{ color: "#fff" }}>You will be redirected to the home page in {countdown} seconds...</p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>

    )
}

export default PNF