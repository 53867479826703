import React from 'react'
import './SideNav.css';
import Tippy from '@tippyjs/react';
import { CLIENT_URL } from '../../config/keys';
import { GetAliasesName } from '../../HelperFunctions/GetAliasesName';
import { ALLOW_PURPOSE_TREE, ALLOW_COMPARE_MODE, ALLOW_SURVEY } from '../../config/keys';
import { showUpgradeNotification } from "../../HelperFunctions/AccessControlPopup";
import Swal from 'sweetalert2';

export default function SideNav({ curUserData, myInfo, teams, setFocusedMode, compareMode, focusMode, setCompareMode, analyticsComponent, showAnalytics, setAnalyticsComponent, removeTags, purposeTreeMode, setPurposeTreeMode, showComaprePopup, showHomePage, setShowHomePage, showPurposeTree, setShowPurposeTree, removeFocused, report, reportPlan }) {
    return (
        <>
            {!report ?
                <div className="sidebar" data-html2canvas-ignore="true">
                    <Tippy content={<span>Toolbox</span>} placement="right" theme="custom">
                        <div className={`icon ${showHomePage && !purposeTreeMode ? 'active' : ''}`}
                            onClick={() => {
                                removeFocused(); // Exit focus mode
                                setFocusedMode(""); // Exit Circle/ Project mode
                                setCompareMode({ // Exit Compare mode
                                    active: false,
                                    compareTypes: [],
                                    compareBetween: [],
                                });
                                setAnalyticsComponent(false); // Exit Analytics mode 
                                removeTags(); // Remove tags
                                setPurposeTreeMode(false);
                                setShowHomePage(true); // Show Home page
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/toolbox.png`} alt="Toolbox" />
                        </div>
                    </Tippy>
                    <Tippy content={<span>Dashboard</span>} placement="right" theme="custom">
                        <div className={`icon separate-after ${(!showHomePage && focusMode.length === 0 && !compareMode.active && !purposeTreeMode && !analyticsComponent) ? 'active' : ''}`}
                            onClick={() => {
                                removeFocused(); // Exit focus mode
                                setFocusedMode(""); // Exit Circle/ Project mode
                                setCompareMode({ // Exit Compare mode
                                    active: false,
                                    compareTypes: [],
                                    compareBetween: [],
                                });
                                setAnalyticsComponent(false); // Exit Analytics mode 
                                removeTags(); // Remove tags
                                setPurposeTreeMode(false);
                                setShowHomePage(false); // Show Home page
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/dashboard.png`} alt="Dashboard" />
                        </div>
                    </Tippy>
                    <Tippy content={<span>{GetAliasesName(teams, "Circles")}</span>} placement="right" theme="custom">
                        <div className={`icon ${(focusMode.length > 0 && focusMode === "Circle") ? 'active' : ''}`}
                            onClick={() => {
                                setFocusedMode("Circle");
                                setCompareMode({
                                    active: false,
                                    compareTypes: [],
                                    compareBetween: [],
                                });
                                setAnalyticsComponent(false); // Exit Analytics mode 
                                removeTags(); // Remove tags
                                setPurposeTreeMode(false);
                                setShowHomePage(false); // Hide Home page
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/circles.png`} alt="Circles" />
                        </div>
                    </Tippy>
                    <Tippy content={<span>{GetAliasesName(teams, "Projects")}</span>} placement="right" theme="custom">
                        <div className={`icon ${(focusMode.length > 0 && focusMode === "Project") ? 'active' : ''}`}
                            onClick={() => {
                                setFocusedMode("Project");
                                setCompareMode({
                                    active: false,
                                    compareTypes: [],
                                    compareBetween: [],
                                });
                                setAnalyticsComponent(false); // Exit Analytics mode 
                                removeTags(); // Remove tags
                                setPurposeTreeMode(false);
                                setShowHomePage(false); // Hide Home page
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/projects.png`} alt="Projects" />
                        </div>
                    </Tippy>

                    <Tippy content={<span>Compare</span>} placement="right" theme="custom">
                        <div className={`icon separate-after ${compareMode.active ? 'active' : ''}`}
                            onClick={() => {
                                showComaprePopup();
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/compare.png`} alt="Compare" />

                            {(curUserData.plan === "FREE" && myInfo.role === "User") && !ALLOW_COMPARE_MODE && <img className="padlock" src={`${CLIENT_URL}/images/sidenav-icons/padlock.png`} alt="Upgrade" />}
                        </div>
                    </Tippy>
                    <Tippy content={<span>Purpose Tree</span>} placement="right" theme="custom">
                        <div className={`icon ${purposeTreeMode ? 'active' : ''}`}
                            onClick={() => {
                                showPurposeTree()// Show purpose tree
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/purpose.png`} alt="Purpose Tree" />

                            {(curUserData.plan === "FREE" && myInfo.role === "User") && !ALLOW_PURPOSE_TREE && <img className="padlock" src={`${CLIENT_URL}/images/sidenav-icons/padlock.png`} alt="Upgrade" />}
                        </div>
                    </Tippy>
                    <Tippy content={<span>Workflows</span>} placement="right" theme="custom">
                        <div className="icon hide">
                            <img src={`${CLIENT_URL}/images/sidenav-icons/workflows.png`} alt="Workflows" />
                            <img className="padlock" src={`${CLIENT_URL}/images/sidenav-icons/padlock.png`} alt="Upgrade" />
                        </div>
                    </Tippy>
                    <Tippy content={<span>Surveys</span>} placement="right" theme="custom">
                        <div className={`icon ${analyticsComponent ? 'active' : ''}`}
                            onClick={() => {
                                showAnalytics();
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/scores.png`} alt="Surveys" />

                            {(curUserData.plan === "FREE" && myInfo.role === "User") && !ALLOW_SURVEY && <img className="padlock" src={`${CLIENT_URL}/images/sidenav-icons/padlock.png`} alt="Upgrade" />}
                        </div>
                    </Tippy>
                    <Tippy content={<span>Campfire</span>} placement="right" theme="custom">
                        <div className="icon hide">
                            <img src={`${CLIENT_URL}/images/sidenav-icons/campfire.png`} alt="Campfire" />
                            <img className="padlock" src={`${CLIENT_URL}/images/sidenav-icons/padlock.png`} alt="Upgrade" />
                        </div>
                    </Tippy>
                </div>
                :
                <div className="sidebar" data-html2canvas-ignore="true">
                    <Tippy content={<span>Report</span>} placement="right" theme="custom">
                        <div className={`icon ${!showPurposeTree ? 'active' : ''}`}
                            onClick={() => {
                                setShowPurposeTree(false);
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/dashboard.png`} alt="Report" />
                        </div>
                    </Tippy>
                    <Tippy content={<span>Purpose Tree</span>} placement="right" theme="custom">
                        <div className={`icon ${showPurposeTree ? 'active' : ''}`}
                            onClick={() => {
                                if (reportPlan && reportPlan === "FREE" && !ALLOW_PURPOSE_TREE) {
                                    Swal.fire({
                                        title: "Access Restricted",
                                        text: "This feature is included in a higher plan. Please reach out to your admin to request an upgrade and unlock this functionality.",
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        confirmButtonText: "Upgrade",
                                        cancelButtonText: "Close",
                                        cancelButtonColor: "#EE7364",
                                    });
                                } else {
                                    setShowPurposeTree(true);
                                }
                            }}>
                            <img src={`${CLIENT_URL}/images/sidenav-icons/purpose.png`} alt="Purpose Tree" />

                            {reportPlan && reportPlan === "FREE" && !ALLOW_PURPOSE_TREE && <img className="padlock" src={`${CLIENT_URL}/images/sidenav-icons/padlock.png`} alt="Upgrade" />} 
                        </div>
                    </Tippy>
                </div>
            }
        </>

    )
}
