import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from '../../store/types/authType'
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2'
import Navbar from '../../Component/Header/Navbar';
import Footer from '../../Component/Footer/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CreateMany } from '../../store/actions/authAction'
import { CLIENT_URL } from '../../config/keys';

export default function OnboardingWizard(data) {
    const imageArr = data.data;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth);

    const [loadings, setLoadings] = useState(false)

    useEffect(() => {
        if (successMessage) {
            dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            // console.log(successMessage)
            if (show === 'people') {
                setShow('skills')
            } else if (show === 'skills') {
                setShow('roles')
            } else if (show === 'roles') {
                setShow('domains')
            } else if (show === 'domains') {
                setShow('links')
            } else if (show === 'links') {
                setShow('circles')
            } else if (show === 'circles') {
                setShow('projects')
            } else if (show === 'projects') {
                setShow('finish')
            }
        }

        if (error) {
            Swal.fire({
                title: "Error",
                showClass: {
                    popup: "animate_animated animate_fadeInDown",
                },
                hideClass: {
                    popup: "animate_animated animate_fadeOutUp",
                },
                text: error,
            }).then((response) => {
                dispatch({ type: ERROR_CLEAR })
                setLoadings(false)
            });
        }
    }, [successMessage, error])

    useEffect(() => {
        if (!authenticate) {
            navigate('/login');
        }

        if (authenticate) {
            dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            document.body.style = "background-image: url(" + imageArr.image + ")";
        }
    }, [authenticate]);

    const [show, setShow] = useState('welcome');

    useEffect(() => {
        document.body.classList.add('no-app-pages');
        if (localStorage.getItem('show')) {
            setShow(localStorage.getItem('show'));
        }
    }, [])

    useEffect(() => {
        localStorage.setItem('show', show);
    }, [show])

    const getWidth = () => {
        let width = '0%';
        if (show === 'people') {
            width = (14.28 * 1) + '%';
        }
        else if (show === 'skills') {
            width = (14.28 * 2) + '%';
        }
        else if (show === 'roles') {
            width = (14.28 * 3) + '%';
        }
        else if (show === 'domains') {
            width = (14.28 * 4) + '%';
        }
        else if (show === 'links') {
            width = (14.28 * 5) + '%';
        }
        else if (show === 'circles') {
            width = (14.28 * 6) + '%';
        }
        else if (show === 'projects') {
            width = '100%';
        }
        return width;
    }

    const [people, setPeople] = useState([
        { id: 1, fname: '', lname: '', email: '' },
        { id: 2, fname: '', lname: '', email: '' },
        { id: 3, fname: '', lname: '', email: '' }
    ]);

    const [skills, setSkills] = useState([
        { id: 1, skillName: '' },
        { id: 2, skillName: '' },
        { id: 3, skillName: '' },
    ]);

    const [roles, setRoles] = useState([
        { id: 1, roleName: '' },
        { id: 2, roleName: '' },
        { id: 3, roleName: '' },
    ]);

    const [domains, setDomains] = useState([
        { id: 1, domainName: '' },
        { id: 2, domainName: '' },
        { id: 3, domainName: '' },
    ]);

    const [links, setLinks] = useState([
        { id: 1, linkName: '' },
        { id: 2, linkName: '' },
        { id: 3, linkName: '' },
    ]);

    const [circles, setCircles] = useState([
        { id: 1, circleName: '' },
        { id: 2, circleName: '' },
        { id: 3, circleName: '' },
    ]);

    const [projects, setProjects] = useState([
        { id: 1, projectName: '' },
        { id: 2, projectName: '' },
        { id: 3, projectName: '' },
    ]);

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        if (['fname', 'lname', 'email'].includes(name)) {
            const list = [...people];
            list[index][name] = value;
            setPeople(list);
        } else if (name === 'skillName') {
            const list = [...skills];
            list[index][name] = value;
            setSkills(list);
        } else if (name === 'roleName') {
            const list = [...roles];
            list[index][name] = value;
            setRoles(list);
        } else if (name === 'domainName') {
            const list = [...domains];
            list[index][name] = value;
            setDomains(list);
        } else if (name === 'linkName') {
            const list = [...links];
            list[index][name] = value;
            setLinks(list);
        } else if (name === 'circleName') {
            const list = [...circles];
            list[index][name] = value;
            setCircles(list);
        } else if (name === 'projectName') {
            const list = [...projects];
            list[index][name] = value;
            setProjects(list);
        }
    };

    const handleAddRow = (type) => {
        if (type === 'people') {
            setPeople([...people, { id: people.length + 1, fname: '', lname: '', email: '' }]);
        } else if (type === 'skill') {
            setSkills([...skills, { id: skills.length + 1, skillName: '' }]);
        } else if (type === 'role') {
            setRoles([...roles, { id: roles.length + 1, roleName: '' }]);
        } else if (type === 'domain') {
            setDomains([...domains, { id: domains.length + 1, domainName: '' }]);
        } else if (type === 'link') {
            setLinks([...links, { id: links.length + 1, linkName: '' }]);
        } else if (type === 'circle') {
            setCircles([...circles, { id: circles.length + 1, circleName: '' }]);
        } else if (type === 'project') {
            setProjects([...projects, { id: projects.length + 1, projectName: '' }]);
        }
    };

    const checkEmptyValues = (array) => {
        for (let obj of array) {
            for (let key in obj) {
                if (key !== 'id' && obj[key] !== '') {
                    return true;
                }
            }
        }
        return false;
    };

    let teamId = localStorage.getItem('teamId');
    let authToken = localStorage.getItem('authToken');
    let adminId = myInfo.userId;
    const createMany = (type) => {
        if (type === 'people') {
            if (checkEmptyValues(people)) {
                dispatch(CreateMany({ teamId: teamId, adminId: adminId, token: authToken, type: 'people', data: people }))
            } else {
                setShow('skills')
            }
        } else if (type === 'skills') {

            // console.log(checkEmptyValues(skills))
            if (checkEmptyValues(skills)) {
                dispatch(CreateMany({ teamId: teamId, adminId: adminId, token: authToken, type: 'skills', data: skills }))
                // console.log("hi")
            } else {
                setShow('roles')
            }
        } else if (type === 'roles') {
            if (checkEmptyValues(roles)) {
                dispatch(CreateMany({ teamId: teamId, adminId: adminId, token: authToken, type: 'roles', data: roles }))
            } else {
                setShow('domains')
            }
        } else if (type === 'domains') {
            if (checkEmptyValues(domains)) {
                dispatch(CreateMany({ teamId: teamId, adminId: adminId, token: authToken, type: 'domains', data: domains }))
            } else {
                setShow('links')
            }

        } else if (type === 'links') {
            if (checkEmptyValues(links)) {
                dispatch(CreateMany({ teamId: teamId, adminId: adminId, token: authToken, type: 'links', data: links }))
            } else {
                setShow('circles')
            }
        } else if (type === 'circles') {

            if (checkEmptyValues(circles)) {
                dispatch(CreateMany({ teamId: teamId, adminId: adminId, token: authToken, type: 'circles', data: circles }))
            } else {
                setShow('projects')
            }
        } else if (type === 'projects') {
            if (checkEmptyValues(projects)) {
                dispatch(CreateMany({ teamId: teamId, adminId: adminId, token: authToken, type: 'projects', data: projects }))
            } else {
                setShow('finish')
            }
        }
    };

    const handleCancel = () => {
        localStorage.removeItem('show');
        window.open(CLIENT_URL, '_self');
    };

    return (
        <div className="onboarding-wizard full-height d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row">
                    <div className={`${show !== 'finish' ? 'col-md-2 col-sm-12 mb-5' : 'mb-5'}`}></div>
                    <div className={`${show !== 'finish' ? 'col-md-8 col-sm-12' : 'col-md-12 col-sm-12'}`}>
                        <div className="login-from">
                            <form>
                                <div className={`progress-bar ${show == 'welcome' || show == 'finish' ? 'hide' : ''}`}><div className='progress' style={{ width: getWidth() }}></div></div>

                                <div id="welcome" className={`tab-content ${show !== 'welcome' ? 'hide' : ''}`}>
                                    <h1>Welcome to<br />teamdecoder!</h1>
                                    <p><b>Let's get you started...</b></p>

                                    <div className="actions">
                                        {/* <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button> */}

                                        <button style={{ width: 'max-content' }} type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('people')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Start onboarding'}
                                        </button>
                                    </div>
                                </div>

                                <div id="people" className={`tab-content ${show !== 'people' ? 'hide' : ''}`}>
                                    <h1>Who is in your team?</h1>
                                    <p>Let's start with the most important part of your team. The people.</p>
                                    <p>No worries - this information is app internal only, nobody will receive a notification after this. Their email addresses are important though - this will allow them to access their reports later.</p>
                                    <div className='wrapper-two'>
                                        {people.map((person, index) => (
                                            <div className="repeater row" key={index}>
                                                <div className="col-sm-2">Person {person.id}</div>
                                                <div className="col-sm-3">
                                                    <input type="text" placeholder="First Name" name='fname' value={person.fname} autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="text" placeholder="Family Name" name='lname' value={person.lname} autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                                <div className="col-sm-3">
                                                    <input type="email" placeholder="Email" name='email' value={person.email} autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12" style={{ paddingLeft: '18%' }}><a className="add-more" onClick={() => handleAddRow('people')}>+ Add more</a></div>
                                    </div>
                                    {/* <div className="row">
                                        <div className="col-sm-2" style={{ paddingLeft: '5px' }}></div>
                                        <div className="col-sm-10" style={{ paddingLeft: '5px' }}><a className="add-more" onClick={() => handleAddRow('people')}>+ Add more</a></div>
                                    </div> */}

                                    <p className="help-text">PS: You can always change everything you enter now and add more content later!</p>

                                    <div className="actions">
                                        <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button>

                                        {/* <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('skills')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Skip this step'}
                                        </button> */}

                                        <button type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => createMany('people')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Next step'}
                                        </button>
                                    </div>
                                </div>

                                <div id="skills" className={`tab-content ${show !== 'skills' ? 'hide' : ''}`}>
                                    <h1>What is your team<br />capable of doing?</h1>
                                    <p>This is what we call “skills” - the combined team power! What are the talents (e.g. design), educations (e.g. no-code) or offers (e.g. newsletter creation) in your team? You can also add skills that you think you will need in the future, but that are not part of your team today.</p>
                                    <div className='wrapper-two'>
                                        {skills.map((skill, index) => (
                                            <div className="repeater row">
                                                <div className="col-md-12">
                                                    <input type="text" placeholder={index == 0 ? `e.g. 'Design' or 'Copywriting'` : ''} name='skillName' autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ paddingLeft: '4%' }}><a className="add-more" onClick={() => handleAddRow('skill')}>+ Add more</a></div>
                                    </div>

                                    {/* <p className="help-text">PS: You can always change everything you enter now and add more content later!</p> */}

                                    <div className="actions">
                                        <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button>

                                        {/* <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('roles')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Skip this step'}
                                        </button> */}

                                        <button type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => createMany('skills')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Next step'}
                                        </button>
                                    </div>
                                </div>

                                <div id="roles" className={`tab-content ${show !== 'roles' ? 'hide' : ''}`}>
                                    <h1>What roles<br />do you have or need?</h1>
                                    <p>A role is a “bundle of tasks” for a specific area of work. That can be hierarchy (e.g. Head of Marketing), management (e.g. project manager), administration (e.g. feel good manager) or support (e.g. customer success) - anything your team needs to do, to achieve its goals. Again: Picture the status quo and/or what you envision for your team in the near future.</p>
                                    <div className='wrapper-two'>
                                        {roles.map((role, index) => (
                                            <div className="repeater row">
                                                <div className="col-md-12">
                                                    <input type="text" placeholder={index == 0 ? `e.g. 'Project Manager' or 'Office Manager'` : ''} name='roleName' autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ paddingLeft: '4%' }}><a className="add-more" onClick={() => handleAddRow('role')}>+ Add more</a></div>
                                    </div>

                                    {/* <p className="help-text">PS: You can always change everything you enter now and add more content later!</p> */}

                                    <div className="actions">
                                        <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button>

                                        {/* <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('domains')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Skip this step'}
                                        </button> */}

                                        <button type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => createMany('roles')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Next step'}
                                        </button>
                                    </div>
                                </div>

                                <div id="domains" className={`tab-content ${show !== 'domains' ? 'hide' : ''}`}>
                                    <h1>Clear agreements make long friendship!</h1>
                                    <p>To avoid conflict and misunderstandings some things (like budgets, some important excel tables, key cards to access the safe, a consumer persona or the admin login for the meta ad account, etc.)  need to be made the responsibility of one person only. That does not mean, that only this person can work with the (what we call) “domain”, but this person needs to manage it.</p>
                                    <div className='wrapper-two'>
                                        {domains.map((domain, index) => (
                                            <div className="repeater row">
                                                <div className="col-md-12">
                                                    <input type="text" placeholder={index == 0 ? `e.g. 'Marketing Budget' or 'Sustainability KPIs'` : ''} name='domainName' autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ paddingLeft: '4%' }}><a className="add-more" onClick={() => handleAddRow('domain')}>+ Add more</a></div>
                                    </div>

                                    {/* <p className="help-text">PS: You can always change everything you enter now and add more content later!</p> */}

                                    <div className="actions">
                                        <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button>

                                        {/* <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('links')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Skip this step'}
                                        </button> */}

                                        <button type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => createMany('domains')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Next step'}
                                        </button>
                                    </div>
                                </div>

                                <div id="links" className={`tab-content ${show !== 'links' ? 'hide' : ''}`}>
                                    <h1>Every team <br />needs a network!</h1>
                                    <p>In the teamdecoder framework we call this “links”. A link is the connection between your team and other teams in the company or stakeholder, partners, suppliers, communities outside of your organization. To where should your team establish, manage and nurture good relationships?</p>
                                    <div className='wrapper-two'>
                                        {links.map((link, index) => (
                                            <div className="repeater row">
                                                <div className="col-md-12">
                                                    <input type="text" placeholder={index == 0 ? `e.g. 'to CEO's Office' or 'to Investor X'` : ''} name='linkName' autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ paddingLeft: '4%' }}><a className="add-more" onClick={() => handleAddRow('link')}>+ Add more</a></div>
                                    </div>

                                    {/* <p className="help-text">PS: You can always change everything you enter now and add more content later!</p> */}

                                    <div className="actions">
                                        <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button>

                                        {/* <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('circles')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Skip this step'}
                                        </button> */}

                                        <button type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => createMany('links')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Next step'}
                                        </button>
                                    </div>
                                </div>

                                <div id="circles" className={`tab-content ${show !== 'circles' ? 'hide' : ''}`}>
                                    <h1>Teamwork makes <br />the team work.</h1>
                                    <p>Working alone in a role or a skill or with a domain or link can be effective, but sometimes we need to team up, build groups, to bring different talents together and achieve better results. These “groups” are called “circles” in the teamdecoder framework. For you, this can be departments, task forces, units, etc. But the question is: What circles does your team really need to work best?</p>
                                    <div className='wrapper-two'>
                                        {circles.map((circle, index) => (
                                            <div className="repeater row">
                                                <div className="col-md-12">
                                                    <input type="text" placeholder={index == 0 ? `e.g. 'AI Task Force' or 'Social Media Team'` : ''} name='circleName' autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ paddingLeft: '4%' }}><a className="add-more" onClick={() => handleAddRow('circle')}>+ Add more</a></div>
                                    </div>

                                    {/* <p className="help-text">PS: You can always change everything you enter now and add more content later!</p> */}

                                    <div className="actions">
                                        <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button>

                                        {/* <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('projects')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Skip this step'}
                                        </button> */}

                                        <button type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => createMany('circles')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Next step'}
                                        </button>
                                    </div>
                                </div>

                                <div id="projects" className={`tab-content ${show !== 'projects' ? 'hide' : ''}`}>
                                    <h1>Last but not least:<br />Projects.</h1>
                                    <p>While teamdecoder is not a project management tool, it makes sense to integrate larger, longer running projects into your role-clarification board. This will help you to realize, if your team is equipped with the right skills, roles, domains, links and circles to manage the kind of projects you do today and in the future. This area can also be used as “sub-circles”, like “social media” could be a project to the “marketing” circle. What are the important projects for your team?</p>
                                    <div className='wrapper-two'>
                                        {projects.map((project, index) => (
                                            <div className="repeater row">
                                                <div className="col-md-12">
                                                    <input type="text" placeholder={index == 0 ? `e.g. 'Strategy 2050 Development' or 'Summer Sales Initiative'` : ''} name='projectName' autoComplete="off" onChange={e => handleChange(index, e)} />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12" style={{ paddingLeft: '4%' }}><a className="add-more" onClick={() => handleAddRow('project')}>+ Add more</a></div>
                                    </div>

                                    {/* <p className="help-text">PS: You can always change everything you enter now and add more content later!</p> */}

                                    <div className="actions">
                                        <button type="button" className="button cancel" disabled={loadings ? 'disabled' : ''} onClick={handleCancel}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Cancel wizard'}
                                        </button>

                                        {/* <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => setShow('finish')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Skip this step'}
                                        </button> */}

                                        <button type="button" className="button" disabled={loadings ? 'disabled' : ''} onClick={() => createMany('projects')}>
                                            {loadings ?
                                                <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                : 'Next step'}
                                        </button>
                                    </div>
                                </div>

                                <div id="finish" className={`tab-content ${show !== 'finish' ? 'hide' : ''}`}>
                                    <h1>That’s it folks ;)</h1>
                                    {/* <p>In the next step you will be brought to the teamdecoder dashboard - showing the 7 lists of our framework, pre-filled with the content you just created.</p> */}

                                    {/* <p><b>Click on each item to change it and to add more depth, like purpose and tasks.
                                        And use the focus mode <span><img alt="" src="/images/newicons/focus.svg" /></span> to attribute skills, roles, domains and links to people, circles and projects.</b></p> */}
                                    <div className="row">
                                        <div className="col-md-6" style={{paddingRight: "30px"}}>
                                            <p>
                                                In the next step you will be brought to the teamdecoder dashboard - showing the 7 lists of our framework, pre-filled with the content you just created.
                                            </p>

                                            <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>1. If the suggested role categories don’t fit your use case, feel free to <u>rename the lists</u> to suit your needs.
                                                <span onClick={() => window.open("https://teamdecoder.tawk.help/article/personalization ", "_blank")}>{" "}<img src="/images/icon-info.png" style={{ width: "18px", verticalAlign: "baseline" }} /></span>
                                            </p>

                                            <p style={{ fontWeight: "bold", fontSize: "1.2rem" }}>2. Assign roles to people, circles and projects by clicking on the <u>focus icon:</u>
                                                {" "}<span className="focus"><img alt="" src="/images/newicons/focus.svg" /></span>
                                                {" "}<span onClick={() => window.open("https://teamdecoder.tawk.help/article/what-is-focus-mode", "_blank")}>{" "}<img src="/images/icon-info.png" style={{ width: "20px", verticalAlign: "middle" }} /></span>
                                            </p>

                                            {/* <p>Any question? Do not hesitate to use the livechat at the bottom right of every page, check out our <a href="https://teamdecoder.kb.help/" rel="noopener nofollow" target="_blank">knowledge base</a> or write to <a href="mailto:helpdesk@teamdecoder.com">helpdesk@teamdecoder.com</a></p> */}
                                        </div>
                                        <div className="col-md-6">
                                            <div className="iframe-container">
                                                <iframe width="560" height="315" src="https://www.youtube.com/embed/5zTDDpK06KE?si=Td9NOpAbKReK_b9J&loop=1&autoplay=1&rel=0&mute=1&controls=1&frameborder=0&playlist=5zTDDpK06KE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                                            </div>
                                        </div>

                                        <div className="col-md-12" style={{marginTop: "40px"}}>
                                            <p style={{ textAlign: 'center' }}><b>Enjoy teamdecoder - Sweet teams are made of this!<br />We’re really happy to have you!</b></p>

                                            <div className="actions">
                                                <button type="button" className="button skip" disabled={loadings ? 'disabled' : ''} onClick={() => { localStorage.removeItem('show'); window.open('/?show=dashboard', '_self') }}>
                                                    {loadings ?
                                                        <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                        : 'Go to dashboard'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div >
                    <div className={`${show !== 'finish' ? 'col-md-2 col-sm-12 mb-5' : 'mb-5'}`}></div>
                </div >
            </div >
        </div >
    )
}
