import React, { useEffect, useState } from 'react';
import Modal from "react-modal";
import { userSessionResetEmail, userUpdate } from '../../store/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER_URI } from "../../config/keys";
import { clearLocalStorage } from "../../HelperFunctions/ClearLocalStorage";
import { getTeam } from '../../store/actions/teamAction';
import { getAccessManagemenet } from '../../store/actions/accessManagementAction';
import { SIGN_OUT } from '../../store/types/authType';
import { CLIENT_URL } from '../../config/keys';
import Swal from 'sweetalert2';

const Permission_ModePopUp = ({ teamData, setHandel, openTeam, openEditTemplate }) => {
    const dispatch = useDispatch();
    const {
        loading,
        successMessage,
        paySuccessMessage,
        error,
        authenticate,
        myInfo,
        curUserData,
        loginHistory,
    } = useSelector((state) => state.auth);
    const { teams } = useSelector((state) => state.teams);

    const [open, setOpen] = useState(false);
    const [isLoggedout, setIsLoggedOut] = useState(false);
    const [startPolling, setStartPolling] = useState(false);
    const [waitingPopup, setWaitingPopup] = useState(false);

    useEffect(() => {
        let openData = localStorage.getItem("modeSwitchPopUp");
        if (openData === "true") {
            localStorage.setItem("mode", "View");
            setOpen(true);
        } else if (openData === "false") {
            setWaitingPopup(false);
            localStorage.setItem("mode", "Edit");
            setOpen(false);
        }
    }, [localStorage.getItem("modeSwitchPopUp")]);

    const handleMode = (mode) => {
        // localStorage.setItem("mode", mode);
        if (mode === "View") {
            /* Add View Mode styles */
            document.body.classList.add('view-mode-body');
            const viewModeDiv = document.createElement('div');
            viewModeDiv.textContent = 'View Mode';
            viewModeDiv.classList.add('view-mode-text');
            document.body.appendChild(viewModeDiv);

            // Add overlay to all modal bodies
            document.querySelectorAll('.modal-body').forEach(modalBody => {
                modalBody.classList.add('modal-overlay');
            });

            setOpen(false);
        } else if (mode === "Edit") {
            setWaitingPopup(true);
            dispatch(userSessionResetEmail({ editRequest: true, teamId: curUserData.curTeam }))
            // Api Call And Start Polling
            setStartPolling(true);
        }
    };

    const SignOut = (e) => {
        // console.log("***************logout******************")
        let urls = `${SERVER_URI}/admin/update`;

        dispatch(
            userUpdate({
                adminId: curUserData._id,
                url: urls,
                teamId: curUserData.curTeam,
                sessionToken: "",
                logout: true,
            })
        ).then(() => {
            localStorage.removeItem("authToken");
            clearLocalStorage();
            setIsLoggedOut(true);
            document.body.classList = "no-app-pages body2 menu2";
            window.location.reload();
        });
    };

    useEffect(() => {
        if (isLoggedout) {
            setTimeout(() => {
                dispatch({ type: SIGN_OUT });
                window.location.href = CLIENT_URL + "/login";
            }, 1000);
        }
    }, [isLoggedout, setIsLoggedOut]);

    useEffect(() => {
        if (startPolling) {
            /* longpolling - interval time */
            const IntervalId = setInterval(() => {
                // API call
                dispatch(getTeam({ userId: myInfo.userId }));
            }, 3000);

            // Clear interval if mode is "Edit"
            if (localStorage.getItem("mode") === "Edit") {
                // console.log("***--Interval clear--***");
                setWaitingPopup(false);
                // clearInterval(IntervalId);
                setStartPolling(false);
            }

            // Cleanup interval on component unmount or when dependencies change
            return () => {
                clearInterval(IntervalId);
            };
        }
    }, [startPolling, teams, myInfo.userId, dispatch]);

    useEffect(() => {
        if (!waitingPopup) {
            setWaitingPopup(false);
        }
    }, [waitingPopup]);

    return (
        <>
            {
                !waitingPopup ? (
                    <Modal
                        isOpen={open}
                        shouldCloseOnOverlayClick={false}
                        ariaHideApp={false}
                        className="permissionReactModal"
                    >
                        <div id="permissionModal" role="dialog">
                            <div className="modal-dialog modal-sm modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-body swal2-container swal2-center">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <h2 className="swal2-title">Access Restricted</h2>
                                                <p>This team is currently busy.</p>
                                                <p>Your options:</p>
                                                <div className="permission-actions dropdown team-list">
                                                    <button className="btn btn-mytpt" onClick={SignOut}>Come back later, logout for now</button>
                                                    <button className="btn btn-mytpt" onClick={() => handleMode("View")}>Enter "view only" mode</button>
                                                    <button className="btn btn-mytpt" onClick={() => handleMode("Edit")}>Request "edit" access</button>

                                                    <div
                                                        className="btn btn-mytpt dropdown-toggle"
                                                        data-toggle="dropdown"
                                                    >
                                                        Switch to different team
                                                        <i
                                                            style={{ marginLeft: "10px" }}
                                                            className="fa fa-caret-down"
                                                        ></i>
                                                    </div>
                                                    <ul className="dropdown-menu" role="menu">
                                                        {/* <li className="dropdown-header">Switch Team</li> */}
                                                        {teamData.map((e, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    role="presentation"
                                                                    onClick={() => {
                                                                        setHandel(index);
                                                                    }}
                                                                >
                                                                    <a
                                                                        role="menuitem"
                                                                        href="#"
                                                                        className="switch-team"
                                                                    >
                                                                        {e.team_name}
                                                                    </a>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>) : (
                    <Modal
                        isOpen={waitingPopup}
                        shouldCloseOnOverlayClick={false}
                        ariaHideApp={false}
                        className="permissionReactModal"
                    >
                        <div id="permissionModal" role="dialog">
                            <div className="modal-dialog modal-sm modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-body swal2-container swal2-center">
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <h2 className="swal2-title">Please wait</h2>
                                                <p>Your request has been sent.</p>
                                                <div className="loader"></div>
                                                <button
                                                    className="btn btn-mytpt-red"
                                                    onClick={() => setWaitingPopup(false)}
                                                    style={{ width: "auto", backgroundColor: "#EE7364", color: "#ffffff" }}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </>
    );
};

export default Permission_ModePopUp;