export const sumFTE = (workload, ownerData) => {
  const ownerIds = Object.keys(ownerData).length
    ? ownerData.map((sw) => sw && sw.value)
    : [];

  let sum = 0;
  if (workload && workload.length > 0) {
    workload.forEach((swl, index) => {
      if (swl && swl.ownerId && ownerIds.includes(swl.ownerId)) {
        sum += parseFloat(swl.plan ? swl.plan : 0);
      }
    });
  }

  return isNaN(sum) ? 0 : sum.toFixed(2);
};

export const sumFTEBody = (workload, ownerIds) => {
  let sum = 0;
  if (ownerIds && ownerIds.length > 0 && workload && workload.length > 0) {
    workload.forEach((swl, index) => {
      if (swl && swl.ownerId && ownerIds.includes(swl.ownerId)) {
        sum += parseFloat(swl.plan ? swl.plan : 0);
      }
    });
  }
  return isNaN(sum) ? 0 : sum;
};

export const aggrigatedPersonFTE = (
  skills,
  roles,
  domains,
  links,
  targetId
) => {
  let sum = 0;

  function calculateTotalPlanSum(array, targetId, type) {
    let totalPlanSum = 0;
    array.forEach((item) => {
      if (item.workload && Array.isArray(item.workload)) {
        if (["SKILLS", "ROLES"].includes(type)) {
          item.workload.forEach((workloadItem) => {
            if (
              item.owners &&
              item.owners.includes(targetId) &&
              workloadItem &&
              workloadItem.ownerId === targetId
            ) {
              totalPlanSum += parseFloat(workloadItem.plan ? workloadItem.plan : 0);
            }
          });
        } else if ("LINKS" === type) {
          item.workload.forEach((workloadItem) => {
            if (
              workloadItem &&
              item.owner === targetId &&
              workloadItem.ownerId === targetId
            ) {
              totalPlanSum += parseFloat(workloadItem.plan ? workloadItem.plan : 0);
            }
          });
        } else if ("DOMAINS" === type) {
          item.workload.forEach((workloadItem) => {
            if (
              workloadItem && item.owners.owner === targetId &&
              workloadItem.ownerId === targetId
            ) {
              totalPlanSum += parseFloat(workloadItem.plan ? workloadItem.plan : 0);
            }
          });
        }
      }
    });
    return totalPlanSum;
  }

  // Example usage for `skills` array
  const totalPlanSumSkills = calculateTotalPlanSum(skills, targetId, "SKILLS");
  // console.log("Total Plan Sum for targetId in skills:", totalPlanSumSkills);
  sum += parseFloat(totalPlanSumSkills ? totalPlanSumSkills : 0);
  // console.log(sum);

  // Example usage for `roles` array
  const totalPlanSumRoles = calculateTotalPlanSum(roles, targetId, "ROLES");
  // console.log("Total Plan Sum for targetId in roles:", totalPlanSumRoles);
  sum = parseFloat(sum) + parseFloat(totalPlanSumRoles ? totalPlanSumRoles : 0);
  // console.log(sum);

  // Example usage for `domains` array
  const totalPlanSumDomains = calculateTotalPlanSum(
    domains,
    targetId,
    "DOMAINS"
  );
  sum += parseFloat(totalPlanSumDomains ? totalPlanSumDomains : 0);

  // Example usage for `links` array
  const totalPlanSumLinks = calculateTotalPlanSum(links, targetId, "LINKS");
  sum += parseFloat(totalPlanSumLinks ? totalPlanSumLinks : 0);

  return isNaN(sum) ? 0 : sum;
};

export const sumFTEBody_Focus = (workload, ownerId) => {
  let sum = 0;
  if (ownerId && workload && workload.length > 0) {
    workload.forEach((swl, index) => {
      if (swl && swl.ownerId === ownerId) {
        sum += parseFloat(swl.plan ? swl.plan : 0);
      }
    });
  }
  // console.log(workload, ownerId, sum);
  return isNaN(sum) ? 0 : sum;
};

export const GetPersonAvailability = (people, focusId) => {
  const fdata = people.find((sp) => sp._id === focusId);
  if (fdata && fdata.availability > 0) {
    return fdata.availability;
  } else {
    return 0;
  }
};

export const GetTotalWorkLoad = (data, type) => {
  if (data.workload && data.workload.length) {
    let allWorkload = data.workload.map((swl) => {
      if (["SKILLS", "ROLES"].includes(type)) {
        if (swl && swl.ownerId && data.owners.includes(swl.ownerId)) {
          return swl.plan;
        }
      } else if ("LINKS" === type) {
        if (swl && data.owner === swl.ownerId) {
          return swl.plan;
        }
      } else if ("DOMAINS" === type) {
        if (swl && data.owners.owner === swl.ownerId) {
          return swl.plan;
        }
      }
    });
    allWorkload = allWorkload.filter((item) => item !== undefined);
    let totalWorkLoad = allWorkload.reduce(
      (total, current) => parseFloat(total) + parseFloat(current),
      0
    );
    return totalWorkLoad;
  }
  return 0;
};
