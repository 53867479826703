export const REPORT_SUCCESS_MESSAGE_CLEAR = 'REPORT_SUCCESS_MESSAGE_CLEAR';
export const REPORT_ERROR_CLEAR = 'REPORT_ERROR_CLEAR'

export const REPORT_TEAM_GET_SUCCESS = 'REPORT_TEAM_GET_SUCCESS';
export const REPORT_TEAM_GET_ERROR = 'REPORT_TEAM_GET_ERROR'
export const REPORT_ALL_TEAM_GET_SUCCESS = 'REPORT_ALL_TEAM_GET_SUCCESS';

export const REPORT_PEOPLE_GET_SUCCESS = 'REPORT_PEOPLE_GET_SUCCESS';
export const REPORT_PEOPLE_GET_ERROR = 'REPORT_PEOPLE_GET_ERROR'

export const REPORT_SKILL_GET_SUCCESS = 'REPORT_SKILL_GET_SUCCESS';
export const REPORT_SKILL_GET_ERROR = 'REPORT_SKILL_GET_ERROR'

export const REPORT_ROLE_GET_SUCCESS = 'REPORT_ROLE_GET_SUCCESS';
export const REPORT_ROLE_GET_ERROR = 'REPORT_ERROR_ROLE_GET'

export const REPORT_DOMAIN_GET_SUCCESS = 'REPORT_DOMAIN_GET_SUCCESS';
export const REPORT_DOMAIN_GET_ERROR = 'REPORT_ERROR_DOMAIN_GET'

export const REPORT_LINK_GET_SUCCESS = 'REPORT_LINK_GET_SUCCESS';
export const REPORT_LINK_GET_ERROR = 'REPORT_ERROR_LINK_GET'

export const REPORT_CIRCLE_GET_SUCCESS = 'REPORT_CIRCLE_GET_SUCCESS';
export const REPORT_CIRCLE_GET_ERROR = 'REPORT_ERROR_CIRCLE_GET'

export const REPORT_PROJECT_GET_SUCCESS = 'REPORT_PROJECT_GET_SUCCESS';
export const REPORT_PROJECT_GET_ERROR = 'REPORT_ERROR_PROJECT_GET'

export const REPORT_MEETING_GET_SUCCESS = 'REPORT_MEETING_GET_SUCCESS';
export const REPORT_MEETING_GET_ERROR = 'REPORT_ERROR_MEETING_GET'

export const REPORT_AUTH_SUCCESS = 'REPORT_AUTH_SUCCESS'
export const REPORT_AUTH_FAIL = 'REPORT_AUTH_FAIL'

export const ALL_REPORT_GET_SUCCESS = 'ALL_REPORT_GET_SUCCESS'
export const ALL_REPORT_GET_ERROR = 'ALL_REPORT_GET_ERROR'

export const REPORT_REQUEST_SENT_SUCCESS = 'REPORT_REQUEST_SENT_SUCCESS'
export const REPORT_REQUEST_SENT_ERROR = 'REPORT_REQUEST_SENT_ERROR'


