import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getpricing } from "../../store/actions/pricingAction";
import Navbar from "../../Component/Header/Navbar";
import Footer from "../../Component/Footer/Footer";
import { SyncLoader } from "react-spinners";
import { getLoginUserData } from "../../store/actions/authAction";
import { userVerify, paymentVerify } from "../../store/actions/authAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export default function Pricing() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    stripeUrl,
    successMessage,
    error,
    authenticate,
    myInfo,
    curUserData,
    loginHistory,
  } = useSelector((state) => state.auth);
  const {
    pricings,
    Prices,
    cuPricingId,
    pricingMessagee,
    pricingMessage,
    pricingError,
  } = useSelector((state) => state.pricings);

  const [rewardfulId, setRewardfulId] = useState(null);

  // console.log(rewardfulId)

  function divHeight() {
    let pricingHead = document.querySelectorAll(".pricing-head h3");
    if (pricingHead) {
      let maxHeight = 0;
      for (let index = 0; index < pricingHead.length; index++) {
        if (maxHeight < pricingHead[index].offsetHeight) {
          maxHeight = pricingHead[index].offsetHeight;
        }
      }

      for (let index = 0; index < pricingHead.length; index++) {
        pricingHead[index].style.height = maxHeight + "px";
      }
    }

    let pricingContain = document.querySelectorAll(".pricing-contain");
    if (pricingContain) {
      let maxHeight = 0;
      for (let index = 0; index < pricingContain.length; index++) {
        if (maxHeight < pricingContain[index].offsetHeight) {
          maxHeight = pricingContain[index].offsetHeight;
        }
      }

      for (let index = 0; index < pricingContain.length; index++) {
        pricingContain[index].style.height = maxHeight + "px";
      }
    }

    let pricingContent = document.querySelectorAll(".pricing-content");
    if (pricingContent) {
      let maxHeight = 0;
      for (let index = 0; index < pricingContent.length; index++) {
        if (maxHeight < pricingContent[index].offsetHeight) {
          maxHeight = pricingContent[index].offsetHeight;
        }
      }

      for (let index = 0; index < pricingContent.length; index++) {
        pricingContent[index].style.height = maxHeight + "px";
      }
    }
  }

  useEffect(() => {
    if (myInfo && myInfo.userId) {
      dispatch(getLoginUserData({ userId: myInfo.userId }));
    }
  }, [myInfo]);

  const [cuTime, setCuTime] = useState(false);
  const [curPlan, setCurPlan] = useState("FREE");
  const [btnAction, setBtnAction] = useState({
    bIndex: null,
    bStatus: false,
  });

  useEffect(() => {
    if (
      curUserData &&
      Object.keys(curUserData).length > 0 &&
      curUserData.plan &&
      curUserData.plan.length > 0
    ) {
      setCurPlan(curUserData.plan);
    }
  }, [curUserData]);

  // console.log(51, pricings)

  useEffect(() => {
    dispatch(getpricing());
  }, []);

  useEffect(() => {
    if (pricings && pricings.length > 0) {
      divHeight();
    }
  }, [pricings]);

  // setTimeout(()=>{divHeight()},1000);

  function openMailTo(plan) {
    /* const email = "hello@teamdecoder.com";
        const subject = "Hello";
        const body = "Type your message here.";

        // Construct the mailto link ?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}
        const mailtoLink = `mailto:${email}`; */

    let mailtoLink = "";
    if (plan === "ENTERPRISE") {
      mailtoLink = `http://contact.teamdecoder.com`;
    } else {
      mailtoLink = `http://coaches.teamdecoder.com/`;
    }

    // Open a new window with the mailto link
    window.open(mailtoLink);
  }

  let navigateTo = (data, price, index) => {
    console.log(data, price, index)
    if (data === "FREE") {
      localStorage.setItem("Rnavigate", "FREE");
      navigate("/register");
    } else if (data === "GO" || data === "COACH") {
      localStorage.setItem("Rnavigate", "GO");
      localStorage.setItem("subPrices", price.id);
      if (
        curUserData &&
        Object.keys(curUserData).length > 0 &&
        curUserData.plan &&
        curUserData.plan.length > 0
      ) {
        setBtnAction({
          bIndex: index,
          bStatus: true,
        });
      } else {
        navigate("/register");
      }
    } /* else if (data === "COACH") {
      // localStorage.setItem('Rnavigate', "COMMUNITY")
      //       localStorage.setItem('subPrices', price.id)
      //       if (curUserData && Object.keys(curUserData).length > 0 && curUserData.plan && curUserData.plan.length > 0) {
      //           setBtnAction({
      //               bIndex: index,
      //               bStatus: true
      //           })
      //           dispatch(userVerify({ userId: curUserData._id, plan: data, prices: price.id, email: curUserData.email, type: "Upgrade" }))
      //       } else {
      //           navigate('/register')
      //       }

      // openMailTo("COMMUNITY")
      // window.location.href =
      //   "https://teamdecoder.circle.so/join?invitation_token=ee199ca132a482dea6da683cc98af2fed41c2667-d7e46952-f8c8-41ef-98a2-69f70f8444b6";
    } */ else if (data === "ENTERPRISE") {
      openMailTo("ENTERPRISE");
    }
  };

  useEffect(() => {
    if (stripeUrl && Object.keys(stripeUrl).length > 0 && stripeUrl.url) {
      window.location.href = `${stripeUrl.url}`;
    }
  }, [stripeUrl]);

  // console.log(142, stripeUrl)
  let getPrice = (product_id, plan_name) => {
    // console.log(product_id, plan_name)

    /*  
    Date: 15-10-2024
    Done to make GO and COACH same thing. I still don't know why do we show COACH if we do not want it.
    */
    if(plan_name === "COACH") product_id = "prod_PGoFHVsZhm5FMM";

    if (product_id) {
      for (let i = 0; i < Prices.length; i++) {
        if (Prices[i].product === product_id) {
          // console.log(Prices[i])
          if (Prices[i].type === "recurring") {
            if (cuTime && Prices[i].recurring.interval === "year") {
              return Prices[i];
            } else if (!cuTime && Prices[i].recurring.interval === "month") {
              return Prices[i];
            }
          } else if (Prices[i].recurring === null) {
            return Prices[i];
          }
        }
      }
    }
  };

  let getButtonName = (name) => {
    // console.log(name);
    if (name === "FREE") {
      return "Start FREE";
    } else if (name === "GO" || name === "COACH") {
      return "Subscribe";
    } /* else if (name === "COACH") {
      return "Register";
    } */ else if (name === "ENTERPRISE") {
      return "Get In Touch";
    }
  };

  let getplanName = (name) => {
    /* if (name === "FREE") {
      return "FREE";
    } else if (name === "GO") {
      return "GO";
    } */
    return name;
  };

  const isDisabled = (data) => {
    if (
      curUserData &&
      Object.keys(curUserData).length > 0 &&
      curUserData.plan &&
      curUserData.plan.length > 0
    ) {
      if (curUserData.plan === data.name) {
        return true;
      } else if (data.name === "FREE") {
        return true;
      }
    }
    return false;
  };

  document.body.style = "";
  document.body.classList = "no-app-pages menu2 page-pricing";

  // console.log(pricings);

  useEffect(() => {
    if (window.rewardfulReferral) {
    //   console.log("hello rewardful reward id", window.rewardfulReferral);
      setRewardfulId(window.rewardfulReferral);
      //   window.rewardfulReferral
    } else {
      // console.log("hello window");
    }
  }, []);

  return (
    <div>
      <Navbar />
      {pricings && pricings.length > 0 ? (
        <div className="container vertical-center">
          <div className="row">
            <div className="col-xs-12 month-yearly-toggle">
              <div className="switch-btn">
                Monthly
                <label className="switch">
                  <input
                    name="term"
                    className="term"
                    onChange={() => setCuTime(!cuTime)}
                    type="checkbox"
                    wfd-id="id0"
                  />
                  <span className="slider"></span>
                </label>{" "}
                Yearly
              </div>
            </div>
            {pricings && pricings.length > 0
              ? pricings.map((e, index) => {
                  let curPrices = getPrice(e.id, e.name);
                  let prices = [];
                  let curPriceId = [];
                  if (curPrices) {
                    prices.push(curPrices.unit_amount / 100);
                    curPriceId.push(curPrices);
                  }
                  // console.log("114", curPrices)
                  // one_time
                  return (
                    <div
                      key={index}
                      className="col-lg-3 col-sm-6 col-12 np-padding plan"
                    >
                      <div className="pricing hover-effect">
                        <div className="pricing-head">
                          <h3
                            dangerouslySetInnerHTML={{
                              __html: getplanName(e.name),
                            }}
                          ></h3>
                        </div>
                        <div className="pricing-contain">
                          <p>{e.description}</p>
                        </div>
                        {e.name === "ENTERPRISE" ? (
                          <div className="pricing-head-price-tree line-out">
                            <h4>
                              indiv.
                              <br />
                              <span className="interval"></span>
                            </h4>
                            <p></p>
                          </div>
                        ) : (
                          <div className="pricing-head-price-tree line-out">
                            {prices && prices.length > 0 ? (
                              <h4>
                                {prices && prices.length > 0 ? prices[0] : 0}{" "}
                                EUR
                                <br />
                                {curPrices.type === "one_time" ? (
                                  <span className="interval">{`one time`}</span>
                                ) : (
                                  <span className="interval">
                                    {`per ${
                                      curPrices &&
                                      curPrices.recurring &&
                                      curPrices.recurring.interval
                                        ? curPrices.recurring.interval
                                        : cuTime
                                        ? "year"
                                        : "month"
                                    }`}
                                  </span>
                                )}
                              </h4>
                            ) : (
                              <h4>
                                {e.name === "COACH"
                                  ? "Special Deal"
                                  : prices && prices.length > 0
                                  ? prices[0] + " EUR"
                                  : 0 + " EUR"}
                                <br />
                                <span className="interval"></span>
                              </h4>
                            )}
                            <p></p>
                          </div>
                        )}
                        <div className="pricing-footer">
                          <a
                            onClick={() =>
                              navigateTo(e.name, curPriceId[0], index)
                            }
                            className={`btn yellow-crusta ${
                              isDisabled(e) || btnAction.bStatus
                                ? "disabled"
                                : ""
                            }`}
                          >
                            {btnAction &&
                            Object.keys(btnAction).length > 0 &&
                            btnAction.bIndex === index &&
                            btnAction.bStatus ? (
                              <FontAwesomeIcon
                                icon={faSpinner}
                                spin
                                style={{ color: "#000000" }}
                              />
                            ) : isDisabled(e) ? (
                              "Current plan"
                            ) : (
                              getButtonName(e.name)
                            )}
                          </a>
                        </div>
                        <div>
                          <ul className="pricing-content list-unstyled">
                            {e.metadata && Object.keys(e.metadata).length > 0
                              ? Object.keys(e.metadata).map((key, indexs) => {
                                  return (
                                    <li
                                      key={indexs}
                                      dangerouslySetInnerHTML={{
                                        __html: e.metadata[key],
                                      }}
                                    ></li>
                                  );
                                })
                              : null}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      ) : (
        <div className="page-loader">
          <SyncLoader color="#36d7b7" />
        </div>
      )}
      <Footer />
    </div>
  );
}