import React from 'react'

export default function PrSkillsList({ tagFilteredMegedData, handleAliesChange, GetAliasesName, teams, PrcorfoSkill, PrextfoSkill, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, pCardHideOnchange, note, handleSave, noteSaveData, pFSonDrop, pFSonDragOver, isTag, isEXT, isNO, TagWiseSorting, tagFilteredObj, extNoFilteredObj, IsOwnerExternal, isPFProjectsAddS, isAllOwnersMarked, focused, projectsSorted, pFSonDrag, setSkill, OwnerExist, toggleTagCards, pFskillSub, OpenCFPeople, temporarilyMovedItem, fpSref, pfSkillsSearch, setPfSkillsSearch, filteredPFSData, OverlayTrigger, popover, cfcircleOrProjectModes, lFromState, skillSubmit, sref, skill, handleSkillChange, setSkills, setSkillName, skillsFrom }) {
    return (
        <div
            className={
                !clickedOnList.listName.includes("skill") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.skill &&
                    tagFilteredMegedData.skill.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="skills"
        >
            <div className="list list-bg-blue" data-list_id="skills">
                <div className="dropdown">
                    <h4 className="list-title">
                        <img alt="" src="images/icon-skill.png" /> {/* {GetAliasesName(teams, "Skills")} (
            <span>{}</span>){" "} */}
                        <span contentEditable={true}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault(); // Prevent new line
                                }
                            }} data-name="skills" onBlur={(e) => handleAliesChange(e)} suppressContentEditableWarning={true}>{GetAliasesName(teams, "Skills")}</span>
                        <span>{` (${PrcorfoSkill.length + PrextfoSkill.length})`}</span>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("skill")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            href="#"
                            onClick={() => {
                                toggleEXTnNOCards("skills", "", true);
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>{" "}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("skill")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("Skills")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    onClick={() => isInfos("Skills")}
                                    role="menuitem"
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    data-toggle="modal"
                                    data-target="#skillsModal"
                                >
                                    Add new {GetAliasesName(teams, "Skills").toLowerCase()}
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("Skills")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("skills", "EXT", false);
                                }}
                            >
                                <a role="menuitem" className="show-external">
                                    Show items owned by external
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => {
                                    toggleEXTnNOCards("skills", "NO", false);
                                }}
                            >
                                <a role="menuitem" className="show-noowners">
                                    Show items without owners
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>

                <div className="card-list scrollbar">
                    <div
                        className="form-group notes"
                        style={note.Skills ? { display: "block" } : { display: "none" }}
                    >
                        <textarea
                            className="notes"
                            name="Skills"
                            onBlur={(e) => handleSave(e)}
                            placeholder="Notes"
                            maxLength="100"
                            defaultValue={noteSaveData.Skills}
                        ></textarea>
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Core members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => pFSonDrop("pFsCore")}
                            onDragOver={(event) => pFSonDragOver(event)}
                            id="core"
                            className="panel-body box-container"
                        >
                            {PrcorfoSkill && PrcorfoSkill.length > 0
                                ? PrcorfoSkill.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "skill"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Skill");
                                    let isPFProjects = isPFProjectsAddS(e._id, "core");
                                    let allOwnersFlag = isAllOwnersMarked(e._id, "core");
                                    // console.log(16045, isPFProjects, allOwnersFlag);
                                    let flagRed = false;
                                    if (focused && focused.card === "Projects") {
                                        let focusedProject = projectsSorted.find(
                                            (p) => p._id === focused.id
                                        );
                                        let leadOfProjectRedInHtml = focusedProject.lead;
                                        if (leadOfProjectRedInHtml === e._id) {
                                            flagRed = true;
                                        }
                                    }
                                    return (
                                        <div
                                            // onDragStart={(e) => skillsDragStart(e, index)}
                                            // onDragEnter={(e) => skillsDragEnter(e, index)}
                                            // onDragEnd={skillsDrop}
                                            onDrag={() => pFSonDrag(e, "pFsCore", isPFProjects)}
                                            draggable
                                            key={index}
                                            className="card "
                                            data-list_id="skills"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setSkill(e, PrcorfoSkill, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                ) : null}
                                                {e.ownerType.length > 0 &&
                                                    e.ownerType === "Single owner" ? (
                                                    <span className="custom-badge blue-o single-owner-pill">
                                                        1
                                                    </span>
                                                ) : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>
                                            <div className="relations">
                                                {flagRed ? (
                                                    <span className="custom-badge red-o direct-lead">
                                                        LEAD
                                                    </span>
                                                ) : null}
                                            </div>
                                            <a
                          /* onClick={() => setSkill(e, PrcorfoSkill, index)} */ className="card-edit"
                                            >
                                                {e.skillName}
                                            </a>
                                            {!flagRed ? (
                                                <a
                                                    className="card-unlink"
                                                    style={{ display: "block", color: "red" }}
                                                >
                                                    <i
                                                        onClick={() =>
                                                            pFskillSub(e, "core", isPFProjects)
                                                        }
                                                        className="icon far fa-times-circle"
                                                    ></i>
                                                </a>
                                            ) : null}
                                            <br />
                                            {isPFProjects &&
                                                isPFProjects.length > 0 &&
                                                e.owners &&
                                                !allOwnersFlag /* e.owners.length > isPFProjects.length */
                                                ? isPFProjects.map((value, pdindex) => {
                                                    return (
                                                        <>
                                                            {e.owners.includes(value.Id) && <span
                                                                key={pdindex}
                                                                onClick={() => OpenCFPeople(value.Id)}
                                                                className="custom-badge tags blue-text"
                                                            >{`${value.name}`}</span>}
                                                        </>

                                                    );
                                                })
                                                : null}
                                        </div>
                                    );
                                })
                                : null}

                            {temporarilyMovedItem &&
                                temporarilyMovedItem.area === "Core" &&
                                temporarilyMovedItem.cardType === "Skills" &&
                                Object.keys(temporarilyMovedItem.card).length > 0
                                ? (() => {
                                    return (
                                        <div
                                            key={temporarilyMovedItem.card._id}
                                            className="card move-up-down"
                                            style={{ border: "1px dashed #000", opacity: "0.7" }}
                                        >
                                            <span className="static-relations">
                                                {temporarilyMovedItem.card.tags &&
                                                    temporarilyMovedItem.card.tags.length > 0
                                                    ? temporarilyMovedItem.card.tags.map(
                                                        (r, indexs) => (
                                                            <span
                                                                onClick={() => toggleTagCards(r)}
                                                                key={indexs}
                                                                className="custom-badge green tags"
                                                            >
                                                                {r}
                                                            </span>
                                                        )
                                                    )
                                                    : null}
                                            </span>
                                            <a className="card-edit">
                                                {temporarilyMovedItem.card.skillName}
                                            </a>
                                        </div>
                                    );
                                })()
                                : null}
                        </div>
                    </div>

                    <h6 style={{ borderBottom: "1px solid #000" }}>Extended members</h6>
                    <div className="panel panel-default">
                        <div
                            onDrop={() => pFSonDrop("pFsExtend")}
                            onDragOver={(event) => pFSonDragOver(event)}
                            id="extended"
                            className="panel-body box-container"
                        >
                            {/* Extended members listed here*/}
                            {PrextfoSkill.length > 0
                                ? PrextfoSkill.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "skill"
                                        );
                                        if (!Proceed) return;
                                    }
                                    let isExternal = IsOwnerExternal(e, "Skill");
                                    let isPFProjects = isPFProjectsAddS(e._id, "extended");
                                    let allOwnersFlag = isAllOwnersMarked(e._id, "extended");
                                    // console.log(16045, isPFProjects, allOwnersFlag);
                                    return (
                                        <div
                                            // onDragStart={(e) => skillsDragStart(e, index)}
                                            // onDragEnter={(e) => skillsDragEnter(e, index)}
                                            // onDragEnd={skillsDrop}
                                            onDrag={() => pFSonDrag(e, "pFsExtend", isPFProjects)}
                                            draggable
                                            key={index}
                                            className="card "
                                            data-list_id="skills"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setSkill(e, PrextfoSkill, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                ) : null}
                                                {e.ownerType.length > 0 &&
                                                    e.ownerType === "Single owner" ? (
                                                    <span className="custom-badge blue-o single-owner-pill">
                                                        1
                                                    </span>
                                                ) : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>

                                            <a
                                                // onClick={() => setSkill(e, PrextfoSkill, index)}
                                                className="card-edit"
                                            >
                                                {e.skillName}
                                            </a>
                                            <a
                                                className="card-unlink"
                                                style={{ display: "block", color: "red" }}
                                            >
                                                <i
                                                    onClick={() => pFskillSub(e, "extend")}
                                                    className="icon far fa-times-circle"
                                                ></i>
                                            </a>
                                            <br />
                                            {isPFProjects &&
                                                isPFProjects.length > 0 &&
                                                e.owners &&
                                                !allOwnersFlag /* e.owners.length > isPFProjects.length */
                                                ? isPFProjects.map((value, pdindex) => {
                                                    return (
                                                        <>
                                                            {e.owners.includes(value.Id) && <span
                                                                key={pdindex}
                                                                onClick={() => OpenCFPeople(value.Id)}
                                                                className="custom-badge tags blue-text"
                                                            >{`${value.name}`}</span>}
                                                        </>
                                                    );
                                                })
                                                : null}
                                        </div>
                                    );
                                })
                                : null}

                            {temporarilyMovedItem &&
                                temporarilyMovedItem.area === "Extended" &&
                                temporarilyMovedItem.cardType === "Skills" &&
                                Object.keys(temporarilyMovedItem.card).length > 0
                                ? (() => {
                                    return (
                                        <div
                                            key={temporarilyMovedItem.card._id}
                                            className="card move-up-down"
                                            style={{ border: "1px dashed #000", opacity: "0.7" }}
                                        >
                                            <span className="static-relations">
                                                {temporarilyMovedItem.card.tags &&
                                                    temporarilyMovedItem.card.tags.length > 0
                                                    ? temporarilyMovedItem.card.tags.map(
                                                        (r, indexs) => (
                                                            <span
                                                                onClick={() => toggleTagCards(r)}
                                                                key={indexs}
                                                                className="custom-badge green tags"
                                                            >
                                                                {r}
                                                            </span>
                                                        )
                                                    )
                                                    : null}
                                            </span>
                                            <a className="card-edit">
                                                {temporarilyMovedItem.card.skillName}
                                            </a>
                                        </div>
                                    );
                                })()
                                : null}
                        </div>
                    </div>

                    <span className="clearable2">
                        <input
                            type="text"
                            ref={fpSref}
                            style={{ color: "black" }}
                            value={pfSkillsSearch}
                            onChange={(e) => setPfSkillsSearch(e.target.value)}
                            className="form-control search_list2 transparent"
                            name="search_list"
                            placeholder="Search to select more"
                            autoComplete="off"
                        />
                        {pfSkillsSearch.length > 0 ? (
                            <i
                                className="clearable__clear"
                                onClick={() => setPfSkillsSearch("")}
                                style={{ display: "inline", color: "black" }}
                            >
                                ×
                            </i>
                        ) : null}
                    </span>

                    <div className="panel panel-default">
                        <div
                            onDrop={() => pFSonDrop("pFsDefult")}
                            onDragOver={(event) => pFSonDragOver(event)}
                            id="notmatched"
                            className="panel-body box-container"
                        >
                            {filteredPFSData.length > 0
                                ? filteredPFSData.filter(
                                    item => ![...PrcorfoSkill, ...PrextfoSkill].some(skill => skill._id === item._id)
                                ).map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "skill"
                                        );
                                        if (!Proceed) return;
                                    }
                                    if (
                                        temporarilyMovedItem &&
                                        temporarilyMovedItem.cardType === "Skills" &&
                                        Object.keys(temporarilyMovedItem.card).length > 0 &&
                                        temporarilyMovedItem.card._id === e._id
                                    )
                                        return;

                                    let isExternal = IsOwnerExternal(e, "Skill");
                                    return (
                                        <div
                                            // onDragStart={(e) => skillsDragStart(e, index)}
                                            // onDragEnter={(e) => skillsDragEnter(e, index)}
                                            // onDragEnd={skillsDrop}
                                            onDrag={() => pFSonDrag(e, "pFsDefult")}
                                            draggable
                                            key={index}
                                            className={`card notmatched ${localStorage.getItem("latest-created") === e._id
                                                ? " latest-created "
                                                : ""
                                                }`}
                                            data-list_id="skills"
                                            onClick={(event) => {
                                                if (
                                                    event.target.classList.contains("card") ||
                                                    event.target.classList.contains("card-edit")
                                                ) {
                                                    setSkill(e, filteredPFSData, index);
                                                }
                                            }}
                                        >
                                            <div className="static-relations">
                                                {isExternal ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "EXT", false, "pill");
                                                        }}
                                                        className="custom-badge blue ext-owner-pill"
                                                    >
                                                        EXT
                                                    </span>
                                                ) : null}
                                                {e.owners.length === 0 || !OwnerExist(e.owners) ? (
                                                    <span
                                                        onClick={() => {
                                                            toggleEXTnNOCards(e._id, "NO", false, "pill");
                                                        }}
                                                        className="custom-badge no-owner-pill red"
                                                    >
                                                        NO
                                                    </span>
                                                ) : null}
                                                {e.ownerType.length > 0 &&
                                                    e.ownerType === "Single owner" ? (
                                                    <span className="custom-badge blue-o single-owner-pill">
                                                        1
                                                    </span>
                                                ) : null}
                                                {e.tags && e.tags.length > 0
                                                    ? e.tags.map((r, index) => (
                                                        <span
                                                            onClick={() => toggleTagCards(r)}
                                                            key={index}
                                                            className="custom-badge green tags"
                                                        >
                                                            {r}
                                                        </span>
                                                    ))
                                                    : null}
                                            </div>

                                            <a
                                                // onClick={() => setSkill(e, filteredPFSData, index)}
                                                className="card-edit"
                                            >
                                                {e.skillName}
                                            </a>
                                            <OverlayTrigger
                                                trigger="click"
                                                placement="right"
                                                overlay={popover}
                                            >
                                                <a
                                                    className="card-link"
                                                    variant="success"
                                                    style={{ display: "block" }}
                                                >
                                                    <i
                                                        onClick={() =>
                                                            cfcircleOrProjectModes(e, "Skills")
                                                        }
                                                        className="icon fa fa-plus-circle"
                                                    ></i>
                                                </a>
                                            </OverlayTrigger>
                                        </div>
                                    );
                                })
                                : null}
                        </div>
                    </div>

                    <div
                        className="card2"
                        style={
                            lFromState.Skills ? { display: "block" } : { display: "none" }
                        }
                    >
                        <form className="form-new-card" role="form" onSubmit={skillSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    ref={sref}
                                    name="card_name"
                                    required
                                    placeholder={`Add ${GetAliasesName(teams, "Skills").toLowerCase()} name`}
                                    className="form-control"
                                    value={skill}
                                    onChange={handleSkillChange}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group" style={{ marginTop: "10px" }}>
                                <button type="submit" className="btn btn-mytpt add-quick">
                                    Save
                                </button>
                                <a
                                    href="#"
                                    type="button"
                                    onClick={() => {
                                        setSkills({ skillName: skill });
                                        setSkillName("");
                                        lFromState.Skills = false;
                                    }}
                                    className="btn btn-link underline open-tool"
                                >
                                    Edit details
                                </a>
                                <a
                                    href="#"
                                    onClick={() => skillsFrom()}
                                    className="btn btn-link underline close-add-card"
                                >
                                    Close
                                </a>
                            </div>
                        </form>
                    </div>
                </div>
                <a
                    className="btn-list add-card"
                    onClick={() => skillsFrom()}
                    data-list_id="skills"
                    style={
                        !lFromState.Skills ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new {/*skill */} {GetAliasesName(teams, "Skills").toLowerCase()}
                </a>
            </div>
        </div>
    )
}
