import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import { historyGet } from "../../../store/actions/historyAction";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../store/types/historyType";
import { sumFTEBody } from "../../../HelperFunctions/FTECalculation";
import WorkLoadSRDL from "../../Body/Modal/WorkLoadSRDL";
import Objectives from "../../Body/Modal/Objectives";
import { numberInputOnWheelPreventChange } from "../../../HelperFunctions/StopInputScroll";
import { GetAliasesName } from "../../../HelperFunctions/GetAliasesName";
import { IsFadeIn, IsFadeOut } from "../../../HelperFunctions/FadeInOutChecker";

export default function LinkModal({ data, closeLink, teams }) {
  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting,
  } = useSelector((state) => state.authReport);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);

  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );

  const dispatch = useDispatch();
  let curOwner = {};
  if (
    data &&
    data.owner &&
    data.owner.length > 0 &&
    reportPeople &&
    reportPeople.length > 0
  ) {
    for (let i = 0; i < reportPeople.length; i++) {
      if (reportPeople[i]._id === data.owner) {
        curOwner = reportPeople[i];
      }
    }
  }

  let curStandIn = null;
  if (
    data &&
    data.standIn &&
    data.standIn.length > 0 &&
    reportPeople &&
    reportPeople.length > 0
  ) {
    for (let i = 0; i < reportPeople.length; i++) {
      if (reportPeople[i]._id === data.standIn) {
        curStandIn = reportPeople[i];
      }
    }
  }
  // console.log(curStandIn)

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  let memberIds = [];
  if (data) {
    members.map((e, index) => {
      let inc = e.coreMembers.Links.includes(data._id);
      let incs = e.extendedMembers.Links.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, XTD: "" });
      }
      if (incs) {
        memberIds.push({ memberId: e.memberType, XTD: "XTD" });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + "(circle)";
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + "(project)";
        }
      }
    }
  }

  const CloseLinkModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeLink();
  };

  return (
    <div
      id="linkModal"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable report-modal">
        <form className="form">
          <div className="modal-content">
            <div className="modal-header header-blue">
              <h4 className="modal-title">
                <img
                  src="../images/icon-link.png"
                  style={{
                    width: "17px",
                    marginTop: "-3px",
                    marginRight: "5px",
                  }}
                />{" "}
                {/* LINK */ GetAliasesName(teams, "Links").toUpperCase()}
              </h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <div
                        name="link_name"
                        className="form-control tool-name"
                        placeholder="Link name"
                      >
                        {data.linkName}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    {data.purpose && (
                      <TextareaAutosize
                        cacheMeasurements
                        type="text"
                        value={data.purpose}
                        className="form-control purpose logit"
                        name="link_purpose"
                        placeholder="+ Add purpose"
                        disabled
                        readOnly
                      />
                    )}
                  </div>

                  <div className="form-group" data-id="task-list">
                    <h4>Tasks</h4>
                    {data.tasks && data.tasks.length > 0 && (
                      <div className="row task-list">
                        {data.tasks && data.tasks.length > 0
                          ? data.tasks.map((r, tindex) => {
                            return (
                              <div
                                className="col-xs-12 task"
                                key={tindex}
                                style={{ position: "relative" }}
                              >
                                <TextareaAutosize
                                  cacheMeasurements
                                  id={`task${tindex}`}
                                  type="text"
                                  placeholder="+ Add task"
                                  name="tasks"
                                  className="form-control logit"
                                  value={r}
                                  disabled
                                >
                                  <span className="fa fa-circle"> </span>
                                </TextareaAutosize>
                              </div>
                            );
                          })
                          : null}
                      </div>
                    )}
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={data} skill={data} type="REPORT_DATA" />
                  </div>

                  <div className="form-group" data-id="task-list">
                    <WorkLoadSRDL
                      planTotal={Number(parseFloat(data.planTotal).toString().replace(",", "."))}
                      status={Number(sumFTEBody(data.workload, [data.owner]).toString().replace(",", "."))}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group link-owner">
                    <div className="row">
                      <div className="col-xs-9" style={{ paddingRight: 0 }}>
                        <h4>Owner</h4>
                        {curOwner && Object.keys(curOwner).length > 0 && (
                          <div className="report-tool-item">
                            {/* fade-in and fade-out pills - START */}
                            {IsFadeIn(curOwner._id, peoples, data._id) && <span
                              onClick={() => {
                                // Yet to be developed
                                // handleFadeInOut();
                              }}
                              className="custom-badge fade-in"
                            >
                              <img src="/images/fadein.png" />
                            </span>}{" "}

                            {/* fade-in and fade-out pills - END */}
                            {IsFadeOut(curOwner._id, peoples, data._id) && <span
                              onClick={() => {
                                // Yet to be developed
                                // handleFadeInOut();
                              }}
                              className="custom-badge fade-out"
                            >
                              <img src="/images/fadeout.png" />
                            </span>}{" "}

                            {curOwner.workRole === "External" ? (
                              <>
                                <span
                                  className="custom-badge blue"
                                  style={{
                                    display: "inline-block",
                                    float: "left",
                                    marginBottom: "0",
                                    marginRight: "5px"
                                  }}
                                >EXT</span>{" "}
                                {`${curOwner.fname} ${curOwner.lname}`}
                              </>
                            ) : null}

                            {`${curOwner.fname || ""} ${curOwner.lname || ""}`}

                          </div>
                        )}

                        <div
                          className="form-group flag"
                          style={{ display: "none" }}
                        >
                          <label>
                            Links without owners will be flagged:{" "}
                            <span className="custom-badge red">NO</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-xs-3">
                        <h4>FTE</h4>
                        <div>
                          {curOwner && Object.keys(curOwner).length > 0 && <input
                            type="number"
                            className="form-control fte"
                            value={
                              (data &&
                                data.workload &&
                                data.workload.length > 0 &&
                                data.workload[0].plan) ||
                              ""
                            }
                            onWheel={numberInputOnWheelPreventChange}
                          />}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group link-stand-in">
                    <h4>Stand In</h4>
                    {curStandIn && Object.keys(curStandIn) > 0 ? (
                      <div className="report-tool-item">
                        {curStandIn.workRole === "External" ? (
                          <span className="custom-badge blue">EXT</span>
                        ) : null}
                        {/* fade-in and fade-out pills - START */}
                        {IsFadeIn(curStandIn._id, peoples, data._id) && <span
                          onClick={() => {
                            // Yet to be developed
                            // handleFadeInOut();
                          }}
                          className="custom-badge fade-in"
                        >
                          <img src="/images/fadein.png" />
                        </span>}{" "}

                        {/* fade-in and fade-out pills - END */}
                        {IsFadeOut(curStandIn._id, peoples, data._id) && <span
                          onClick={() => {
                            // Yet to be developed
                            // handleFadeInOut();
                          }}
                          className="custom-badge fade-out"
                        >
                          <img src="/images/fadeout.png" />
                        </span>}{" "}
                        {`${curStandIn.fname || ""} ${curStandIn.lname || ""}`}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group member-in">
                    <h4>Member In</h4>
                    <div className="flag">
                      <label>This {/* link */ GetAliasesName(teams, "Links").toLowerCase()} is currently member in:</label>
                    </div>
                    <div
                      className="row"
                      style={{ marginRight: 0, marginLeft: 0 }}
                    >
                      {memberIds && memberIds.length > 0
                        ? memberIds.map((e, index) => {
                          return (
                            <div key={index} className="col-xs-12">
                              {e.memberName}
                              {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                <span
                                  style={{
                                    display: "inlineBlock",
                                    marginBottom: "0",
                                  }}
                                  className="custom-badge lightgray person-pill text-red"
                                >
                                  <span className="text-red">XTD</span>
                                </span>
                              ) : null}
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>

                  <div className="form-group">
                    <h4>Tags</h4>

                    <div className="tagator_tags">
                      {data.tags && data.tags.length > 0
                        ? data.tags.map((e, index) => {
                          return (
                            <div key={index} className="tagator_tag">
                              {e}
                              <div style={{ clear: "both" }}></div>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  <button
                    type="button"
                    onClick={() => historysGet(data._id)}
                    style={
                      historysMessage.length > 0
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    className="btn btn-mytpt-gray history"
                  >
                    {/* <i className="fa fa-history"></i> */}
                    <img alt="" src="../images/newicons/history.svg" />
                  </button>
                </div>
                <div className="col-xs-8 text-right">
                  <button
                    type="button"
                    onClick={CloseLinkModal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
