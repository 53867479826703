import React from 'react';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { updatePeople } from '../../../store/actions/peopleAction';
import { historyCreate } from '../../../store/actions/historyAction';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

/**
 * Handles fade-in and fade-out animations for the popover.
 * @param {Event} e - The event object.
 * @param {string} type - The type of action (e.g., 'fade-in', 'fade-out').
 */
export const handleFadeInOut = (e, type) => {
    setTimeout(() => {
        const popoverElement = document.getElementById('popover-basic');
        if (popoverElement) {
            popoverElement.classList.remove('fade');
        }
    }, 300);
};

/**
 * Creates a reusable fade-in/out OverlayTrigger with Popover component.
 * @param {Object} props - The component props.
 * @param {boolean} props.show - The current visibility state of the popover.
 * @param {function} props.setShow - Function to toggle the visibility of the popover.
 * @param {string} props.placement - The placement of the popover ('left', 'right', etc.).
 * @param {function} props.handleFadeInOut - Function to handle fade-in/out animation.
 */
export const FadePopoverTrigger = ({ show, focusPeopleId, id, setShow, placement, handleFadeInOut }) => {
    let dispatch = useDispatch();
    const { loading, successMessage, error, authenticate, myInfo } = useSelector(
        (state) => state.auth
    );
    const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector(
        (state) => state.peoples
    );
    let adminId = myInfo.userId;
    const addOrRemoveFromFadeIn = () => {
        let targetPeople = peoples.find(peoples => peoples._id === focusPeopleId);
        if (targetPeople) {
            let peopleSort = localStorage.getItem("peopleSort");
            let teamId = localStorage.getItem("teamId");
            dispatch(updatePeople({ ...targetPeople, peopleSort, teamId, adminId, fadeInId: id, fadeInOutUpdater: true }))
        }
    };
    const addOrRemoveFromFadeOut = () => {
        let targetPeople = peoples.find(peoples => peoples._id === focusPeopleId);
        if (targetPeople) {
            let peopleSort = localStorage.getItem("peopleSort");
            let teamId = localStorage.getItem("teamId");
            dispatch(updatePeople({ ...targetPeople, peopleSort, teamId, adminId, fadeOutId: id, fadeInOutUpdater: true }))
        }
    }

    const hidePopover = () => setShow(false);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header className="fadeinout popover-title" style={{textAlign: 'right', cursor: 'pointer'}}>
                {/* Close{' '} */}
                {/* <a className="close" onClick={hidePopover}>
                    ×
                </a> */}
                <img src="/images/icon-info.png" style={{ width: "12px", verticalAlign: "middle" }} onClick={()=>window.open("https://teamdecoder.tawk.help/article/fading-in-out", "_blank")}/>
            </Popover.Header>
            <Popover.Body>
                <div className="popover-content">
                    <a className="fadeinout pop-actions" onClick={() => { addOrRemoveFromFadeIn() }}>
                        <span className="custom-badge fade-in"><img alt="" src="images/fadein.png" /></span>
                        <span  >
                            fade in
                        </span>
                    </a>
                    <a className="fadeinout pop-actions" onClick={() => { addOrRemoveFromFadeOut() }}>
                        <span className="custom-badge fade-out"><img alt="" src="images/fadeout.png" /></span>
                        <span  >
                            fade out
                        </span>
                    </a>
                </div>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement={placement} overlay={popover} rootClose
        /* onToggle={(nextShow) => setShow(nextShow)} */>
            <a
                className="card-fade-in-out"
                onBlur={hidePopover} // Hide on blur
                onClick={(e) => handleFadeInOut(e, 'Domains')}
            >
                <i className="icon fas fa-ellipsis-v"></i>
            </a>
        </OverlayTrigger>
    );
};
