
import {REPORT_AUTH_SUCCESS ,REPORT_AUTH_FAIL , REPORT_SUCCESS_MESSAGE_CLEAR ,REPORT_ERROR_CLEAR ,
     REPORT_TEAM_GET_SUCCESS , REPORT_PEOPLE_GET_SUCCESS , REPORT_TEAM_GET_ERROR , REPORT_PEOPLE_GET_ERROR ,REPORT_SKILL_GET_ERROR
  , REPORT_SKILL_GET_SUCCESS , REPORT_ROLE_GET_SUCCESS , REPORT_ROLE_GET_ERROR , REPORT_DOMAIN_GET_SUCCESS 
  , REPORT_DOMAIN_GET_ERROR , REPORT_LINK_GET_SUCCESS , REPORT_LINK_GET_ERROR, REPORT_CIRCLE_GET_SUCCESS,
  REPORT_PROJECT_GET_SUCCESS, REPORT_PROJECT_GET_ERROR, REPORT_MEETING_GET_SUCCESS, REPORT_MEETING_GET_ERROR,REPORT_REQUEST_SENT_SUCCESS,REPORT_REQUEST_SENT_ERROR, 
  REPORT_ALL_TEAM_GET_SUCCESS} from '../types/reportType'
import deCodeToken from 'jwt-decode'

const reportState = {
    reportError: '',
    successMessage: '',
    reportInfo: '',
    reportPlan: '',
    reportUserId: '',
    reportTeam: [],
    reportAllTeams: [],
    reportPeople: [],
    reportSkill: [],
    reportRole : [],
    reportDomain: [],
    reportLink: [],
    reportCircle: [],
    reportProject:[],
    reportMeeting:[],
    reportNotes:[],
}

const tokenDecode = (reportTokens) => {
    const tokenDecoded = deCodeToken(reportTokens);
    const expTime = new Date(tokenDecoded.exp * 1000);
    if (new Date() > expTime) {
        return null
    }
    return tokenDecoded;
}

const getToken = localStorage.getItem('reportTokens');
// console.log(getToken)
if (getToken) {
    const getInfo = tokenDecode(getToken)
    // console.log(getInfo);
    if (getInfo) {
        reportState.reportInfo = getInfo;
    }
}


export const reportReducer = (state = reportState, action) => {
    const { payload, type } = action;

    if (type === REPORT_AUTH_FAIL ) {
        return {
            ...state,
            reportError: payload.error,
            reportInfo: ''
        }
    }
    if (type === REPORT_AUTH_SUCCESS) {
        const reportInfo = tokenDecode(payload.token);
        return {
            ...state,
            reportInfo: reportInfo,
            reportPlan: payload.plan,
            reportUserId: payload.userId,
            successMessage: payload.successMessage,
            error: ''
        }
    }

    if (type === REPORT_SUCCESS_MESSAGE_CLEAR) {
        return {
            ...state,
            successMessage: ''
        }
    }
    if (type === REPORT_ERROR_CLEAR) {
        return {
            ...state,
            reportError: ''
        }
    }

    if(type === REPORT_TEAM_GET_SUCCESS){
        return {
            ...state,
            reportTeam: payload.reportTeam,
            reportAllTeams: payload.teams
        }
    }

    if(type === REPORT_ALL_TEAM_GET_SUCCESS){
        return {
            ...state,
            reportAllTeams: payload.teams
        }
    }

    if (type === REPORT_TEAM_GET_ERROR) {
        return {
            ...state,
            reportError: payload.reportError //
        }
    }

    if(type === REPORT_PEOPLE_GET_SUCCESS){
        return {
            ...state,
            reportPeople: payload.reportPeople
        }
    }
    if (type === REPORT_PEOPLE_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }

    if(type === REPORT_SKILL_GET_SUCCESS){
        return {
            ...state,
            reportSkill: payload.reportSkill
        }
    }
    if (type === REPORT_SKILL_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }

    if(type === REPORT_ROLE_GET_SUCCESS){
        return {
            ...state,
            reportRole: payload.reportRole
        }
    }
    if (type === REPORT_ROLE_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }
   
    if(type === REPORT_DOMAIN_GET_SUCCESS){
        return {
            ...state,
            reportDomain: payload.reportDomain
        }
    }
    if (type === REPORT_DOMAIN_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }

    if(type === REPORT_LINK_GET_SUCCESS){
        return {
            ...state,
            reportLink: payload.reportLink
        }
    }
    if (type === REPORT_LINK_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }
    if(type === REPORT_CIRCLE_GET_SUCCESS){
        return{
            ...state,
            reportCircle: payload.reportCircle
        }
    }
    if (type === REPORT_LINK_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }
    if(type === REPORT_PROJECT_GET_SUCCESS){
        return{
            ...state,
            reportProject: payload.reportProjet
        }
    }
    if (type === REPORT_PROJECT_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }
    if(type === REPORT_MEETING_GET_SUCCESS){
        return{
            ...state,
            reportMeeting: payload.reportMeeting
        }
    }
    if (type === REPORT_MEETING_GET_ERROR) {
        return {
            ...state,
            reportError: ''
        }
    }
    if (type === REPORT_REQUEST_SENT_SUCCESS) {
        return {
            ...state,
            successMessage: payload.successMessage
        }
    }
    if (type === REPORT_REQUEST_SENT_ERROR) {
        return {
            ...state,
            reportError: payload.errorMessage
        }
    }
    return state;
}

