module.exports = {
  SERVER_URI: 'https://server.teamdecoder.com/api-v4.0',
  CLIENT_URL: "https://app.teamdecoder.com",
  CLIENT_MODE: "production",
  CARD_LIMIT: 5,
  TEAM_LIMIT: 1,
  ALLOW_WORKLOAD: true,
  ALLOW_PURPOSE_TREE: false,
  ALLOW_SURVEY: false,
  ALLOW_COMPARE_MODE: false,
  ALLOW_CAMPFIRE: false,
  ALLOW_WORKFLOW: false,
  RC_SITE_KEY: '6LcwpJIiAAAAANTtRDeyjA39_NoNA7ocYH0FE5Cw',
  GOOGLE_CLIENT_ID: '62219936943-mgeaubigu91b89evta6jm8s8hsgk81is.apps.googleusercontent.com',
  CLIENT_ID: '662888a7f85d4b3eded8e7a7',
  LogRocketSetUp: true,
};