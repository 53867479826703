import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../store/types/historyType";
import { historyGet } from "../../../store/actions/historyAction";
import Documents from "../../Body/Modal/Documents";

export default function PeopleModal({ data, closePeople }) {
  const {
    error,
    successMessage,
    reportInfo,
    reportTeam,
    reportPeople,
    reportSkill,
    reportRole,
    reportDomain,
    reportLink,
    reportCircle,
    reportProject,
    reportMeeting,
  } = useSelector((state) => state.authReport);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);

  const dispatch = useDispatch();

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  //mentor
  let curMentor = {};

  if (
    data &&
    data.mentor &&
    data.mentor.length > 0 &&
    reportPeople.length > 0
  ) {
    for (let i = 0; i < reportPeople.length; i++) {
      if (reportPeople[i]._id === data.mentor) {
        curMentor = reportPeople[i];
      }
    }
  }

  //mentees
  let curMentees = [];
  if (data && data.mentees.length > 0 && reportPeople.length > 0) {
    for (let i = 0; i < data.mentees.length; i++) {
      for (let j = 0; j < reportPeople.length; j++) {
        if (reportPeople[j]._id === data.mentees[i]) {
          curMentees.push(reportPeople[j]);
        }
      }
    }
  }


  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  const ClosePeopleModal = () => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closePeople()
  }

  return (
    <div id="peopleModal" role="dialog">
      <div className="modal-dialog modal-lg modal-dialog-scrollable report-modal">
        <form className="form">
          {/* Modal content*/}
          <div className="modal-content">
            <div className="modal-header header-yellow">
              <h4 className="modal-title">
                <img
                  src="../images/icon-people.png"
                  style={{
                    width: "17px",
                    marginTop: "-3px",
                    marginRight: "5px",
                  }}
                />{" "}
                PERSON
              </h4>
            </div>
            <div className="modal-body">
              <div className="alert"></div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="input-group mb-3">
                      <input
                        type="hidden"
                        id="first_name"
                        name="first_name"
                        value={data.fname}
                      />
                      <div
                        name="first_name"
                        className="form-control tool-name"
                        placeholder="First name"
                      >
                        {data.fname}
                      </div>
                    </div>
                    <div className="input-group mb-3">
                      <input
                        type="hidden"
                        id="last_name"
                        name="last_name"
                        value={data.lname}
                      />
                      <div
                        name="last_name"
                        className="form-control tool-name"
                        placeholder="Last name"
                      >
                        {data.lname}
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="phone"
                      name="phone"
                      value={data.mobile}
                      className="form-control"
                      placeholder="+ Add phone number"
                      autoComplete="off"
                      disabled="disabled"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      value={data.email}
                      className="form-control"
                      placeholder="+ Add email address"
                      autoComplete="off"
                      disabled="disabled"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="radio"
                      className="logit"
                      id="internal"
                      name="member_type"
                      value="Internal"
                      checked={data.workRole === "Internal" ? true : false}
                      disabled="disabled"
                    />
                    <label htmlFor="internal" className="inline-label">Internal</label>

                    <input
                      type="radio"
                      className="logit"
                      id="external"
                      name="member_type"
                      value="External"
                      checked={data.workRole === "External" ? true : false}
                      disabled="disabled"
                    />
                    <label htmlFor="external" className="inline-label">External</label>
                  </div>
                  <div className="form-group flag" style={{ display: "none" }}>
                    <label>
                      People marked external will be flagged:{" "}
                      <span className="custom-badge blue">EXT</span>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mentor">
                    <h4>Mentor</h4>
                    {Object.keys(curMentor).length > 0 ? (
                      <div className="selectize-control mentor s_selectize readonly single">
                        <div className="report-tool-item">{`${curMentor.fname} ${curMentor.lname}`}</div>
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <h4>Mentees</h4>
                    {curMentees && curMentees.length > 0
                      ? curMentees.map((e, index) => {
                          return (
                            <div key={index} className="report-tool-item">
                              {/* {e.workRole === "External" ? (
                                <span className="custom-badge blue">EXT</span>
                              ) : null}
                              {`${e.fname} ${e.lname}`}{" "} */}

                              {e.workRole === "External" ? (
                                <><span className="custom-badge blue" style={{ display: 'inline-block', marginBottom: '0' }}>EXT</span> {`${e.fname} ${e.lname}`}</>
                              ) : `${e.fname} ${e.lname}`}
                            </div>
                          );
                        })
                      : null}
                  </div>
                  <Documents data={data} type="MY-REPORT" />

                  <div className="form-group">
                    <h4>Tags</h4>
                    <div className="tagator_element">
                      {data.tags && data.tags.length > 0
                        ? data.tags.map((e, index) => {
                            return (
                              <div key={index} className="tagator_tag">
                                {e}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  <button
                    onClick={() => historysGet(data._id)}
                    style={
                      historysMessage.length > 0
                        ? { backgroundColor: "#F3D068" }
                        : null
                    }
                    type="button"
                    className="btn btn-mytpt-gray history"
                  >
                   {/* <i className="fa fa-history"></i> */}
                   <img alt="" src="../images/newicons/history.svg" />
                  </button>
                </div>
                <div className="col-xs-8 text-right">
                  <button
                    type="button"
                    onClick={ClosePeopleModal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return <p key={index} dangerouslySetInnerHTML={{ __html: e }}></p>;
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
