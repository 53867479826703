export const IsFadeIn = (peopleId, people, id) => {
    let val = false;
    let targetPeople = people.find(p => p._id === peopleId);
    if (targetPeople) {
        if (targetPeople.fadeInIds && targetPeople.fadeInIds.includes(id)) {
            val = true;
        } else {
            val = false;
        };
    }
    return val;
};

export const IsFadeOut = (peopleId, people, id) => {
    let val = false;
    let targetPeople = people.find(p => p._id === peopleId);
    if (targetPeople) {
        if (targetPeople.fadeOutIds && targetPeople.fadeOutIds.includes(id)) {
            val = true;
        } else {
            val = false;
        };
    }
    return val;
};
