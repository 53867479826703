import React, { useState, useEffect } from 'react'
import { Autologin, userReset } from '../../store/actions/authAction'
import { useDispatch, useSelector } from 'react-redux';
import { useAlert } from 'react-alert'
import { SUCCESS_MESSAGE_CLEAR, ERROR_CLEAR } from '../../store/types/authType'
import { useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Navbar from '../../Component/Header/Navbar';
import { RC_SITE_KEY, CLIENT_URL } from "../../config/keys"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import deCodeToken from "jwt-decode";
import { SyncLoader } from 'react-spinners';
import Footer from '../../Component/Footer/Footer';

const tokenDecode = (token) => {
    const tokenDecoded = deCodeToken(token);
    const expTime = new Date(tokenDecoded.exp * 1000);
    if (new Date() > expTime) {
        return null;
    }
    return tokenDecoded;
};

export default function ResetPassword(data) {
    const imageArr = data.data;
    // console.log(data)

    document.body.style.backgroundImage = `url(${data.data.image})`;
    document.body.classList.remove(`wallpaper-bg-0`);
    document.body.classList.remove(`wallpaper-bg-1`);
    document.body.classList.remove(`wallpaper-bg-2`);
    document.body.classList.remove(`wallpaper-bg-3`);
    document.body.classList.remove(`wallpaper-bg-4`);

    document.body.classList.remove(`purpose-tree`);
    
    document.body.classList = `no-app-pages body1 wallpaper-bg-${4}`;

    /* if (data.objNumber !== 2 && data.objNumber !== 3) {
        document.body.classList.add(`menu2`);
    } else { */
        document.body.classList.remove(`menu2`);
    // }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { token } = useParams();

    const recaptchaRef = React.useRef();

    const alert = useAlert();
    const { loading, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth)

    const [loadings, setLoadings] = useState(false);
    const [pageMode, setPageMode] = useState(false);
    const [buttonText, setButtonText] = useState('Reset Password');
    const [userRole, setUserRole] = useState('User');

    const [state, setState] = useState({
        password: '',
        confirmPassword: "",
        token: token
    });

    const inputHendle = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    // console.log(state)

    const resetPassword = async (e) => {
        setLoadings(true)
        e.preventDefault()
        const token = await recaptchaRef.current.executeAsync();
        state.rc_token = token
        dispatch(userReset(state))
    }

    let userData = '';

    useEffect(() => {
        userData = tokenDecode(token);

        if (authenticate) {
            // history.push('/')
            // console.log('ok')
            window.location.href = CLIENT_URL;
        }

        if (successMessage) {
            localStorage.setItem('passwordResetDone', 'yes');
            // const rc_token = await recaptchaRef.current.executeAsync();
            // setTimeout(() => {
            //     dispatch({ type: SUCCESS_MESSAGE_CLEAR })
            //     /* autologin for co-Admin */
            //     if (userData.role === 'Co-Admin') {
            //         const rc_token = await recaptchaRef.current.executeAsync();
            //         const state = {
            //             email: tokenData.email,
            //             userUniqId: tokenData.userId,
            //             rc_token: rc_token
            //         }
            //         dispatch(Autologin(state))
            //     } else {
            //         // navigate("/login");
            //         window.location.href = CLIENT_URL;
            //     }
            // }, 2000)
            setTimeout(async () => {
                dispatch({ type: SUCCESS_MESSAGE_CLEAR });

                try {
                    // Autologin for Co-Admin
                    if (userData.role === 'Co-Admin') {
                        setPageMode(true);
                        const rc_token = await recaptchaRef.current.executeAsync();
                        const state = {
                            email: userData.email,
                            userUniqId: userData.userId,
                            rc_token: rc_token
                        };
                        dispatch(Autologin(state));
                    } else {
                        // navigate("/login");
                        window.location.href = CLIENT_URL;
                    }
                } catch (error) {
                    console.error('Error during autologin or redirection:', error);
                }
            }, 2000);
        }


        if (error) {
            setLoadings(false)
            // dispatch({ type: ERROR_CLEAR });
        }
    }, [successMessage, error])

    useEffect(() => {
        if (userData && userData.role === 'Co-Admin') {
            // console.log(userData);
            setButtonText('Set and Login');
            setUserRole('Co-Admin');
        }
    }, [state]);

    const [checked, setChecked] = useState(false);

    const handleChange = () => {
        setChecked(!checked);
    };

    // console.log(155, userData)

    return (
        <div>
            {
                pageMode ? (
                    <div className="page-loader">
                        <SyncLoader color="#36d7b7" />
                    </div>
                ) : (
                    <>
                        {/* <!-- NAVBAR --> */}
                        <Navbar />
                        {/* <!-- END NAVBAR --> */}
                        <div className="container vertical-center">
                            <div className="row">
                                <div className="col-md-7 col-sm-12">
                                    <div className="flex-caption2">
                                        <div className="structuring-lo">
                                            <h4 style={{ "color": '#ffffff' }} dangerouslySetInnerHTML={{ __html: userRole === 'User' ? imageArr.text : 'Hello co–admin! Please create your password.' }}></h4>
                                            {userRole === 'User' ?
                                                <>
                                                    <a href="/pricing/" className="button button-darkgrey-v46">Create account</a>
                                                    <a target="_blank" rel="noopener nofollow noreferrer" href="http://teamdecoder.com/" className="button button-white-v46">More info</a>
                                                </> : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-1 col-sm-12 mb-5"></div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="login-from">
                                        {token && token.length > 0 ?
                                            <form onSubmit={resetPassword} id="mytpt-report-request">
                                                <h3>Set your password</h3>
                                                {successMessage ?
                                                    <div className="alert alert-success" style={{ "width": "100%" }}>{successMessage}</div>
                                                    : null}
                                                {error ?
                                                    <div className="alert alert-danger" style={{ "width": "100%" }}>{error}</div>
                                                    : null}
                                                <p>Enter password and confirm password.</p>
                                                <div className="alert" style={{ "display": "none", "width": "100%" }}></div>

                                                <div className="mb-3" style={{ "display": "block", "position": "relative", "width": "100%" }}>
                                                    <input type={checked ? "text" : "password"} name='password' onChange={inputHendle} className="form-control" id="password" placeholder="Password"
                                                        autoComplete="off" />
                                                    <div id="togglePassword">
                                                        <svg style={checked ? { "display": "block" } : { "display": "none" }} onClick={() => handleChange()} xmlns="http://www.w3.org/2000/svg"
                                                            width="16" height="16" fill="currentColor"
                                                            className="bi bi-eye-fill" viewBox="0 0 16 16">
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                                            <path
                                                                d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z">
                                                            </path>
                                                        </svg>

                                                        <svg style={checked ? { "display": "none" } : { "display": "block" }} onClick={() => handleChange()} xmlns="http://www.w3.org/2000/svg"
                                                            width="16" height="16" fill="currentColor"
                                                            className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                                                            <path
                                                                d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z">
                                                            </path>
                                                            <path
                                                                d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>

                                                <input type={checked ? "text" : "password"} name='confirmPassword' onChange={inputHendle} className="form-control" id="confirm-password" placeholder="Confirm Password"
                                                    autoComplete="off" />
                                                <button type="submit" className="g-recaptch">{loadings ?
                                                    <FontAwesomeIcon icon={faSpinner} spin style={{ color: "#000000" }} />
                                                    : buttonText}</button>
                                                <p style={{ marginTop: '5px' }}><small>8-15 characters, must include one upper case, lower case, number and a special character.</small></p>
                                            </form>
                                            :
                                            <div className="alert alert-danger">Invalid login or reset key.</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Footer />
                    </>
                )
            }
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={RC_SITE_KEY}
            />
        </div>
    )
}
