import React, { useState, useEffect, useRef } from "react";
import Body from "../Body/Body";
import PeopleModal from "../Body/Modal/PeopleModal";
import SkillModal from "../Body/Modal/SkillModal";
import RoleModal from "../Body/Modal/RoleModal";
import ProjectModel from "../Body/Modal/ProjectModal";
import CircleModel from "../Body/Modal/CircleModal";
import LinkModel from "../Body/Modal/LinkModal";
import DomainModel from "../Body/Modal/DomainModal";
import TeamModel from "../Body/Modal/TeamModal";
import ProfileModel from "../Body/Modal/ProfileModal";
import SettingModal from "../Body/Modal/SettingModal";
import InfoModel from "../Body/Modal/InfoModal";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import Swal from "sweetalert2";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import html2canvas from "html2canvas";
import useScrollbarSize from "react-scrollbar-size";
import { getPeople } from "../../store/actions/peopleAction";
import { createSkills, deleteSkills, getSkills } from "../../store/actions/skillAction";
import { getDomain } from "../../store/actions/domainAction";
import { getLink } from "../../store/actions/linkAction";
import { createCircle, deleteCircle, getCircle } from "../../store/actions/circleAction";
import { createProject, deleteProject, getProject } from "../../store/actions/projectAction";
import { createRole, deleteRole, getRole } from "../../store/actions/roleAction";
import { getTags } from "../../store/actions/tagsAction";
import { getMeetings } from "../../store/actions/meetingAction";
import { getMember } from "../../store/actions/memberAction";
import {
  noteCreate,
  getNote,
  updateNote,
} from "../../store/actions/noteAction";
import {
  createState,
  updateState,
  getState,
} from "../../store/actions/stateAction";
import {
  STATE_SUCCESS_MESSAGE_CLEAR,
  STATE_ERROR_CLEAR,
  STATELM_SET_SUCCESS,
} from "../../store/types/stateType";
import { useDispatch, useSelector } from "react-redux";
import {
  getTeam,
  getAllTeamData,
  getTemplate,
} from "../../store/actions/teamAction";
import { useAlert } from "react-alert";
import { SIGN_OUT } from "../../store/types/authType";
import { useNavigate, useSearchParams } from "react-router-dom";
import { update } from "immutable";
import {
  userUpdate,
  getLoginUserData,
  userLoginHistory,
  getuserMetaData,
} from "../../store/actions/authAction";
import StartIcon from "@mui/icons-material/Start";
import { SERVER_URI, CLIENT_URL, CLIENT_ID } from "../../config/keys";
import { getInfo } from "../../store/actions/infoAction";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { userReLogin } from "../../store/actions/authAction";
import { getPFMember } from "../../store/actions/pfmemberAction";
import { userVerify, paymentVerify } from "../../store/actions/authAction";
import { GoogleLogout } from "react-google-login";
import { PAYMENT_VERIFIY_MSG_CLEAR } from "../../store/types/authType";
import { SyncLoader } from "react-spinners";
import { generateStripeUpgradeUrl } from "../../store/actions/pricingAction";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

import PeopleModeTool from "../Body/ToolModal/PeopleModeTool";
import CircleModelTool from "../Body/ToolModal/CircleModeTool";
import { clearLocalStorage } from "../../HelperFunctions/ClearLocalStorage";
import { freezeWindow, unfreezeWindow } from "../../HelperFunctions/Overlay";
import { IconButton } from "@mui/material";
import CompareModePage from "../../Pages/CompareMode/CompareModePage";
import EditTemplateModal from "../Body/Modal/TemplateModal";
import TrashModal from "../Body/Modal/TrashModal";
import { HeaderFTE } from "./HeaderFTE";
import AccessManagement from "../Body/Modal/AccessManagement";
import Permission_ModePopUp from "../Permission_ModePopUp/Permission_ModePopUp";
import ModalWrapper from "../Body/Modal/ConditionalOveralayModal";
import ConditionalOveralayModal from "../Body/Modal/ConditionalOveralayModal";
import ActivityTracker from "../Body/ActivityTracker";
import ReferralModal from "../Body/Modal/ReferralModal";
import AnalyticsPage from "../../Pages/AnalyticsPage/AnalyticsPage";
import { GetAliasesName } from "../../HelperFunctions/GetAliasesName";
import PurposeTree from "../../Pages/PurposeTree/PurposeTree";
import { ROLE_SUCCESS_MESSAGE_CLEAR } from "../../store/types/roleType";
import { HISTORY_DATAS_CLEAR } from "../../store/types/historyType";
import { SKILL_SUCCESS_MESSAGE_CLEAR } from "../../store/types/skillType";
import { PROJECT_ID_CLEAR, PROJECT_SUCCESS_MESSAGE_CLEAR } from "../../store/types/projectType";
import { CIRCLE_ID_CLEAR, CIRCLE_SUCCESS_MESSAGE_CLEAR } from "../../store/types/circleType";
import HomePage from "../../Pages/HomePage/HomePage";
import SideNav from "../SideNav/SideNav";
import { ALLOW_COMPARE_MODE, ALLOW_PURPOSE_TREE, ALLOW_SURVEY } from "../../config/keys";
import { showUpgradeNotification } from "../../HelperFunctions/AccessControlPopup";

// document.body.style = "";
// document.body.classList = "";

const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // // marginRight: '-50%',
    // padding: "0px",
    // transform: 'translate(-50%, -50%)',
    // background: "#FFFF00",
    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
    // overflowX: "hidden",
    // overflowY: "auto",
  },
};

const customStyle = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // // marginRight: '-50%',
    // padding: "0px",
    // transform: 'translate(-50%, -50%)',
    // background: "#FFFF00",
    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)",
    // overflowX: "hidden",
    // overflowY: "auto",
  },
};

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  // background: isDraggingOver ? "lightblue" : "lightgrey",
  // padding: grid,
  // width: 250,
});
export default function User() {
  const [peopleOpen, setpeople] = useState(false);
  const [skillOpen, setskill] = useState(false);
  const [roleOpen, setrole] = useState(false);
  const [projectOpen, setproject] = useState(false);
  const [circleOpen, setcircle] = useState(false);
  const [linkOpen, setlink] = useState(false);
  const [domainOpen, setdomain] = useState(false);
  const [teamOpen, setteam] = useState(false);
  const [templateOpen, setOpenTemplates] = useState(false);
  const [profileOpen, setprofile] = useState(false);
  const [accessManagementOpen, SetAccessManagement] = useState(false);
  const [settingOpen, setsetting] = useState(false);
  const [trashOpen, setTrash] = useState(false);
  const [reffOpen, setReff] = useState(false);
  const [infoOpen, setinfo] = useState(false);
  const [pMTool, setpMTool] = useState(false);
  const [cMTool, setcMTool] = useState(false);
  const [purposetreeloader, setPurposetreeLoader] = useState({ skill: false, role: false, domain: false, link: false, project: false, circle: false });

  const [temporarilyMovedItem, setTemporarilyMovedItem] = useState(null);

  const openPeople = () => setpeople(true);
  const closePeople = () => setpeople(false);

  const openSkill = () => setskill(true);
  const closeSkill = () => setskill(false);

  const openRole = () => setrole(true);
  const closeRole = () => setrole(false);

  const openProject = () => setproject(true);
  const closeProject = () => setproject(false);

  const openCircle = () => setcircle(true);
  const closeCircle = () => setcircle(false);

  const openLink = () => setlink(true);
  const closeLink = () => setlink(false);

  const openDomain = () => setdomain(true);
  const closeDomain = () => setdomain(false);

  const openTeam = () => setteam(true);
  const closeTeam = () => setteam(false);

  // open edit templates
  const openEditTemplate = () => setOpenTemplates(true);
  const closeEditTemplate = () => setOpenTemplates(false);

  const openProfile = () => setprofile(true);
  const closeProfile = () => setprofile(false);

  const openAccessManagement = () => {
    if ((curUserData.plan !== "FREE" || myInfo.role !== "User") && (myInfo.role !== "Co-Admin")) {
      SetAccessManagement(true);
    } else {
      showUpgradeNotification(dispatch, Swal, myInfo, curUserData, freezeWindow, generateStripeUpgradeUrl);
    }
  };
  const closeAccessManagement = () => SetAccessManagement(false);

  const openSetting = () => setsetting(true);
  const closeSetting = () => setsetting(false);

  const openTrash = () => setTrash(true);
  const closeTrash = () => setTrash(false);

  const openInfo = () => setinfo(true);
  const closeInfo = () => setinfo(false);

  const openPeopleModeTool = () => setpMTool(true);
  const closePeopleModeTool = () => {
    setpMTool(false);
    setTemporarilyMovedItem(null);
  };

  const openCircleModeTool = () => setcMTool(true);
  const closeCircleModeTool = () => {
    setcMTool(false);
    setTemporarilyMovedItem(null);
  };

  const openReferal = () => setReff(true);
  const closeReferal = () => setReff(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();




  const {
    loading,
    successMessage,
    paySuccessMessage,
    error,
    authenticate,
    myInfo,
    curUserData,
    loginHistory,
    userMeta
  } = useSelector((state) => state.auth);
  const { teams, template, sMessage, errors } = useSelector(
    (state) => state.teams
  );
  const { peoples, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const { skills, skillMessagee, skillError } = useSelector(
    (state) => state.skills
  );
  const { roles, dRoles, roleMessagee, roleError } = useSelector(
    (state) => state.roles
  );
  const { domains, domainMessage, domainError } = useSelector(
    (state) => state.domains
  );
  const { links, linkMessagee, linkError } = useSelector(
    (state) => state.links
  );
  const { circles, dCircles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { info, infoMessagee, infoError } = useSelector((state) => state.infos);
  const {
    states,
    statesLM,
    cuStateId,
    statesMessagee,
    statesError,
    statesMessage,
  } = useSelector((state) => state.states);
  const { stripeUrl } = useSelector((state) => state.pricings);

  const { noteData, noteMessage, noteMessagee, noteError } = useSelector(
    (state) => state.notes
  );

  const [index, indexSet] = useState({});
  const [infoData, setInfoData] = useState({});

  const [focused, setFocused] = useState({ card: "", index: null, id: null });
  const [focusMode, setFocusedMode] = useState("");
  const [isModeSwitched, setIsModeSwitched] = useState(true);
  const [naveSort, setNaveSort] = useState("");
  const [focusList, setFocusList] = useState([]);
  const [fList, setFList] = useState({});

  const [compareMode, setCompareMode] = useState({
    active: false,
    startComparing: false,
    compareTypes: [],
    compareBetween: [],
  }); //{id: "", type: p/s/r}

  const [purposeTreeMode, setPurposeTreeMode] = useState(false);

  const [analyticsComponent, setAnalyticsComponent] = useState(false);

  const [showHomePage, setShowHomePage] = useState(true);

  const dragItem = useRef();
  const dragOverItem = useRef();
  const [list, setList] = useState({});

  const [allSearch, setAllSearch] = useState("");

  const makeFocusListObject = (idArray, type) => {
    if (type === "Circle") {
      /* new change */
      let mappedCircles = idArray
        .map((id) => {
          let circle = [...circles, ...dCircles].find((p) => p._id === id);
          return circle ? { name: circle.circleName, id: circle._id } : null;
        })
        .filter((circle) => circle !== null);
      return mappedCircles;
    } else if (type === "Project") {
      let mappedProjects = idArray
        .map((id) => {
          let project = projects.find((p) => p._id === id);
          return project
            ? { name: project.projectName, id: project._id }
            : null;
        })
        .filter((project) => project !== null);
      return mappedProjects;
    }
  };

  useEffect(() => {
    if (curUserData && Object.keys(curUserData).length > 0) {
      dispatch(getuserMetaData(curUserData._id));
    }
  }, [curUserData]);

  /* initially detect wich focusMode  */
  useEffect(() => {
    /* update state */
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(getState(obj));
    if (focusMode === "Project") {
      // console.log("project");
      // console.log(states.ProjectModeOrder);
      let sortedIdArray = makeFocusListObject(
        states.ProjectModeOrder,
        "Project"
      );
      // console.log(sortedIdArray);
      setFocusList(sortedIdArray);
    } else if (focusMode === "Circle") {
      // console.log("circle");
      // console.log(states.CircleModeOrder);
      let sortedIdArray = [];
      if (states.CircleModeOrder.length === 0) {
        const allCircleIds = [...circles, ...dCircles].map((sc) => sc._id);
        // console.log(allCircleIds);
        setFocusList(allCircleIds);
        sortedIdArray = makeFocusListObject(allCircleIds, "Circle");
      } else {
        sortedIdArray = makeFocusListObject(states.CircleModeOrder, "Circle");
      }
      // console.log(sortedIdArray);
      setFocusList(sortedIdArray);
    }
  }, [focusMode]);

  // Xls
  const [xls, setXls] = useState([]);
  const [xlsCM, setXlsCM] = useState([]);
  const [xlsName, setXlsName] = useState([]);

  const [isActive, setIsActive] = useState(false);

  const { height, width } = useScrollbarSize();

  const [clickedOnList, setClickedOnList] = useState({
    isClicked: false,
    listName: [],
  });
  const [isEXT, setIsEXT] = useState(false);
  const [isNO, setIsNO] = useState(false);
  const [isTag, setIsTag] = useState("");
  const [isTagData, setIsTagData] = useState("");
  const [isNToP, setIsNToP] = useState([]);
  const [tagFilteredObj, setTagFilteredObj] = useState({});
  const [extNoFilteredObj, setExtNoFilteredObj] = useState({});
  const [specialModeDataObj, setSpecialModeDataObj] = useState([]);

  const [listHide, setListHide] = useState({
    People: false,
    Skills: false,
    Roles: false,
    Domains: false,
    Links: false,
    Circles: false,
    Projects: false,
  });

  const [allNote, setAllNote] = useState(false);

  const [note, setNote] = useState({
    People: true,
    Skills: true,
    Roles: true,
    Domains: true,
    Links: true,
    Circles: true,
    Projects: true,
  });

  const [showGenericRole, setShowGenericRole] = useState(true);

  const [workLoadToggle, setWorkLoadToggle] = useState(false);

  const [saveNote, setSaveNote] = useState({
    People: false,
    Skills: false,
    Roles: false,
    Domains: false,
    Links: false,
    Circles: false,
    Projects: false,
  });

  const [loadings, setLoadings] = useState(false);
  const [load, setLoad] = useState(false);

  //team curTeam

  const [state, setState] = useState(`Select team`);

  const [searchParams] = useSearchParams();
  let paymentId = searchParams.get("session_id");

  let am = searchParams.get("am") || false;

  let showPage = searchParams.get("show");

  useEffect(() => {
    if (am) {
      dispatch(getLoginUserData({ userId: myInfo.userId }));
      openAccessManagement();
      searchParams.delete('am');
      // Update the URL without navigation
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [am, navigate, searchParams]);

  useEffect(() => {
    if (showPage && showPage == 'dashboard') {
      setShowHomePage(false);

      const url = new URL(window.location.href);
      url.searchParams.delete('show');

      // Update the URL in the browser without reloading
      window.history.replaceState({}, document.title, url.toString());
    }
  }, [showPage]);

  useEffect(() => {
    if (paymentId && paymentId.length > 0) {
      freezeWindow(false, true, true);
      dispatch(
        paymentVerify({
          session_id: paymentId,
          plan: localStorage.getItem("Rnavigate"),
        })
      );
    }
  }, [paymentId]);

  useEffect(() => {
    if (paySuccessMessage) {
      unfreezeWindow();
      Swal.fire({
        title: "Upgrade Successful",
        text: "You can always check and change your status in your profile! Go to Settings > Manage Subscription!",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
          dispatch({ type: PAYMENT_VERIFIY_MSG_CLEAR });
          localStorage.removeItem("Rnavigate");
          localStorage.removeItem("subPrices");
          window.location.reload();
        } else {
          navigate("/");
          dispatch({ type: PAYMENT_VERIFIY_MSG_CLEAR });
          localStorage.removeItem("Rnavigate");
          localStorage.removeItem("subPrices");
          window.location.reload();
        }
      });
    }
  }, [paySuccessMessage]);

  useEffect(() => {
    dispatch(getLoginUserData({ userId: myInfo.userId })).then(() => {
      dispatch(getTeam({ userId: myInfo.userId, token: localStorage.getItem("authToken") }));
      dispatch(getTemplate({ userId: CLIENT_ID }));
      // dispatch(getAllTeamData({ userId: myInfo.userId }));
      dispatch(
        userLoginHistory({ userId: myInfo.userId, token: myInfo.token })
      );
    });
  }, [myInfo, localStorage.getItem("authToken")]);

  //get states && mettings && members && user info && notes

  useEffect(() => {
    // console.log(curUserData, teams)
    if (curUserData.curTeam && curUserData.curTeam.length > 0) {
      localStorage.setItem("teamId", curUserData.curTeam);
      if (teams && teams.length > 0) {
        for (let i = 0; i < teams.length; i++) {
          if (teams[i]._id === curUserData.curTeam) {
            setState(teams[i].team_name);
            localStorage.setItem("teamName", teams[i].team_name);
          }
        }
      }

      let obj = {};
      obj.teamId = curUserData.curTeam;
      obj.userId = myInfo.userId;
      dispatch(getState(obj));
      // dispatch(getMeetings(obj));
      dispatch(getTags({ userId: myInfo.userId }));
      // dispatch(getMember(obj));
      // dispatch(getInfo(obj));
      dispatch(getNote(obj));
      //people focused circle and project members get
      // dispatch(getPFMember(obj));
    }
  }, [curUserData, teams]);

  useEffect(() => {
    if (states && Object.keys(states).length > 0) {
      // console.log(states.Person);
      if (states.Person && states.Person.length > 0) {
        localStorage.setItem("peopleSort", states.Person);
      } else if (!localStorage.getItem("peopleSort")) {
        localStorage.setItem("peopleSort", []);
      }

      // console.log(states, states.Skills);
      if (states.Skills && states.Skills.length > 0) {
        localStorage.setItem("skillSort", states.Skills);
      } else if (!localStorage.getItem("skillSort")) {
        localStorage.setItem("skillSort", []);
      }

      // console.log(states.Roles)
      if (states.Roles && states.Roles.length > 0) {
        localStorage.setItem("roleSort", states.Roles);
      } else if (!localStorage.getItem("roleSort")) {
        localStorage.setItem("roleSort", []);
      }

      // console.log(states.Domains)
      if (states.Domains && states.Domains.length > 0) {
        localStorage.setItem("domainSort", states.Domains);
      } else if (!localStorage.getItem("domainSort")) {
        localStorage.setItem("domainSort", []);
      }

      // console.log(states.Links)
      if (states.Links && states.Links.length > 0) {
        localStorage.setItem("linkSort", states.Links);
      } else if (!localStorage.getItem("linkSort")) {
        localStorage.setItem("linkSort", []);
      }

      // console.log(states.Circles)
      if (states.Circles && states.Circles.length > 0) {
        localStorage.setItem("circleSort", states.Circles);
      } else if (!localStorage.getItem("circleSort")) {
        localStorage.setItem("circleSort", []);
      }

      // console.log(states.Projects)
      if (states.Projects && states.Projects.length > 0) {
        localStorage.setItem("projectSort", states.Projects);
      } else if (!localStorage.getItem("projectSort")) {
        localStorage.setItem("projectSort", []);
      }

      // console.log(states.TeamLists)
      if (states.TeamLists && states.TeamLists.length > 0) {
        localStorage.setItem("items", states.TeamLists);
        setList(states.TeamLists);
      } else if (
        localStorage.getItem("items") === "" ||
        undefined === localStorage.getItem("items")
      ) {
        localStorage.setItem("items", [
          "People",
          "Skills",
          "Roles",
          "Domains",
          "Links",
          "Circles",
          "Projects",
        ]);
      }

      let teamId = localStorage.getItem("teamId");

      //cListGMode state change
      if (states && states.cListGMode && states.cListGMode.length > 0) {
        setNaveSort(states.cListGMode);
      }

      //statesLM state change
      if (
        states &&
        states.cListMode &&
        Object.keys(states.cListMode).length > 0
      ) {
        dispatch({
          type: STATELM_SET_SUCCESS,
          payload: {
            statesLM: states.cListMode,
          },
        });
      }

      //sListMode state change
      if (
        states &&
        states.sListMode &&
        Object.keys(states.sListMode).length > 0
      ) {
        setListHide(states.sListMode);
      }
      // sListMode

      //notes data set

      /* let data = localStorage.getItem("notes");
      if (data) {
        let obj = JSON.parse(data);
        let noteArr = [
          "People",
          "Skills",
          "Roles",
          "Domains",
          "Links",
          "Circles",
          "Projects",
        ];
        setNote(JSON.parse(data));
        let count = 0;
        for (let i = 0; i < noteArr.length; i++) {
          if (obj[noteArr[i]]) {
            count++;
          }
        }
        if (count === noteArr.length) {
          setAllNote(true);
        }
      } */
    }

    dispatch(getTags({ userId: myInfo.userId }));

    if (authenticate) {
      setTimeout(() => {
        setLoadings(true);
        // document.body.style = "";
        // document.body.classList = "";
      }, 1000);
      /* added by Debashis on 15 Aug 2023 */
      // Remove the "out" class from html tag
      var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
      root.setAttribute("class", "");
    }
    // }, [curUserData, teams, states]);
  }, [states]);
  // console.log(teams);
  // let teamData = teams.filter((e) => e.archived !== true);
  // let teamData = [];
  const [teamData, setTeamData] = useState([])

  useEffect(() => {
    if (teams) {
      const filterTeamData = teams.filter((e) => e.archived !== true);
      setTeamData(filterTeamData)
    }
  }, [teams]);

  const setHandel = (e) => {
    // reset focus mode
    removeFocused();
    // Reset circle/ project modes
    setFocusedMode("");

    setState(teamData[e].team_name);
    localStorage.setItem("teamId", teamData[e]._id);
    localStorage.setItem("teamName", teamData[e].team_name);
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      userUpdate({
        adminId: obj.userId,
        url: `${SERVER_URI}/admin/update`,
        teamId: obj.teamId,
        curTeam: obj.teamId,
        logout: false,
      })
    ).then(() => {
      dispatch(getLoginUserData({ userId: myInfo.userId }));
      // dispatch(getState(obj));
      window.location.reload();
    });
  };

  const [isLoggedout, setIsLoggedOut] = useState(false);

  const SignOut = (e) => {
    let urls = `${SERVER_URI}/admin/update`;

    dispatch(
      userUpdate({
        adminId: curUserData._id,
        url: urls,
        teamId: curUserData.curTeam,
        sessionToken: "",
        logout: true,
      })
    ).then(() => {
      localStorage.removeItem("authToken");
      document.cookie = "rewardful.referral=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      // sessionStorage.clear();
      // localStorage.clear();
      clearLocalStorage();
      setIsLoggedOut(true);
      setLoadings(false);
      // localStorage.setItem("bg-img", "images/backgroung_bg1.jpg");
      // document.body.style = "background-image: url(images/backgroung_bg1.jpg);";
      document.body.classList = "no-app-pages body2 menu2";
      window.location.reload();
      // navigate("/login")
    });
    /* .then(() => {
      setLoadings(false);
    }); */
  };

  useEffect(() => {
    if (isLoggedout) {
      setTimeout(() => {
        setLoadings(true);
        dispatch({ type: SIGN_OUT });
        // navigate("/login");
        // window.location.reload()
        window.location.href = CLIENT_URL + "/login";
      }, 1000);
    }
  }, [isLoggedout, setIsLoggedOut]);

  useEffect(() => {
    if (focusMode && focusMode.length > 0) {
      let fListData = [];
      let Flist = {};
      if (
        focusMode === "Circle" &&
        !isModeSwitched &&
        circles &&
        circles.length > 0
      ) {
        for (let i = 0; i < circles.length; i++) {
          fListData.push({ name: circles[i].circleName, id: circles[i]._id });
          Flist[circles[i].circleName] = false;
        }
        if (dCircles && dCircles.length > 0) {
          for (let i = 0; i < dCircles.length; i++) {
            fListData.push({
              name: dCircles[i].circleName,
              id: dCircles[i]._id,
            });
            Flist[dCircles[i].circleName] = false;
          }
        }
      } else if (
        focusMode === "Circle" &&
        isModeSwitched &&
        circles &&
        circles.length > 0
      ) {
        for (let i = 0; i < circles.length; i++) {
          fListData.push({ name: circles[i].circleName, id: circles[i]._id });
          Flist[circles[i].circleName] = false;
        }
        if (dCircles && dCircles.length > 0) {
          for (let i = 0; i < dCircles.length; i++) {
            fListData.push({
              name: dCircles[i].circleName,
              id: dCircles[i]._id,
            });
            Flist[dCircles[i].circleName] = false;
          }
        }
      } else if (
        focusMode === "Project" &&
        !isModeSwitched &&
        projects &&
        projects.length > 0
      ) {
        for (let i = 0; i < projects.length; i++) {
          fListData.push({
            name: projects[i].projectName,
            id: projects[i]._id,
          });
          Flist[projects[i].projectName] = false;
        }
      } else if (
        focusMode === "Project" &&
        isModeSwitched &&
        projects &&
        projects.length > 0
      ) {
        for (let i = 0; i < projects.length; i++) {
          fListData.push({
            name: projects[i].projectName,
            id: projects[i]._id,
          });
          Flist[projects[i].projectName] = false;
        }
      }
      if (fListData.length > 0) {
        // console.log(fListData);
        const resTrictedArray = ["Project", "Circle"];
        if (!resTrictedArray.includes(focusMode)) {
          setFocusList(fListData);
        }
      }
      if (Flist) {
        setFList(Flist);
      }
    }
  }, [focusMode, isModeSwitched]);

  const fListHideOnchange = (e) => {
    setFList({
      ...fList,
      [e.target.name]: e.target.checked,
    });
  };

  const AllSref = useRef(null);
  useEffect(() => {
    if (allSearch.length > 0) {
      AllSref.current.focus();
    }
  }, [allSearch]);

  function FMPeopleAS(data) {
    setIsModeSwitched(!isModeSwitched);
  }

  const isInfos = (datas) => {
    /* if (datas && datas.length > 0 && info && info.length > 0) {
      for (let i = 0; i < info.length; i++) {
        if (info[i].infoCard === datas) {
          setInfoData(info[i]);
          openInfo();
          break;
        }
      }
    } */
    let kblink = "";
    switch (datas) {
      case "People":
        kblink = "https://teamdecoder.tawk.help/article/people-tool";
        break;

      case "Skills":
        kblink = "https://teamdecoder.tawk.help/article/skills-tool";
        break;

      case "Roles":
        kblink = "https://teamdecoder.tawk.help/article/roles";
        break;

      case "Domains":
        kblink = "https://teamdecoder.tawk.help/article/domains";
        break;

      case "Links":
        kblink = "https://teamdecoder.tawk.help/article/links";
        break;

      case "Circles":
        kblink = "https://teamdecoder.tawk.help/article/circles-projects";
        break;

      case "Projects":
        kblink = "https://teamdecoder.tawk.help/article/circles-projects";
        break;
    }

    // window.location.href = kblink;
    window.open(kblink, "_blank");
  };

  var xlsData = [];

  if (focused && focused.card.length === 0) {
    // console.log(peoples,skills,roles,domains,links,circles,projects);
    if (
      peoples.length > 0 ||
      skills.length > 0 ||
      roles.length > 0 ||
      domains.length > 0 ||
      links.length > 0 ||
      circles.length > 0 ||
      projects.length > 0
    ) {
      // console.log(peoples,peoples.length)
      let arr = [
        peoples.length,
        skills.length,
        roles.length,
        domains.length,
        links.length,
        circles.length,
        projects.length,
      ];
      const max = arr.reduce((a, b) => {
        return Math.max(a, b);
      });
      for (let i = 0; i < max; i++) {
        let obj = {
          People:
            peoples[i] && peoples[i]._id
              ? `${peoples[i].fname} ${peoples[i].lname}`
              : "",
          Skill: skills[i] && skills[i]._id ? `${skills[i].skillName}` : "",
          Role: roles[i] && roles[i]._id ? `${roles[i].roleName}` : "",
          Domain:
            domains[i] && domains[i]._id ? `${domains[i].domainName}` : "",
          Link: links[i] && links[i]._id ? `${links[i].linkName}` : "",
          Circle:
            circles[i] && circles[i]._id ? `${circles[i].circleName}` : "",
          Project:
            projects[i] && projects[i]._id ? `${projects[i].projectName}` : "",
        };
        xlsData.push(obj);
      }
    }
  }

  // JPg
  const handleDownloadImage = () => {
    // console.log({ purposeTreeMode });
    let allCardList = document.querySelectorAll(".card-list");
    let maxHeight = 0;
    if (allCardList) {
      for (let index = 0; index < allCardList.length; index++) {
        // console.log({a:maxHeight , b: allCardList[index].scrollHeight})
        if (maxHeight < allCardList[index].scrollHeight) {
          maxHeight = allCardList[index].scrollHeight + 300;
        }
      }
    }

    /* If maxHeight cannot be determined due to other special modes */
    if (maxHeight === 0) {
      maxHeight = document.querySelector("#capture .app-board .board-body").scrollHeight;
    }

    let sW = document.querySelector(".board-body").scrollWidth;
    // document.getElementById("capture").style.width = sW + "px";
    document.getElementById("capture").setAttribute("class", "html2-canvas");
    setIsActive(true);
    setTimeout(function () {
      const style = document.createElement("style");
      style.textContent =
        ".html2canvas-container { width: " +
        sW +
        "px !important; height: " +
        (maxHeight + 200) +
        "px !important; }";
      document.head.appendChild(style);

      // $('#'+mode+' #notmatched .card.notmatched').attr('data-html2canvas-ignore',true);

      // Get a reference to the element with the class name "#notmatched .card"
      const elements = document.querySelectorAll(
        "#notmatched .card.notmatched"
      );
      // Loop through each matched element and set the attribute
      if (elements) {
        elements.forEach((element) => {
          element.setAttribute("data-html2canvas-ignore", true);
        });
      }

      html2canvas(document.querySelector("#capture")).then((canvas) => {
        var a = document.createElement("a");
        a.href = canvas
          .toDataURL("image/jpeg")
          .replace("image/jpeg", "image/octet-stream");
        a.download = "download.jpg";
        a.click();
        // setIsActive(false);
        document.getElementById("capture").setAttribute("class", "");

        elements.forEach((element) => {
          element.removeAttribute("data-html2canvas-ignore");
        });
      });
    }, 100);
  };

  const removeExt = () => {
    setIsEXT(false);
    setExtNoFilteredObj({});
  };

  const removeNo = () => {
    setIsNO(false);
    setExtNoFilteredObj({});
    setIsNO(false);
    setExtNoFilteredObj({});
  };

  const removeTags = () => {
    setIsTag("");
    setIsTagData("");
    setTagFilteredObj({});
  };

  const removeFocused = () => {
    setFocused({ card: "", index: null, id: null });
    localStorage.removeItem("fCard");
    localStorage.removeItem("fIndex");
    localStorage.removeItem("FIndex");
    localStorage.removeItem("popoverId");
    localStorage.removeItem("popoverIds");
    localStorage.removeItem("pfmemberId");
    localStorage.removeItem("pfmemberIds");
    localStorage.removeItem("fcData");
    localStorage.removeItem("fcDatas");
    localStorage.removeItem("dragPepleCard");
  };

  const listHideOnchange = (e) => {
    setListHide({
      ...listHide,
      [e.target.name]: e.target.checked,
    });
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: localStorage.getItem("teamId"),
        url: `${SERVER_URI}/user/states/update`,
        sListMode: {
          ...listHide,
          [e.target.name]: e.target.checked,
        },
      })
    ).then(() => {
      // dispatch(getState({ userId: myInfo.userId, teamId: curUserData.curTeam }));
    });
  };

  // /user/states/get
  useEffect(() => {
    // console.log(note);
    // localStorage.setItem("notes", JSON.stringify(note));
    if (note && Object.keys(note).length > 0) {
      const allShowNotesTrue = Object.values(note).every((sn) => {
        return sn === true;
      });
      // console.log(allShowNotesTrue)

      if (allShowNotesTrue) {
        setAllNote(true);
      } else {
        setAllNote(false);
      }
    }
  }, [note, setNote]);

  const noteOnchange = (e) => {
    if (e.target.name === "show_all_Notes") {
      setNote({
        People: e.target.checked,
        Skills: e.target.checked,
        Roles: e.target.checked,
        Domains: e.target.checked,
        Links: e.target.checked,
        Circles: e.target.checked,
        Projects: e.target.checked,
      });
      setAllNote(e.target.checked);
      dispatch(
        updateNote({
          teamId: localStorage.getItem("teamId"),
          noteCard: "all",
          showNotes: e.target.checked,
        })
      );
    } else {
      setNote({
        ...note,
        [e.target.name]: e.target.checked,
      });

      dispatch(
        updateNote({
          teamId: localStorage.getItem("teamId"),
          noteCard: e.target.name,
          showNotes: e.target.checked,
        })
      );
    }
  };

  const genericRolechange = (e) => {
    let val = !showGenericRole;
    setShowGenericRole(val);

    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        genericRole: val
      })
    );
  };

  const workloadToggleController = (e) => {
    /* make change in usestate */
    setWorkLoadToggle(e.target.checked);
    /* update in state */
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    dispatch(
      updateState({
        adminId: myInfo.userId,
        teamId: obj.teamId,
        workLoad: e.target.checked,
      })
    );
  };

  useEffect(() => {
    if (states.workLoad) {
      setWorkLoadToggle(states.workLoad);
    } else {
      setWorkLoadToggle(false);
    }
    if (states.genericRole) {
      setShowGenericRole(states.genericRole);
    } else {
      setShowGenericRole(false);
    }
  }, [states]);

  useEffect(() => {
    if (noteData && noteData.length > 0) {
      // console.log(noteData)
      // Initial state
      const initialState = {
        People: true,
        Skills: true,
        Roles: true,
        Domains: true,
        Links: true,
        Circles: true,
        Projects: true,
      };

      // Update state based on noteData
      const updatedState = noteData.reduce(
        (acc, note) => {
          const { noteCard, showNotes } = note;
          if (acc.hasOwnProperty(noteCard)) {
            acc[noteCard] = showNotes;
          }
          return acc;
        },
        { ...initialState }
      );

      // Now updatedState will contain the desired state
      // console.log(updatedState);
      // Set the state using the updatedState
      setNote(updatedState);

      const allShowNotesTrue = Object.values(noteData).every((note) => {
        return note.showNotes === true;
      });

      if (allShowNotesTrue) {
        setAllNote(true);
      } else {
        setAllNote(false);
      }
    }
  }, [noteData]);

  //.......................................................All Curd State.......................................................//

  const [CloseState, stateCloseState] = useState("");

  const CloseStateHandel = (type) => {
    freezeWindow();
    if (type === "People") {
      closePeople();
      stateCloseState("People");
    } else if (type === "Skills") {
      closeSkill();
      stateCloseState("Skills");
    } else if (type === "Roles") {
      closeRole();
      stateCloseState("Roles");
    } else if (type === "Domains") {
      closeDomain();
      stateCloseState("Domains");
    } else if (type === "Links") {
      closeLink();
      stateCloseState("Links");
    } else if (type === "Circles") {
      closeCircle();
      stateCloseState("Circles");
    } else if (type === "Projects") {
      closeProject();
      stateCloseState("Projects");
    }
  };

  //pepole
  const [pShowState, setPShowState] = useState("");

  const setPepole = (data, datas, index, type) => {
    // console.log("---during open---", { data, datas, index, type });
    if (type && type === "People") {
      setPShowState("People");
      indexSet(data);
      setIsNToP(datas);
      setTimeout(() => {
        setPShowState("");
      }, 1);
    } else {
      localStorage.setItem("FIndex", index);
      indexSet(data);
      setIsNToP(datas);
      openPeople();
    }
  };

  const setPepoles = (data) => {
    localStorage.removeItem("FIndex");
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.fname &&
      data.fname.length > 0
    ) {
      indexSet(data);
    } else {
      indexSet({});
    }
    setIsNToP([]);
    openPeople();
  };

  //skills
  const setSkill = (data, datas, index, type) => {
    if (type && type === "Skill") {
      setPShowState("Skill");
      indexSet(data);
      setIsNToP(datas);
      setTimeout(() => {
        setPShowState("");
      }, 1);
    } else {
      localStorage.setItem("FIndex", index);
      indexSet(data);
      setIsNToP(datas);
      openSkill();
    }
  };

  const setSkills = (data) => {
    localStorage.removeItem("FIndex");
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.skillName &&
      data.skillName.length > 0
    ) {
      indexSet(data);
    } else {
      indexSet({});
    }
    setIsNToP([]);
    openSkill();
  };

  //roles
  const setRole = (data, datas, index, type) => {
    if (type && type === "Role") {
      setPShowState("Role");
      indexSet(data);
      setIsNToP(datas);
      setTimeout(() => {
        setPShowState("");
      }, 1);
    } else {
      localStorage.setItem("FIndex", index);
      indexSet(data);
      setIsNToP(datas);
      openRole();
    }
  };

  const setRoles = (data) => {
    localStorage.removeItem("FIndex");
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.roleName &&
      data.roleName.length > 0
    ) {
      indexSet(data);
    } else {
      indexSet({});
    }
    setIsNToP([]);
    openRole();
  };

  //Domains
  const setDomain = (data, datas, index, type) => {
    if (type && type === "Domain") {
      setPShowState("Domain");
      indexSet(data);
      setIsNToP(datas);
      setTimeout(() => {
        setPShowState("");
      }, 1);
    } else {
      localStorage.setItem("FIndex", index);
      indexSet(data);
      setIsNToP(datas);
      openDomain();
    }
  };

  const setDomains = (data) => {
    localStorage.removeItem("FIndex");
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.domainName &&
      data.domainName.length > 0
    ) {
      indexSet(data);
    } else {
      indexSet({});
    }
    setIsNToP([]);
    openDomain();
  };

  //Links
  const setLink = (data, datas, index, type) => {
    if (type && type === "Link") {
      setPShowState("Link");
      indexSet(data);
      setIsNToP(datas);
      setTimeout(() => {
        setPShowState("");
      }, 1);
    } else {
      localStorage.setItem("FIndex", index);
      indexSet(data);
      setIsNToP(datas);
      openLink();
    }
  };

  const setLinks = (data) => {
    localStorage.removeItem("FIndex");
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.linkName &&
      data.linkName.length > 0
    ) {
      indexSet(data);
    } else {
      indexSet({});
    }
    setIsNToP([]);
    openLink();
  };

  //Circle
  const setCircle = (data, datas, index, type) => {
    if (type && type === "Circle") {
      setPShowState("Circle");
      indexSet(data);
      setIsNToP(datas);
      setTimeout(() => {
        setPShowState("");
      }, 1);
    } else {
      localStorage.setItem("FIndex", index);
      indexSet(data);
      setIsNToP(datas);
      openCircle();
    }
  };

  const setCircles = (data) => {
    localStorage.removeItem("FIndex");
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.circleName &&
      data.circleName.length > 0
    ) {
      indexSet(data);
    } else {
      indexSet({});
    }
    setIsNToP([]);
    openCircle();
  };

  //Projects
  const setProject = (data, datas, index, type) => {
    if (type && type === "Project") {
      setPShowState("Project");
      indexSet(data);
      setIsNToP(datas);
      setTimeout(() => {
        setPShowState("");
      }, 1);
    } else {
      localStorage.setItem("FIndex", index);
      indexSet(data);
      setIsNToP(datas);
      openProject();
    }
  };

  const setProjects = (data) => {
    localStorage.removeItem("FIndex");
    if (
      data &&
      Object.keys(data).length > 0 &&
      data.projectName &&
      data.projectName.length > 0
    ) {
      indexSet(data);
    } else {
      indexSet({});
    }
    setIsNToP([]);
    openProject();
  };

  const [popup, setPopup] = useState(false);

  // setTimeout(() => {
  //   let date = Math.floor(myInfo.exp - Date.now() / 1000);
  //   if (date < 11) {
  //     setPopup(true);
  //   }
  // }, 1000);

  // useEffect(() => {
  //   let date = Math.floor(myInfo.exp - Date.now() / 1000);
  //   if (popup) {
  //     if (date < 1) {
  //       Swal.fire({
  //         title: "Note",
  //         title: "You have been logged out due to session inactivity!",
  //       }).then((ress) => {
  //         // console.log(ress);
  //         if (ress.isConfirmed) {
  //           SignOut();
  //         }
  //       });
  //     } else {
  //       let timerInterval;
  //       Swal.fire({
  //         title: "Are you continuing with current browser?",
  //         html: "I will close in <strong></strong> seconds.<br/><br/>",
  //         timer: 10000,
  //         didOpen: () => {
  //           timerInterval = setInterval(() => {
  //             Swal.getHtmlContainer().querySelector("strong").textContent = (
  //               Swal.getTimerLeft() / 1000
  //             ).toFixed(0);
  //           }, 100);
  //         },
  //         willClose: () => {
  //           clearInterval(timerInterval);
  //         },
  //       }).then((res) => {
  //         if (res.isConfirmed) {
  //           // console.log(res);
  //           dispatch(
  //             userReLogin({
  //               fname: myInfo.fname,
  //               lname: myInfo.lname,
  //               companyName: myInfo.companyName,
  //               email: myInfo.email,
  //               role: myInfo.role,
  //               userId: myInfo.userId,
  //             })
  //           );
  //         } else {
  //           Swal.fire({
  //             title: "Note",
  //             text: "You have been logged out due to session inactivity!",
  //           }).then((ress) => {
  //             if (ress.isConfirmed) {
  //               SignOut();
  //             }
  //           });
  //         }
  //       });
  //     }
  //   }
  // }, [popup]);

  useEffect(() => {
    // console.log("running");
    const interval = setInterval(() => {
      const currentTime = Date.now() / 1000; // Convert to seconds
      const expirationTime = myInfo.exp; //exp time in seconds

      const timeLeft = Math.floor(expirationTime - currentTime); // Remaining time in seconds
      // console.log("Time left:", timeLeft);

      if (timeLeft < 11 && timeLeft > 0) {
        setPopup(true); // popup state change if less than 11 seconds are remaining
      }

      if (timeLeft <= 0) {
        clearInterval(interval); // Stop checking once the session expires
      }
    }, 1000); // Check every 1 second

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, []);

  useEffect(() => {
    // console.log("Popup state:", popup);

    const currentTime = Date.now() / 1000;
    const timeLeft = Math.floor(myInfo.exp - currentTime);

    if (popup) {
      if (timeLeft < 1) {
        Swal.fire({
          title: "Note",
          text: "You have been logged out due to session inactivity!",
        }).then((result) => {
          if (result.isConfirmed) {
            SignOut(); // Log out the user
          }
        });
      } else {
        let timerInterval;
        Swal.fire({
          title: "Are you continuing with the current browser?",
          html: "I will close in <strong></strong> seconds.<br/><br/>",
          timer: 10000, // 10 seconds timer
          didOpen: () => {
            timerInterval = setInterval(() => {
              Swal.getHtmlContainer().querySelector("strong").textContent = (
                Swal.getTimerLeft() / 1000
              ).toFixed(0); // Update the timer display
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval); // Clear the interval when the modal closes
          },
        }).then((res) => {
          if (res.isConfirmed) {
            /* another login for new 8 hr session */
            dispatch(
              userReLogin({
                fname: myInfo.fname,
                lname: myInfo.lname,
                companyName: myInfo.companyName,
                email: myInfo.email,
                role: myInfo.role,
                userId: myInfo.userId,
              })
            ).then(() => {
              /* refresh to start timer again */
              window.location.reload();
            });
          } else {
            Swal.fire({
              title: "Note",
              text: "You have been logged out due to session inactivity!",
            }).then((result) => {
              if (result.isConfirmed) {
                SignOut(); // Log out if the user didn't continue
              }
            });
          }
        });
      }
    }
  }, [popup, myInfo.exp]);


  /* added by Debashis on 15 Aug 2023 */
  const setSMode = (modes, e) => {
    // console.log(modes)
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;
    if (modes === "NewToOld") {
      let allListMode = {
        People: "NewToOld",
        Skills: "NewToOld",
        Roles: "NewToOld",
        Domains: "NewToOld",
        Links: "NewToOld",
        Circles: "NewToOld",
        Projects: "NewToOld",
      };
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
          cListGMode: "NewToOld",
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        dispatch(getState(obj));
        setNaveSort("NewToOld");
        localStorage.setItem("sortType", "ACSORT");
      });
    } else if (modes === "OldToNew") {
      let allListMode = {
        People: "OldToNew",
        Skills: "OldToNew",
        Roles: "OldToNew",
        Domains: "OldToNew",
        Links: "OldToNew",
        Circles: "OldToNew",
        Projects: "OldToNew",
      };
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
          cListGMode: "OldToNew",
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        dispatch(getState(obj));
        setNaveSort("OldToNew");
        localStorage.setItem("sortType", "ACSORT");
      });
    } else if (modes === "AtoZ") {
      let allListMode = {
        People: "AtoZ",
        Skills: "AtoZ",
        Roles: "AtoZ",
        Domains: "AtoZ",
        Links: "AtoZ",
        Circles: "AtoZ",
        Projects: "AtoZ",
      };
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
          cListGMode: "AtoZ",
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        dispatch(getState(obj));
        setNaveSort("AtoZ");
        localStorage.setItem("sortType", "ACSORT");
      });
    } else if (modes === "ZtoA") {
      let allListMode = {
        People: "ZtoA",
        Skills: "ZtoA",
        Roles: "ZtoA",
        Domains: "ZtoA",
        Links: "ZtoA",
        Circles: "ZtoA",
        Projects: "ZtoA",
      };
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
          cListGMode: "ZtoA",
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        dispatch(getState(obj));
        setNaveSort("ZtoA");
        localStorage.setItem("sortType", "ACSORT");
      });
    } else if (modes === "Parsonal") {
      let allListMode = {
        People: "Parsonal",
        Skills: "Parsonal",
        Roles: "Parsonal",
        Domains: "Parsonal",
        Links: "Parsonal",
        Circles: "Parsonal",
        Projects: "Parsonal",
      };
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: obj.teamId,
          url: `${SERVER_URI}/user/states/create`,
          cListMode: allListMode,
          cListGMode: "Parsonal",
        })
      ).then(() => {
        dispatch({ type: STATE_SUCCESS_MESSAGE_CLEAR });
        dispatch(getState(obj));
        setNaveSort("Parsonal");
        localStorage.setItem("sortType", "ACSORT");
      });
    }
  };

  const [menuToggol, setMenuToggol] = useState(false);

  const onDragEndSP = (result, provided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const copyListItems = reorder(
      list,
      result.source.index,
      result.destination.index
    );
    setList(copyListItems);
    setMenuToggol(false);
    if (copyListItems && copyListItems.length > 0) {
      localStorage.setItem("items", copyListItems);
      dispatch(
        updateState({
          adminId: myInfo.userId,
          teamId: localStorage.getItem("teamId"),
          url: `${SERVER_URI}/user/states/update`,
          TeamLists: copyListItems,
        })
      );
    }
  };

  const onDragEndSPCM = (result, provided) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const copyListItems = reorder(
      focusList,
      result.source.index,
      result.destination.index
    );
    const allSortedOrderId = copyListItems.map((scl) => scl.id);
    /* now save the sorted order of circlemode Ids to backed as CircleModelId according to focusMode type i.e. circle or project */
    if (focusMode === "Project") {
      dispatch(
        updateState({
          ...states,
          ProjectModeOrder: allSortedOrderId,
          adminId: myInfo.userId,
        })
      );
    } else if (focusMode === "Circle") {
      dispatch(
        updateState({
          ...states,
          CircleModeOrder: allSortedOrderId,
          adminId: myInfo.userId,
        })
      );
    }
    setFocusList(copyListItems);
    setMenuToggol(false);
  };

  function showDropdown() {
    if (menuToggol) {
      setMenuToggol(!menuToggol);
      document.getElementById("dropdown-menu").style = "display: none";
    } else if (!menuToggol) {
      setMenuToggol(!menuToggol);
      document.getElementById("dropdown-menu").style = "display: block";
    }
  }

  const componentRef = useRef(null);
  const dropdownMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      componentRef.current &&
      componentRef.current.contains(event.target) &&
      dropdownMenuRef.current &&
      !dropdownMenuRef.current.contains(event.target) &&
      menuToggol
    ) {
      showDropdown(false);
    }
  };

  const divclck = () => {
    if (menuToggol) {
      // Attach the event listener when the component mounts
      document.addEventListener("mousedown", handleClickOutside);

      // Detach the event listener when the component unmounts
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  };
  document.addEventListener("mousedown", handleClickOutside);

  const handleUpgradeAccount = () => {
    dispatch(
      generateStripeUpgradeUrl({
        userId: curUserData._id,
        plan: "GO",
        interval: "month",
        email: curUserData.email,
      })
    );
  };

  useEffect(() => {
    if (stripeUrl && Object.keys(stripeUrl).length > 0 && stripeUrl.url) {
      window.location.href = `${stripeUrl.url}`;
    }
  }, [stripeUrl]);

  const showComaprePopup = () => {
    if ((curUserData.plan === "FREE" && myInfo.role === "User") && !ALLOW_COMPARE_MODE) {
      showUpgradeNotification(dispatch, Swal, myInfo, curUserData, freezeWindow, generateStripeUpgradeUrl);
    } else {
      if (!localStorage.getItem("ShowComapareMsg")) {
        Swal.fire({
          title: "Compare Mode",
          text: "Please pick either people or skills/roles you want to compare. Then click START!",
          // showConfirmButton: false,
          // showCancelButton: true,
          cancelButtonColor: "#E97162",
          cancelButtonText: "Close",
          input: "checkbox",
          inputPlaceholder: `Don’t show message again`,
          didOpen: () => {
            const placeholder = document.querySelector(".swal2-label");
            if (placeholder) {
              placeholder.style.fontSize = "15px";
              placeholder.style.fontWeight = "normal";
              placeholder.style.fontFamiliy = "inherit";
            }
          },
        }).then((result) => {
          if (result.value) {
            localStorage.setItem("ShowComapareMsg", "off");
          }
          removeFocused();
          removeTags(); // Remove tags
          setFocusedMode("");
          setPurposeTreeMode(false);
          setAnalyticsComponent(false); // Exit Analytics mode 
          setCompareMode({
            active: true,
            compareTypes: [],
            compareBetween: [],
          });
          setShowHomePage(false);
        });
      } else {
        removeFocused();
        removeTags(); // Remove tags
        setFocusedMode("");
        setPurposeTreeMode(false);
        setAnalyticsComponent(false); // Exit Analytics mode 
        setCompareMode({
          active: true,
          compareTypes: [],
          compareBetween: [],
        });
        setShowHomePage(false);
      }
    }
  };

  const showPurposeTree = () => {
    if ((curUserData.plan === "FREE" && myInfo.role === "User") && !ALLOW_PURPOSE_TREE) {
      showUpgradeNotification(dispatch, Swal, myInfo, curUserData, freezeWindow, generateStripeUpgradeUrl);
    } else {
      removeFocused();
      removeTags(); // Remove tags
      setCompareMode({
        active: false,
        compareTypes: [],
        compareBetween: [],
      });
      setFocusedMode("");
      setShowHomePage(false);
      setAnalyticsComponent(false); // Exit Analytics mode 
      setPurposeTreeMode(true);
    }
  }

  const showAnalytics = () => {
    if ((curUserData.plan === "FREE" && myInfo.role === "User") && !ALLOW_SURVEY) {
      showUpgradeNotification(dispatch, Swal, myInfo, curUserData, freezeWindow, generateStripeUpgradeUrl);
    } else {
      removeFocused(); // Exit focus mode
      removeTags(); // Remove tags
      setCompareMode({
        active: false,
        startComparing: false,
        compareTypes: [],
        compareBetween: [],
      });
      setPurposeTreeMode(false);
      setShowHomePage(false);
      setFocusedMode("");
      setAnalyticsComponent(true);
    }
  }

  const clearModes = () => {
    removeFocused(); // Exit focus mode
    setFocusedMode(""); // Exit Circle/ Project mode
    setCompareMode({ // Exit Compare mode
      active: false,
      compareTypes: [],
      compareBetween: [],
    });
    setAnalyticsComponent(false); // Exit Analytics mode 
    removeTags(); // Remove tags      
  }

  /* Skill to Role */
  const SConToRole = (data) => {
    let adminId = myInfo.userId;
    if (data._id && data._id.length > 0) {
      let objData = {
        teamId: data.teamId,
        roleName: data.skillName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerRole: data.ownerType,
        owners: data.owners,
        skillId: data._id,
        domains: data.domains,
        tags: data.tags,
        url: `${SERVER_URI}/user/role/create`,
        adminId: adminId,
        transferIndex: data.transferIndex
      };

      let objsData = {};
      objsData.teamId = localStorage.getItem("teamId");
      objsData.userId = myInfo.userId;

      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.adminId = adminId;
      obj.url = `${SERVER_URI}/user/skill/delete`;
      obj.skillId = data._id;

      let objs = {};
      objs.teamId = localStorage.getItem("teamId");
      objs.userId = myInfo.userId;
      let cuSkills = states.Skills.filter((e) => {
        return e !== obj.skillId;
      });

      freezeWindow(false);
      dispatch(createRole({ ...objData, transfer: true })).then(() => {
        dispatch(deleteSkills(obj)).then(() => {
          dispatch({ type: ROLE_SUCCESS_MESSAGE_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          unfreezeWindow();
        });
      });
    }
  };
  /* Role to Skill */
  const RConToSkill = (data) => {
    let adminId = myInfo.userId;
    if (data._id && data._id.length > 0) {
      let objData = {
        teamId: data.teamId,
        skillName: data.roleName,
        purpose: data.purpose,
        tasks: data.tasks,
        ownerType: data.ownerRole,
        owners: data.owners,
        roleId: data._id,
        domains: data.domains,
        tags: data.tags,
        url: `${SERVER_URI}/user/skill/create`,
        adminId: adminId,
        transferIndex: data.transferIndex
      };

      let objsData = {};
      objsData.teamId = localStorage.getItem("teamId");
      objsData.userId = myInfo.userId;

      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.adminId = adminId;
      obj.url = `${SERVER_URI}/user/role/delete`;
      obj.roleId = data._id;
      let objs = {};
      objs.teamId = localStorage.getItem("teamId");
      objs.userId = myInfo.userId;
      /* let cuRoles = states.Roles.filter((e) => {
        return e !== obj.roleId;
      }); */

      freezeWindow(false);
      dispatch(createSkills({ ...objData, transfer: true })).then(() => {
        // dispatch(getSkills(objsData));
        let roleSort = localStorage.getItem("roleSort");
        dispatch(deleteRole(objData)).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: objData.teamId }));
          dispatch({ type: SKILL_SUCCESS_MESSAGE_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          unfreezeWindow();
        });
      });
    }
  };
  /* Circle to Project */
  const circleToProject = (data) => {
    let adminId = myInfo.userId;
    if (data._id && data._id.length > 0) {
      let objData = {
        teamId: data.teamId,
        projectName: data.circleName,
        tasks: data.tasks,
        lead: data.lead,
        standIn: data.standIn,
        leadToDos: data.leadToDos ? data.leadToDos : [],
        meetings: data.meetings ? data.meetings : [],
        administration: data.administration ? data.administration : [],
        circleId: data._id,
        purpose: data.purpose,
        tags: data.tags,
        adminId: adminId,
        url: `${SERVER_URI}/user/project/create`,
        transferIndex: data.transferIndex
      };
      let objsData = {};
      objsData.teamId = localStorage.getItem("teamId");
      objsData.userId = myInfo.userId;

      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.adminId = adminId;
      obj.url = `${SERVER_URI}/user/circle/delete`;
      obj.circleId = data._id;
      let objs = {};
      objs.teamId = localStorage.getItem("teamId");
      objs.userId = myInfo.userId;
      /* let cuCircles = states.Circles.filter((e) => {
        return e !== obj.circleId;
      }); */

      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");

      freezeWindow(false)
      dispatch(createProject({ ...objData, projectSort, transfer: true })).then(() => {
        // dispatch(getProject(objsData));
        dispatch(deleteCircle({ ...obj, circleSort })).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: PROJECT_ID_CLEAR });
          dispatch({ type: PROJECT_SUCCESS_MESSAGE_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          unfreezeWindow()
        }).then(() => {
          clearModes();
        });
      });
      // console.log(obj)
    }
  };
  /* Project to Circle */
  const projectToCircle = (data) => {
    let adminId = myInfo.userId;
    if (data._id && data._id.length > 0) {
      let objData = {
        teamId: data.teamId,
        circleName: data.projectName,
        tasks: data.tasks,
        lead: data.lead,
        standIn: data.standIn,
        leadToDos: data.leadToDos ? data.leadToDos : [],
        meetings: data.meetings ? data.meetings : [],
        administration: data.administration ? data.administration : [],
        projectId: data._id,
        purpose: data.purpose,
        tags: data.tags,
        adminId: adminId,
        url: `${SERVER_URI}/user/circle/create`,
        transferIndex: data.transferIndex
      };
      let objsData = {};
      objsData.teamId = localStorage.getItem("teamId");
      objsData.userId = myInfo.userId;

      let obj = {};
      obj.teamId = localStorage.getItem("teamId");
      obj.adminId = adminId;
      obj.url = `${SERVER_URI}/user/project/delete`;
      obj.projectId = data._id;
      let objs = {};
      objs.teamId = localStorage.getItem("teamId");
      objs.userId = myInfo.userId;
      /* let cuProjects = states.Projects.filter((e) => {
        return e !== obj.projectId;
      }); */

      let circleSort = localStorage.getItem("circleSort");
      let projectSort = localStorage.getItem("projectSort");
      freezeWindow(false)
      dispatch(createCircle({ ...objData, circleSort, transfer: true })).then(() => {
        // dispatch(getCircle(objsData));
        dispatch(deleteProject({ ...obj, projectSort })).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: CIRCLE_ID_CLEAR });
          dispatch({ type: CIRCLE_SUCCESS_MESSAGE_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
          unfreezeWindow()
        }).then(() => {
          clearModes();
        });
      });
    }
  };

  return (
    <>
      <ActivityTracker />

      <Permission_ModePopUp
        teamData={teamData}
        setHandel={setHandel}
        openTeam={openTeam}
        openEditTemplate={openEditTemplate}
      />
      {loadings ? (
        <div ref={componentRef} onClick={() => divclck()} id="capture">
          <div className="app">
            {/* Main Header */}
            <header className="app-header" data-html2canvas-ignore="true">
              <div className="left">
                <div className="logo">
                  <a href="#"
                    onClick={() => {
                      removeFocused(); // Exit focus mode
                      setFocusedMode(""); // Exit Circle/ Project mode
                      setCompareMode({ // Exit Compare mode
                        active: false,
                        compareTypes: [],
                        compareBetween: [],
                      });
                      setAnalyticsComponent(false); // Exit Analytics mode 
                      removeTags(); // Remove tags
                      setPurposeTreeMode(false);
                      setShowHomePage(true); // Show Home page
                    }}
                  >
                    <Tippy content={<span>Home Page</span>}>
                      <img
                        // src="images/new-logo-nobg-sm.png"
                        src={`./images/new-logo-sm-td.png`}
                        className="teamdecoder"
                      />
                    </Tippy>
                  </a>
                </div>

                <div className="dropdown company-name">
                  <div
                    className="btn btn-mytpt"
                    style={{ minWidth: "60px", minHeight: "32px", textAlign: "left" }}
                  >
                    {curUserData.companyName}
                  </div>
                </div>

                <div className="dropdown team-list">
                  <div
                    className="btn btn-mytpt dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {state}
                    <i
                      style={{ marginLeft: "10px" }}
                      className="fa fa-caret-down"
                    ></i>
                  </div>

                  <ul className="dropdown-menu" role="menu">
                    <li className="dropdown-header">Switch Team </li>

                    {teamData.map((e, index) => {
                      return (
                        <li
                          key={index}
                          role="presentation"
                          onClick={() => {
                            setHandel(index);
                          }}
                        >
                          <a
                            role="menuitem"
                            href="#"
                            className="switch-team"
                            data-name="INTOLAP"
                            id="62"
                          >
                            {e.team_name}
                          </a>
                        </li>
                      );
                    })}

                    {myInfo.role !== "Co-Admin" && (
                      <>
                        <li role="presentation" className="divider"></li>
                        <li
                          role="presentation"
                          type="button"
                          onClick={() => openTeam()}
                        >
                          <a href="#" data-toggle="modal" data-target="#teamModal">
                            <img
                              src="./images/edit.svg"
                              alt="download"
                              width={"15px"}
                              style={{ marginRight: "5px" }}
                            />
                            <span> Create/ Edit Teams </span>
                          </a>
                        </li>
                        <li
                          role="presentation"
                          type="button"
                          onClick={() => openEditTemplate()}
                        >
                          <a href="#" data-toggle="modal" data-target="#teamModal">
                            {/* <i className="fas fa-download"></i> */}
                            <img
                              src="./images/collage-icon.svg"
                              alt="download"
                              width={"15px"}
                              style={{ marginRight: "5px" }}
                            />
                            <span>Download Templates</span>
                          </a>
                        </li>
                      </>
                    )}
                  </ul>
                </div>

                <div className="dropdown dashboard hide">
                  {/* <Tippy content={<span>View Dashboard</span>}> */}
                  <div
                    className="btn btn-mytpt"
                    onClick={() => {
                      removeFocused(); // Exit focus mode
                      setFocusedMode(""); // Exit Circle/ Project mode
                      setCompareMode({ // Exit Compare mode
                        active: false,
                        compareTypes: [],
                        compareBetween: [],
                      });
                      setAnalyticsComponent(false); // Exit Analytics mode 
                      removeTags(); // Remove tags
                      setPurposeTreeMode(false);
                      setShowHomePage(false); // Show Home page
                    }}
                    style={{ borderBottom: (focusMode == "" && /* focused.index==null && */ !compareMode.active && !analyticsComponent && !purposeTreeMode && !showHomePage) ? '2px solid #000000' : 'none', borderRadius: 'unset' }}
                  >
                    Dashboard
                  </div>
                  {/* </Tippy> */}
                </div>

                {/* {!analyticsComponent && */}
                <div className="dropdown lists" id="lists" ref={dropdownMenuRef}>
                  <Tippy content={<span>Manage Lists</span>}>
                    <div
                      className={
                        !menuToggol
                          ? "btn btn-mytpt list-btn dropdown-toggle"
                          : "btn btn-mytpt list-btn"
                      }
                      data-toggle={!menuToggol ? "dropdown" : ""}
                      onClick={() => showDropdown()}
                    >
                      Lists{" "}
                      <i
                        style={{ marginLeft: "10px" }}
                        className="fa fa-caret-down"
                      ></i>
                    </div>
                  </Tippy>

                  {!focusMode && focusMode.length === 0 ? (
                    <div className="dropdown-menu" id="dropdown-menu">
                      <div className="col-xs-6">
                        <ul
                          className="checkbox-menu allow-focus sortable-list-menu"
                          role="menu"
                        >
                          <li className="dropdown-header">
                            Select to hide list(s)
                          </li>
                          <li className="dropdown-header">
                            Drag to order list(s)
                          </li>
                          <li className="list-start hide"></li>

                          <DragDropContext onDragEnd={onDragEndSP}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {list && list.length > 0
                                    ? list.map((item, index) => {
                                      let currentList = false;
                                      let curList = [
                                        listHide.People,
                                        listHide.Skills,
                                        listHide.Roles,
                                        listHide.Domains,
                                        listHide.Links,
                                        listHide.Circles,
                                        listHide.Projects,
                                      ];
                                      let curLists = [
                                        "People",
                                        /* GetAliasesName(teams, "Skills") */"Skills",
                                        /* GetAliasesName(teams, "Roles") */"Roles",
                                        /* GetAliasesName(teams, "Domains") */"Domains",
                                        /* GetAliasesName(teams, "Links") */"Links",
                                        /* GetAliasesName(teams, "Circles") */"Circles",
                                        /* GetAliasesName(teams, "Projects") */"Projects",
                                      ];

                                      for (
                                        let i = 0;
                                        i < curLists.length;
                                        i++
                                      ) {
                                        if (curLists[i] === item) {
                                          currentList = curList[i];
                                        }
                                      }

                                      return (
                                        <Draggable
                                          isDragDisabled={false}
                                          key={item + 1}
                                          draggableId={item}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <li
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            // className="card"
                                            >
                                              <label>
                                                <input
                                                  className="list_name"
                                                  name={item}
                                                  onChange={(e) =>
                                                    listHideOnchange(e)
                                                  }
                                                  type="checkbox"
                                                  checked={currentList}
                                                  id={`${item.toLocaleLowerCase()}`}
                                                />
                                                {GetAliasesName(teams, item) || item}
                                              </label>
                                            </li>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                    : null}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </ul>
                      </div>

                      {!focused || focused.card === "" ? (
                        <div
                          className="col-xs-6"
                          style={{ borderLeft: "1px solid darkgray" }}
                        >
                          <ul className="checkbox-menu allow-focus" role="menu">
                            <li className="dropdown-header">
                              Sort all lists together
                            </li>
                            <li
                              className="dropdown-header"
                              style={{ visibility: "hidden" }}
                            >
                              {" "}
                              * Drag & sort cards for personal
                            </li>
                            <li>
                              <label>
                                <input
                                  className="sortcards-all"
                                  onChange={(e) => setSMode("NewToOld", e)}
                                  checked={naveSort === "NewToOld"}
                                  name="sortcards-all"
                                  type="checkbox"
                                  data-sort_order="n"
                                />
                                New to Old <i className="fa fa-arrow-down"></i>
                              </label>
                            </li>

                            <li>
                              <label>
                                <input
                                  className="sortcards-all"
                                  onChange={() => setSMode("OldToNew")}
                                  name="sortcards-all"
                                  checked={naveSort === "OldToNew"}
                                  type="checkbox"
                                  data-sort_order="nd"
                                />
                                Old to New <i className="fa fa-arrow-down"></i>
                              </label>
                            </li>

                            <li>
                              <label>
                                <input
                                  className="sortcards-all"
                                  onChange={() => setSMode("AtoZ")}
                                  checked={naveSort === "AtoZ"}
                                  name="sortcards-all"
                                  type="checkbox"
                                  data-sort_order="asc"
                                />
                                A to Z <i className="fa fa-arrow-down"></i>
                              </label>
                            </li>

                            <li>
                              <label>
                                <input
                                  className="sortcards-all"
                                  onChange={() => setSMode("ZtoA")}
                                  checked={naveSort === "ZtoA"}
                                  name="sortcards-all"
                                  type="checkbox"
                                  data-sort_order="desc"
                                />
                                Z to A <i className="fa fa-arrow-down"></i>
                              </label>
                            </li>

                            <li>
                              <label>
                                <input
                                  className="sortcards-all"
                                  name="sortcards-all"
                                  type="checkbox"
                                  onChange={() => setSMode("Parsonal")}
                                  checked={naveSort === "Parsonal"}
                                  data-sort_order="menu_order"
                                />
                                Personal <i className="fa fa-arrows-alt-v"></i>
                              </label>
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="dropdown-menu" id="dropdown-menu">
                      <div className="col-xs-6">
                        <ul
                          className="checkbox-menu allow-focus sortable-list-menu"
                          id="list-menu"
                          role="menu"
                        >
                          <li className="dropdown-header">
                            {" "}
                            Select to hide list(s){" "}
                          </li>
                          <li className="dropdown-header">
                            {" "}
                            Drag to order list(s){" "}
                          </li>
                          <li className="list-start hide"></li>
                          <DragDropContext onDragEnd={onDragEndSPCM}>
                            <Droppable droppableId="droppable">
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                >
                                  {focusList && focusList.length > 0
                                    ? focusList.map((item, index) => {
                                      let currentList = false;
                                      if (fList) {
                                        currentList = fList[item.name];
                                      }
                                      // console.log(item)
                                      return (
                                        <Draggable
                                          isDragDisabled={false}
                                          key={item.name + 1}
                                          draggableId={item.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <li
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                              )}
                                            // className="card"
                                            >
                                              <label>
                                                <input
                                                  className="list_name"
                                                  name={item.name}
                                                  onChange={(e) =>
                                                    fListHideOnchange(e)
                                                  }
                                                  type="checkbox"
                                                  checked={currentList}
                                                  id={`${item.id.toLocaleLowerCase()}`}
                                                />
                                                {/* {item.name} */}
                                                {GetAliasesName(teams, item.name) || item.name}
                                              </label>
                                            </li>
                                          )}
                                        </Draggable>
                                      );
                                    })
                                    : null}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>

                {/* <div className="dropdown" style={{ fontSize: "20px", color: "darkgray", backgroundColor: "#eeeeee", width: "2px", height: "28px" }}></div> */}

                {workLoadToggle === true && (
                  <>
                    <div className="dropdown" style={{ fontSize: "20px", color: "darkgray", backgroundColor: "#eeeeee", width: "2px", height: "28px" }}></div>
                    <HeaderFTE
                      peoples={peoples}
                      skills={skills}
                      roles={roles}
                      dRoles={dRoles}
                      domains={domains}
                      links={links}
                      circles={circles}
                      projects={projects}
                    />
                  </>
                )}

                <div className="dropdown modes hide">
                  <div
                    className="btn btn-mytpt dropdown-toggle"
                    data-toggle="dropdown"
                    style={{
                      minWidth: "108px", display: "flex",
                      justifyContent: "space-between", alignItems: "center", borderBottom: (focusMode.length > 0 || compareMode.active || purposeTreeMode) ? '2px solid #000000' : 'none', borderRadius: 'unset'
                    }}
                  >
                    {focusMode && focusMode === 'Circle' ? GetAliasesName(teams, 'Circles') : focusMode === 'Project' ? GetAliasesName(teams, 'Projects') : compareMode.active ? 'Compare' : purposeTreeMode ? "Purpose Tree" : 'Select Mode'}
                    <i
                      style={{ marginLeft: "10px" }}
                      className="fa fa-caret-down"
                    ></i>
                  </div>
                  <ul className="dropdown-menu" role="menu">
                    <li
                      role="presentation"
                      className={
                        focusMode || focused.card || compareMode.active
                          ? "dropdown"
                          : "dropdown"
                      }
                    >
                      <a href="#"
                        onClick={() => {
                          setFocusedMode("Circle");
                          setCompareMode({
                            active: false,
                            compareTypes: [],
                            compareBetween: [],
                          });
                          setAnalyticsComponent(false); // Exit Analytics mode 
                          removeTags(); // Remove tags
                          setPurposeTreeMode(false);
                          setShowHomePage(false); // Hide Home page
                        }}
                      >
                        {/* Circles */GetAliasesName(teams, "Circles")}
                      </a>
                    </li>

                    <li
                      role="presentation"
                      onClick={() => {
                        setFocusedMode("Project");
                        setCompareMode({
                          active: false,
                          compareTypes: [],
                          compareBetween: [],
                        });
                        setAnalyticsComponent(false); // Exit Analytics mode 
                        removeTags(); // Remove tags
                        setPurposeTreeMode(false);
                        setShowHomePage(false); // Hide Home page
                      }}
                    >
                      <a href="#">{/* Projects */GetAliasesName(teams, "Projects")}</a>
                    </li>

                    <li
                      role="presentation"
                    >
                      <a href="#">
                        <span
                          onClick={() => {
                            showComaprePopup();
                            setAnalyticsComponent(false); // Exit Analytics mode 
                            removeTags(); // Remove tags
                            setShowHomePage(false); // Hide Home page
                          }}>Compare</span>

                        <span
                          onClick={() => {
                            window.open("https://teamdecoder.tawk.help/article/compare-mode", "_blank")
                          }}>{" "}<img src="/images/icon-info.png" style={{ width: "12px", verticalAlign: "middle" }} />
                        </span>
                      </a>
                    </li>

                    <li
                      role="presentation"
                    >
                      <a> <span onClick={() => {
                        setAnalyticsComponent(false); // Exit Analytics mode 
                        removeTags(); // Remove tags
                        showPurposeTree()// Show purpose tree
                      }}>Purpose Tree</span>
                        <span onClick={() => {
                          window.open("https://teamdecoder.tawk.help/article/purpose-tree", "_blank")
                        }}>{" "}<img src="/images/icon-info.png" style={{ width: "12px", verticalAlign: "middle" }} /></span>
                      </a>
                    </li>

                  </ul>
                </div>

                <div className="dropdown analytics hide">
                  <div
                    onClick={() => {
                      removeFocused(); // Exit focus mode
                      setFocusedMode("");
                      setCompareMode({
                        active: false,
                        startComparing: false,
                        compareTypes: [],
                        compareBetween: [],
                      });
                      setAnalyticsComponent(true);
                      removeTags(); // Remove tags
                      setPurposeTreeMode(false);
                    }}
                    // style={{textDecoration: analyticsComponent ? 'underline': 'none'}}
                    style={{ borderBottom: analyticsComponent ? '2px solid #000000' : 'none', borderRadius: 'unset' }}
                    className="btn btn-mytpt btn-mytpt-modes project-mode"
                  >
                    Scores
                  </div>
                </div>

                {
                  (
                    (focusMode && (focusMode === 'Circle' || focusMode === 'Project')) ||
                    (focused && focused.card.length > 0) ||
                    (compareMode.compareBetween.length > 1) ||
                    (isNO || isEXT) ||
                    (isTag && isTag.length > 0)
                  ) &&
                  (
                    <div className="dropdown" style={{ fontSize: "20px", color: "darkgray", backgroundColor: "#eeeeee", width: "2px", height: "28px" }}></div>
                  )}

                {focusMode && focusMode.length > 0 ? (
                  <div
                    className="dropdown"
                    style={{
                      padding: "0 5px",
                      border: "1px solid #000",
                      borderRadius: "5px",
                      height: "32px",
                      fontSize: "14px",
                    }}
                  >
                    Added as
                    <label className="switch">
                      <input
                        name="view"
                        className="view"
                        type="checkbox"
                        checked={isModeSwitched}
                        onChange={() => FMPeopleAS(focusMode)}
                      />
                      <span className="slider"></span>
                    </label>
                    People
                  </div>
                ) : null}

                {focused && focused.card.length > 0 ? (
                  <div className="dropdown focus-mode-exit">
                    <div
                      onClick={() => removeFocused()}
                      className="btn btn-mytpt-red"
                    >
                      <i className="fas fa-times-circle"></i> Focus
                    </div>
                  </div>
                ) : null}

                {compareMode &&
                  compareMode.active == true &&
                  compareMode.compareBetween.length > 1 && (
                    <>
                      {compareMode.startComparing ? (
                        <div
                          onClick={() => {
                            setFocusedMode("");
                            setCompareMode((prev) => ({
                              ...prev,
                              startComparing: false,
                            }));
                            removeTags();
                          }}
                          className="dropdown btn btn-mytpt btn-mytpt-modes project-mode"
                          style={{
                            padding: "0 5px",
                            border: "1px solid #000",
                            borderRadius: "5px",
                            height: "32px",
                            fontSize: "14px",
                          }}
                        >
                          <i className="far fa-times-circle"></i> &nbsp;{" "}
                          {"Reset"}
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            setFocusedMode("");
                            setCompareMode((prev) => ({
                              ...prev,
                              startComparing: true,
                            }));
                          }}
                          className="dropdown btn btn-mytpt btn-mytpt-modes project-mode"
                          style={{
                            padding: "0 5px",
                            border: "1px solid #000",
                            borderRadius: "5px",
                            height: "32px",
                            fontSize: "14px",
                          }}
                        >
                          <i className="far fa-play-circle"></i> &nbsp;
                          {"Start"}
                        </div>
                      )}
                    </>
                  )}

                {/* No owner cards exit */}
                {!specialModeDataObj.length > 0 &&
                  !clickedOnList.isClicked &&
                  isNO && (
                    <div
                      onClick={() => removeNo()}
                      className="dropdown no-owner-exit"
                    >
                      <div className="btn btn-mytpt-red">
                        <i className="fas fa-times-circle"></i> NO
                      </div>
                    </div>
                  )}

                {/* Cards with external exit */}
                {!specialModeDataObj.length > 0 &&
                  !clickedOnList.isClicked &&
                  isEXT && (
                    <div
                      onClick={() => removeExt()}
                      className="dropdown ext-owner-exit"
                    >
                      <div className="btn btn-mytpt-red">
                        <i className="fas fa-times-circle"></i> EXT
                      </div>
                    </div>
                  )}

                {isTag && isTag.length > 0 ? (
                  <div
                    onClick={() => removeTags()}
                    className="dropdown ext-owner-exit"
                  >
                    <div className="btn btn-mytpt-red">
                      <i className="fas fa-times-circle"></i> {isTag}
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="center"></div>

              <div className="right">
                <span className="dropdown clearable3">
                  <input
                    type="text"
                    className="form-control"
                    value={allSearch}
                    ref={AllSref}
                    onChange={(e) => setAllSearch(e.target.value)}
                    name="search_list3"
                    placeholder="Search all..."
                    autoComplete="off"
                  />
                  {allSearch.length > 0 ? (
                    <i
                      className="clearable__clear"
                      onClick={() => setAllSearch("")}
                      style={{ display: "inline", color: "black" }}
                    >
                      ×
                    </i>
                  ) : null}
                </span>

                {/* Search menu */}
                <div className="dropdown search-trigger">
                  <div
                    className="btn btn-mytpt dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    <img alt="settings" src="images/newicons/search-2.png" style={{ width: "18px" }} />
                  </div>

                  <div className="dropdown-menu dropdown-menu-right" style={{ width: "295px", padding: "5px" }} role="menu">
                    <span className="dropdown clearable3">
                      <input
                        type="text"
                        className="form-control"
                        value={allSearch}
                        ref={AllSref}
                        onChange={(e) => setAllSearch(e.target.value)}
                        name="search_list3"
                        placeholder="Search all..."
                        autoComplete="off"
                      />
                      {allSearch.length > 0 ? (
                        <i
                          className="clearable__clear"
                          onClick={() => setAllSearch("")}
                          style={{ display: "inline", color: "black" }}
                        >
                          ×
                        </i>
                      ) : null}
                    </span>

                  </div>
                </div>

                {/* Download menu */}
                <div className="dropdown">
                  <div
                    className="btn btn-mytpt dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {/* <i className="fas fa-download"></i> */}
                    {/* Download */}
                    <img alt="download" src="images/newicons/download.png" style={{ width: "18px", height: "16px" }} />
                  </div>

                  <ul className="dropdown-menu dropdown-menu-right" role="menu">
                    <li role="presentation">
                      <a
                        role="menuitem"
                        id="mytpt-download-jpeg"
                        onClick={() => handleDownloadImage()}
                        href="#"
                      >
                        Download JPEG
                      </a>
                    </li>

                    <li role="presentation">
                      <a role="menuitem">
                        <ReactHTMLTableToExcel
                          id="mytpt-download-xls"
                          className="download-table-xls-button"
                          table="table-to-xls"
                          filename="xls"
                          sheet="tablexls"
                          role="menuitem"
                          buttonText="Download as XLS"
                        />

                        <table id="table-to-xls" className="hide">
                          <thead>
                            {(xlsData &&
                              xlsData.length > 0 &&
                              xlsName.length === 0) ||
                              (xls && xls.length > 0 && xlsName.length === 0) ? (
                              <tr>
                                <th>People</th>
                                <th>Skill</th>
                                <th>Role</th>
                                <th>Domain</th>
                                <th>Link</th>
                                <th>Circle</th>
                                <th>Project</th>
                              </tr>
                            ) : (
                              <tr>
                                {xlsName && xlsName.length > 0
                                  ? xlsName.map((l, index) => {
                                    return <th key={index}>{l}</th>;
                                  })
                                  : null}
                              </tr>
                            )}
                          </thead>

                          <tbody>
                            {xlsCM && xlsCM.length === 0
                              ? xlsData &&
                                xls.length === 0 &&
                                xlsData.length > 0
                                ? xlsData.map((item, indexs) => (
                                  <tr key={indexs}>
                                    <td>{item.People}</td>
                                    <td>{item.Skill}</td>
                                    <td>{item.Role}</td>
                                    <td>{item.Domain}</td>
                                    <td>{item.Link}</td>
                                    <td>{item.Circle}</td>
                                    <td>{item.Project}</td>
                                  </tr>
                                ))
                                : xls.map((item, indexs) => (
                                  <tr key={indexs}>
                                    <td>{item.People}</td>
                                    <td>{item.Skill}</td>
                                    <td>{item.Role}</td>
                                    <td>{item.Domain}</td>
                                    <td>{item.Link}</td>
                                    <td>{item.Circle}</td>
                                    <td>{item.Project}</td>
                                  </tr>
                                ))
                              : xlsCM.map((item, indexs) => (
                                <tr key={indexs}>
                                  {xlsName && xlsName.length > 0
                                    ? xlsName.map((i, indexf) => {
                                      return (
                                        <td key={indexf}>{item[indexf]}</td>
                                      );
                                    })
                                    : null}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </a>
                    </li>
                  </ul>
                </div>

                {/* Settings menu */}
                <div className="dropdown">
                  <div
                    className="btn btn-mytpt dropdown-toggle"
                    data-toggle="dropdown"
                  >
                    {/* <i className="fas fa-cog"></i> */}
                    {/* Settings */}
                    <img alt="settings" src="images/newicons/settings.png" style={{ width: "18px" }} />
                  </div>

                  <ul
                    className="dropdown-menu dropdown-menu-right"
                    style={{ width: "295px" }}
                    role="menu"
                  >
                    <li className="dropdown-header">
                      Welcome{" "}
                      <b>{`${curUserData.fname} ${curUserData.lname}`}</b>!
                    </li>

                    <li role="presentation" type="button" onClick={openProfile}>
                      <a
                        role="menuitem"
                        data-toggle="modal"
                        data-target="#profileModal"
                      >
                        Profile
                      </a>
                    </li>

                    {(curUserData.plan !== "FREE" || myInfo.role !== "User") && (myInfo.role !== "Co-Admin") && (
                      <li role="presentation" type="button" onClick={openAccessManagement} >
                        <a
                          role="menuitem"
                          data-toggle="modal"
                          data-target="#profileModal"
                        >
                          Access Management
                        </a>
                      </li>
                    )}

                    {myInfo.role !== "Co-Admin" && curUserData && Object.keys(curUserData).length > 0 && curUserData.plan !== "FREE" && curUserData.plan !== "free_forever" && curUserData.plan !== "Consulting" ? (
                      <li role="presentation">
                        <a
                          role="menuitem"
                          target="_blank"
                          rel="noopener nofollow"
                          href={process.env.REACT_APP_NODE_ENV === "production" ? "https://billing.stripe.com/p/login/bIY6py2H376c1JC000" : "https://billing.stripe.com/p/login/test_eVa4hz8csahL46s144"}
                        >
                          Manage Subscription
                        </a>
                      </li>
                    ) : (
                      myInfo.role !== "Co-Admin" && curUserData.plan !== "Consulting" /* && curUserData.plan !== "free_forever" */ && curUserData.plan !== "Consulting" &&
                      <li role="presentation">
                        <a
                          role="menuitem"
                          target="_blank"
                          rel="noopener nofollow"
                          onClick={handleUpgradeAccount}
                        >
                          Upgrade Account
                        </a>
                      </li>
                    )}

                    {myInfo.role !== "Co-Admin" && (
                      <li role="presentation" type="button" onClick={openSetting}>
                        <a
                          role="menuitem"
                          data-toggle="modal"
                          data-target="#settingsModal"
                        >
                          Manage Tags
                        </a>
                      </li>
                    )}

                    {myInfo.role !== "Co-Admin" && (
                      <li role="presentation" type="button" >
                        <a
                          href="/onboarding-wizard"
                          role="menuitem"
                        >
                          Onboarding
                        </a>
                      </li>
                    )}


                    {/* ==============[TRASH OPEN MODEL]============ */}
                    {myInfo.role !== "Co-Admin" && (
                      <li role="presentation" type="button" onClick={openTrash}>
                        <a
                          role="menuitem"
                          data-toggle="modal"
                          data-target="#settingsModal"
                        >
                          Archive
                        </a>
                      </li>
                    )}

                    {curUserData && curUserData.plan === "GO" && (
                      <li role="presentation" type="button" onClick={openReferal}>
                        <a
                          role="menuitem"
                          data-toggle="modal"
                          data-target="#settingsModal"
                        >
                          Referral Program
                        </a>
                      </li>
                    )}

                    <li role="presentation">
                      <a role="menuitem" style={{ paddingRight: "5px" }}>
                        Show Notes
                        <span onClick={() => {
                          window.open("https://teamdecoder.tawk.help/article/notes-boxes", "_blank")
                        }}>{" "}<img src="/images/icon-info.png" style={{ width: "12px", verticalAlign: "middle" }} /></span>
                        <label className="switch">
                          <input
                            name="show_all_Notes"
                            onChange={(e) => noteOnchange(e)}
                            data-list_id="all"
                            checked={allNote}
                            className="dd-switch"
                            type="checkbox"
                          />
                          <span className="slider"></span>
                        </label>
                      </a>
                    </li>

                    <li role="presentation">
                      <a role="menuitem" style={{ paddingRight: "5px" }}>
                        Show Workload

                        <span onClick={() => {
                          window.open("https://teamdecoder.tawk.help/article/workloadplanning", "_blank")
                        }}>{" "}<img src="/images/icon-info.png" style={{ width: "12px", verticalAlign: "middle" }} /></span>

                        <label className="switch">
                          <input
                            name="show_workload"
                            onChange={(e) => workloadToggleController(e)}
                            data-list_id="all"
                            checked={workLoadToggle}
                            className="dd-switch"
                            type="checkbox"
                          />
                          <span className="slider"></span>
                        </label>
                      </a>
                    </li>

                    <li role="presentation">
                      <a role="menuitem" style={{ paddingRight: "5px" }}>
                        Show Generic Roles
                        <span onClick={() => {
                          window.open("https://teamdecoder.tawk.help/article/generic-items", "_blank")
                        }}>{" "}<img src="/images/icon-info.png" style={{ width: "12px", verticalAlign: "middle" }} /></span>
                        <label className="switch">
                          <input
                            name="show_all_Notes"
                            onChange={(e) => genericRolechange()}
                            data-list_id="all"
                            checked={showGenericRole}
                            className="dd-switch"
                            type="checkbox"
                          />
                          <span className="slider"></span>
                        </label>
                      </a>
                    </li>

                    <li role="presentation" className="divider"></li>

                    <li className="dropdown-header">Support:</li>

                    <li role="presentation">
                      <a
                        role="menuitem"
                        target="_blank"
                        rel="noopener nofollow"
                        href="mailto:helpdesk@teamdecoder.com"
                      >
                        helpdesk@teamdecoder.com
                      </a>
                    </li>

                    {/* <li role="presentation">
                      <a
                        role="menuitem"
                        target="_blank"
                        rel="noopener nofollow"
                        href="http://livevideosupport.teamdecoder.com"
                      >
                        Live Video Support
                      </a>
                    </li> */}

                    <li role="presentation">
                      <a
                        role="menuitem"
                        target="_blank"
                        rel="noopener nofollow"
                        href="https://teamdecoder.tawk.help/"
                      >
                        Knowledge Base
                      </a>
                    </li>

                    {/* <li role="presentation">
                      <a role="menuitem" href="javascript:Appcues.show('a55ff890-fae7-4dc6-9541-4ebf7c9b617a')">
                        Watch Tour Again
                      </a>
                    </li> */}

                    <li role="presentation" className="divider"></li>
                    {/* <li role="presentation">
                      <a role="menuitem" id="imprint">
                        Cookie Settings
                      </a>
                    </li> */}
                    <GoogleLogout
                      clientId="740610511692-mlkgdr9pd0ap1r5nvvu4vvlgnja86p8v.apps.googleusercontent.com"
                      render={(renderProps) => (
                        <li
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          role="presentation"
                        >
                          <a role="menuitem" id="mytpt-logout" href="#">
                            Sign Out
                          </a>
                        </li>
                      )}
                      buttonText="Logout"
                      onLogoutSuccess={SignOut}
                      isSignOut={true}
                    ></GoogleLogout>

                    <li role="presentation" className="divider"></li>

                    <li role="presentation" className="usage-terms">
                      <a role="menuitem" rel="noopener nofollow" target="_blank" href="/legal-notice/">
                        Legal Notice
                      </a>{" "}
                      |{" "}
                      <a
                        role="menuitem"
                        target="_blank"
                        rel="noopener nofollow"
                        href="https://legal.teamdecoder.com/privacy-policy"
                      >
                        Privacy Policy
                      </a>{" "}
                      |{" "}
                      <a
                        role="menuitem"
                        target="_blank"
                        rel="noopener nofollow"
                        href="https://legal.teamdecoder.com/terms-of-service"
                      >
                        Terms of Service
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </header>

            <SideNav curUserData={curUserData} myInfo={myInfo} teams={teams} setFocusedMode={setFocusedMode} focusMode={focusMode} compareMode={compareMode} setCompareMode={setCompareMode} analyticsComponent={analyticsComponent} showAnalytics={showAnalytics} setAnalyticsComponent={setAnalyticsComponent} removeTags={removeTags} purposeTreeMode={purposeTreeMode} setPurposeTreeMode={setPurposeTreeMode} showHomePage={showHomePage} setShowHomePage={setShowHomePage} showComaprePopup={showComaprePopup} showPurposeTree={showPurposeTree} removeFocused={removeFocused} report={false} />

            {/* App Board */}
            <main className="app-board">
              {/* Board */}
              <section className={`board-body ` + (analyticsComponent ? 'analytics-page' : '')} id="content">
                {!analyticsComponent ?
                  (<>
                    {purposeTreeMode ?
                      <PurposeTree
                        circles={circles}
                        dCircles={dCircles}
                        projects={projects}
                        skills={skills}
                        roles={roles}
                        dRoles={dRoles}
                        domains={domains}
                        links={links}
                        setSkill={setSkill}
                        setRole={setRole}
                        setDomain={setDomain}
                        setLink={setLink}
                        setCircle={setCircle}
                        setProject={setProject}
                        teams={teams}
                        openTeam={openTeam}
                        indexSet={indexSet}
                        indexInSet={index}
                        purposetreeloader={purposetreeloader}
                        setPurposetreeLoader={setPurposetreeLoader}
                        openCircle={openCircle}
                        openProject={openProject}
                        openSkill={openSkill}
                        openRole={openRole}
                        openDomain={openDomain}
                        openLink={openLink}
                        CloseStateHandel={CloseStateHandel}
                      />
                      : compareMode.startComparing ? (
                        <CompareModePage
                          workloadToggle={workLoadToggle}
                          openPeople={openPeople}
                          closePeople={closePeople}
                          openSkill={openSkill}
                          closeSkill={closeSkill}
                          openRole={openRole}
                          closeRole={closeRole}
                          openLink={openLink}
                          closeLink={closeLink}
                          openCircle={openCircle}
                          closeCircle={closeCircle}
                          openProject={openProject}
                          closeProject={closeProject}
                          openDomain={openDomain}
                          closeDomain={closeDomain}
                          index={index}
                          indexSet={indexSet}
                          list={list}
                          focusMode={focusMode}
                          compareMode={compareMode}
                          setCompareMode={setCompareMode}
                          naveSort={naveSort}
                          allSearch={allSearch}
                          isModeSwitched={isModeSwitched}
                          clickedOnList={clickedOnList}
                          setClickedOnList={setClickedOnList}
                          isEXT={isEXT}
                          setIsEXT={setIsEXT}
                          isNO={isNO}
                          setIsNO={setIsNO}
                          listHide={listHide}
                          isTag={isTag}
                          setIsTag={setIsTag}
                          isTagData={isTagData}
                          setIsTagData={setIsTagData}
                          tagFilteredObj={tagFilteredObj}
                          setTagFilteredObj={setTagFilteredObj}
                          extNoFilteredObj={extNoFilteredObj}
                          setExtNoFilteredObj={setExtNoFilteredObj}
                          specialModeDataObj={specialModeDataObj}
                          setSpecialModeDataObj={setSpecialModeDataObj}
                          setListHide={setListHide}
                          setFocused={setFocused}
                          focused={focused}
                          setPepole={setPepole}
                          setPepoles={setPepoles}
                          setSkill={setSkill}
                          setSkills={setSkills}
                          setRole={setRole}
                          setRoles={setRoles}
                          setDomain={setDomain}
                          setDomains={setDomains}
                          setLink={setLink}
                          setLinks={setLinks}
                          setCircle={setCircle}
                          setCircles={setCircles}
                          setProject={setProject}
                          setProjects={setProjects}
                          openInfo={openInfo}
                          closeInfo={closeInfo}
                          isInfos={isInfos}
                          isActive={isActive}
                          xlsData={xlsData}
                          setXls={setXls}
                          setXlsName={setXlsName}
                          setXlsCM={setXlsCM}
                          note={note}
                          focusList={focusList}
                          fList={fList}
                          setFList={setFList}
                          setNote={setNote}
                          noteOnchange={noteOnchange}
                          saveNote={saveNote}
                          setSaveNote={setSaveNote}
                          openPeopleModeTool={openPeopleModeTool}
                          openCircleModeTool={openCircleModeTool}
                          setFocusedMode={setFocusedMode}
                          temporarilyMovedItem={temporarilyMovedItem}
                          setTemporarilyMovedItem={setTemporarilyMovedItem}
                        />
                      ) : showHomePage ?
                        (
                          <HomePage
                            removeFocused={removeFocused} // Exit focus mode
                            setFocusedMode={setFocusedMode} // Exit Circle/ Project mode
                            showComaprePopup={showComaprePopup} // Show Comapre Popup
                            setCompareMode={setCompareMode}
                            showAnalytics={showAnalytics}
                            setAnalyticsComponent={setAnalyticsComponent} // Exit Analytics mode 
                            removeTags={removeTags} // Remove tags
                            showPurposeTree={showPurposeTree}
                            setPurposeTreeMode={setPurposeTreeMode}
                            setShowHomePage={setShowHomePage} // Show Home page
                            openAccessManagement={openAccessManagement}
                            openTeam={openTeam}
                            openEditTemplate={openEditTemplate}
                          />
                        )
                        : (
                          <Body
                            workloadToggle={workLoadToggle}
                            openPeople={openPeople}
                            closePeople={closePeople}
                            openSkill={openSkill}
                            closeSkill={closeSkill}
                            openRole={openRole}
                            closeRole={closeRole}
                            openLink={openLink}
                            closeLink={closeLink}
                            openCircle={openCircle}
                            closeCircle={closeCircle}
                            openProject={openProject}
                            closeProject={closeProject}
                            openDomain={openDomain}
                            closeDomain={closeDomain}
                            index={index}
                            indexSet={indexSet}
                            list={list}
                            focusMode={focusMode}
                            compareMode={compareMode}
                            setCompareMode={setCompareMode}
                            naveSort={naveSort}
                            allSearch={allSearch}
                            isModeSwitched={isModeSwitched}
                            clickedOnList={clickedOnList}
                            setClickedOnList={setClickedOnList}
                            isEXT={isEXT}
                            setIsEXT={setIsEXT}
                            isNO={isNO}
                            setIsNO={setIsNO}
                            listHide={listHide}
                            isTag={isTag}
                            setIsTag={setIsTag}
                            isTagData={isTagData}
                            setIsTagData={setIsTagData}
                            tagFilteredObj={tagFilteredObj}
                            setTagFilteredObj={setTagFilteredObj}
                            extNoFilteredObj={extNoFilteredObj}
                            setExtNoFilteredObj={setExtNoFilteredObj}
                            specialModeDataObj={specialModeDataObj}
                            setSpecialModeDataObj={setSpecialModeDataObj}
                            setListHide={setListHide}
                            setFocused={setFocused}
                            focused={focused}
                            setPepole={setPepole}
                            setPepoles={setPepoles}
                            setSkill={setSkill}
                            setSkills={setSkills}
                            setRole={setRole}
                            setRoles={setRoles}
                            setDomain={setDomain}
                            setDomains={setDomains}
                            setLink={setLink}
                            setLinks={setLinks}
                            setCircle={setCircle}
                            setCircles={setCircles}
                            setProject={setProject}
                            setProjects={setProjects}
                            openInfo={openInfo}
                            closeInfo={closeInfo}
                            isInfos={isInfos}
                            isActive={isActive}
                            xlsData={xlsData}
                            setXls={setXls}
                            setXlsName={setXlsName}
                            setXlsCM={setXlsCM}
                            note={note}
                            focusList={focusList}
                            fList={fList}
                            setFList={setFList}
                            setNote={setNote}
                            noteOnchange={noteOnchange}
                            saveNote={saveNote}
                            setSaveNote={setSaveNote}
                            openPeopleModeTool={openPeopleModeTool}
                            openCircleModeTool={openCircleModeTool}
                            setFocusedMode={setFocusedMode}
                            temporarilyMovedItem={temporarilyMovedItem}
                            setTemporarilyMovedItem={setTemporarilyMovedItem}
                            teams={teams}
                            SConToRole={SConToRole}
                            RConToSkill={RConToSkill}
                            circleToProject={circleToProject}
                            projectToCircle={projectToCircle}
                            showGenericRole={showGenericRole}
                          />
                        )
                    }
                  </>) : <AnalyticsPage />
                }

                <Modal
                  isOpen={peopleOpen}
                  onRequestClose={() => CloseStateHandel("People")}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  {pShowState !== "People" ? (
                    <>
                      <ConditionalOveralayModal />
                      <PeopleModal
                        data={index}
                        openPeople={openPeople}
                        focusMode={focusMode}
                        closePeople={closePeople}
                        setPepoles={setPepole}
                        CloseState={CloseState}
                        stateCloseState={stateCloseState}
                        isNToP={isNToP}
                        setFocused={setFocused}
                        focused={focused}
                        compareMode={compareMode}
                        teams={teams}
                      />
                    </>
                  ) : null}
                </Modal>

                <Modal
                  isOpen={skillOpen}
                  onRequestClose={() => {
                    CloseStateHandel("Skills");
                  }}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  {pShowState !== "Skill" ? (
                    <>
                      <ConditionalOveralayModal />
                      <SkillModal
                        data={index}
                        closeSkill={closeSkill}
                        CloseState={CloseState}
                        stateCloseState={stateCloseState}
                        focusMode={focusMode}
                        setSkills={setSkill}
                        isNToP={isNToP}
                        setFocused={setFocused}
                        focused={focused}
                        compareMode={compareMode}
                        teams={teams}
                        setPurposetreeLoader={setPurposetreeLoader}
                      />
                    </>
                  ) : null}
                </Modal>

                <Modal
                  isOpen={roleOpen}
                  onRequestClose={() => { CloseStateHandel("Roles") }}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  {pShowState !== "Role" ? (
                    <>
                      <ConditionalOveralayModal />
                      <RoleModal
                        data={index}
                        closeRole={closeRole}
                        CloseState={CloseState}
                        stateCloseState={stateCloseState}
                        focusMode={focusMode}
                        setRoles={setRole}
                        isNToP={isNToP}
                        setFocused={setFocused}
                        focused={focused}
                        compareMode={compareMode}
                        teams={teams}
                        setPurposetreeLoader={setPurposetreeLoader}
                      />
                    </>
                  ) : null}
                </Modal>

                <Modal
                  isOpen={projectOpen}
                  onRequestClose={() => { CloseStateHandel("Projects") }}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  {pShowState !== "Project" ? (
                    <>
                      <ConditionalOveralayModal />
                      <ProjectModel
                        data={index}
                        closeProject={closeProject}
                        CloseState={CloseState}
                        stateCloseState={stateCloseState}
                        focusMode={focusMode}
                        setProjects={setProject}
                        isNToP={isNToP}
                        setFocused={setFocused}
                        focused={focused}
                        clearModes={clearModes}
                        teams={teams}
                        setPurposetreeLoader={setPurposetreeLoader}
                      />
                    </>
                  ) : null}
                </Modal>

                <Modal
                  isOpen={circleOpen}
                  onRequestClose={() => { CloseStateHandel("Circles") }}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                  contentClassName="modal-content"
                >
                  {pShowState !== "Circle" ? (
                    <>
                      <ConditionalOveralayModal />
                      <CircleModel
                        data={index}
                        closeCircle={closeCircle}
                        CloseState={CloseState}
                        stateCloseState={stateCloseState}
                        focusMode={focusMode}
                        setCircles={setCircle}
                        isNToP={isNToP}
                        setFocused={setFocused}
                        focused={focused}
                        clearModes={clearModes}
                        teams={teams}
                        setPurposetreeLoader={setPurposetreeLoader}
                      />
                    </>
                  ) : null}
                </Modal>

                <Modal
                  isOpen={linkOpen}
                  onRequestClose={() => { CloseStateHandel("Links") }}
                  shouldCloseOnOverlayClick={true}
                  closeTimeoutMS={20}
                  openTimeoutMS={20}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  {pShowState !== "Link" ? (
                    <>
                      <ConditionalOveralayModal />
                      <LinkModel
                        data={index}
                        closeLink={closeLink}
                        CloseState={CloseState}
                        stateCloseState={stateCloseState}
                        focusMode={focusMode}
                        setLinks={setLink}
                        isNToP={isNToP}
                        setFocused={setFocused}
                        focused={focused}
                        compareMode={compareMode}
                        teams={teams}
                        setPurposetreeLoader={setPurposetreeLoader}
                      />
                    </>
                  ) : null}
                </Modal>

                <Modal
                  isOpen={domainOpen}
                  onRequestClose={() => { CloseStateHandel("Domains") }}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  {pShowState !== "Domain" ? (
                    <>
                      <ConditionalOveralayModal />
                      <DomainModel
                        data={index}
                        closeDomain={closeDomain}
                        CloseState={CloseState}
                        stateCloseState={stateCloseState}
                        focusMode={focusMode}
                        setDomains={setDomain}
                        isNToP={isNToP}
                        setFocused={setFocused}
                        focused={focused}
                        compareMode={compareMode}
                        teams={teams}
                        setPurposetreeLoader={setPurposetreeLoader}
                      />
                    </>
                  ) : null}
                </Modal>

                <Modal
                  isOpen={teamOpen}
                  // onRequestClose={closeTeam}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  <TeamModel
                    load={load}
                    setLoad={setLoad}
                    closeTeam={closeTeam}
                  />
                </Modal>

                <Modal
                  isOpen={templateOpen}
                  onRequestClose={closeEditTemplate}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  <EditTemplateModal
                    load={load}
                    setLoad={setLoad}
                    closeEditTemplate={closeEditTemplate}
                    template={template}
                  />
                </Modal>

                <Modal
                  isOpen={profileOpen}
                  onRequestClose={closeProfile}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  <ProfileModel closeProfile={closeProfile} />
                </Modal>

                <Modal
                  isOpen={accessManagementOpen}
                  onRequestClose={closeAccessManagement}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  <AccessManagement
                    closeAccessManagement={closeAccessManagement}
                  />
                </Modal>

                <Modal
                  isOpen={settingOpen}
                  onRequestClose={closeSetting}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  <SettingModal closeSetting={closeSetting} />
                </Modal>

                {/* ========================[TRASH MODAL]======================== */}
                <Modal
                  isOpen={trashOpen}
                  onRequestClose={closeTrash}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  <TrashModal closeTrash={closeTrash} />
                </Modal>
                {/* user reffereal */}
                <Modal
                  isOpen={reffOpen}
                  onRequestClose={closeReferal}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyles}
                >
                  <ReferralModal closeReferal={closeReferal} />
                </Modal>

                <Modal
                  isOpen={infoOpen}
                  onRequestClose={closeInfo}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyle}
                >
                  <InfoModel data={infoData} closeInfo={closeInfo} />
                </Modal>

                <Modal
                  isOpen={pMTool}
                  onRequestClose={closePeopleModeTool}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  // style={customStyle}
                  className="people-modal-tool"
                >
                  <PeopleModeTool
                    focused={focused}
                    setFocused={setFocused}
                    closeCircleModeTool={closePeopleModeTool}
                    temporarilyMovedItem={temporarilyMovedItem}
                    setTemporarilyMovedItem={setTemporarilyMovedItem}
                    teams={teams}
                  />
                </Modal>

                <Modal
                  isOpen={cMTool}
                  onRequestClose={closeCircleModeTool}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyle}
                  className="circle-modal-tool"
                >
                  <CircleModelTool
                    focused={focused}
                    setFocused={setFocused}
                    closeCircleModeTool={closeCircleModeTool}
                    temporarilyMovedItem={temporarilyMovedItem}
                    setTemporarilyMovedItem={setTemporarilyMovedItem}
                  />
                </Modal>
                {/* <Modal
                  isOpen={load}
                  onRequestClose={closeCircleModeTool}
                  shouldCloseOnOverlayClick={true}
                  ariaHideApp={false}
                  style={customStyle}
                >
                  <SyncLoader
                    loading={load}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "90vh",
                      alignItems: "center",
                    }}
                    color="#36d7b7"
                  />
                </Modal> */}
              </section>
            </main>
          </div>
        </div>
      ) : (
        <div className="page-loader">
          <SyncLoader color="#36d7b7" />
        </div>
      )}
    </>
  );
}
