import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { useDispatch, useSelector } from 'react-redux';
import Navbar from "../../Component/Header/Navbar";
import { userSessionReset } from "../../store/actions/authAction"
import { CARD_LIMIT, CLIENT_URL } from "../../config/keys"
import { clearLocalStorage } from "../../HelperFunctions/ClearLocalStorage";

function ResetSession() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  document.body.style = "";
  document.body.classList = "no-app-pages body1";

  const { userId } = useParams();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(userId && userId.length > 0 && loading === false) {
        dispatch(userSessionReset({ userId: userId })).then(()=>{
            sessionStorage.clear();
            // localStorage.clear();
            clearLocalStorage();
            // navigate("/login")
            window.location.href = CLIENT_URL+"/login";
        })
    }
  }, [userId]);
  
  return (
    <div>
      <Navbar />
        <div className="page-loader">
          <SyncLoader color="#36d7b7" />
        </div>
    </div>
  );
}

export default ResetSession;