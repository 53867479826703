import React, { useState, useEffect, useCallback } from 'react';
import { userUpdate } from '../../store/actions/authAction';
import { SERVER_URI } from '../../config/keys';
import { useDispatch, useSelector } from 'react-redux';
import { clearLocalStorage } from '../../HelperFunctions/ClearLocalStorage';
import Swal from 'sweetalert2';

const ActivityTracker = () => {
  const dispatch = useDispatch();
  const {
    loading,
    successMessage,
    paySuccessMessage,
    error,
    authenticate,
    myInfo,
    curUserData,
    loginHistory,
  } = useSelector((state) => state.auth);
  const [popUpTimerRunning, SetPopUpTimerRunning] = useState(false);
  const [isActive, setIsActive] = useState(true); // Tracks if the user is active
  const [lastActiveTime, setLastActiveTime] = useState(Date.now()); // Tracks the last activity time
  const idleTimeout = 1000 * 60 * 60;
  const showPopUpBefore = 10000;


  // Function to handle user activity
  const handleUserActivity = useCallback((X) => {
    // console.log(X);// ---> it will return data for each activityEvents if that event occurs else return nothing
    setIsActive(true); // when return data then : isActive= true
    setLastActiveTime(Date.now()); // when return data then : lastActiveTime= true
  }, []); //--->This will call when compnent is load : mount

  useEffect(() => {
    // Reset activity status when user interacts with the page
    const activityEvents = ['mousemove', 'keydown', 'scroll', 'click']; //--> here i can track activityEvents

    activityEvents.forEach((event) =>
      window.addEventListener(event, handleUserActivity)
    );//---> for each it will call window function

    //---> it will see if currentTime - last activeTime >= idealTimeOut(here i set 3 seconds)-- if exceed then turn into isActive  = false
    const checkIdleStatus = () => {
      if (Date.now() - lastActiveTime >= idleTimeout) {
        setIsActive(false); // Mark as inactive if idle timeout exceeded
        SignOut();
      } else if (!popUpTimerRunning && Date.now() - lastActiveTime >= (idleTimeout - showPopUpBefore) && Date.now() - lastActiveTime < idleTimeout) {

        SetPopUpTimerRunning(true);
        let timerInterval;
        Swal.fire({
          title: "Are you continuing with current browser?",
          html: "I will close in <strong></strong> seconds.<br/><br/>",
          timer: showPopUpBefore,
          didOpen: () => {
            timerInterval = setInterval(() => {
              Swal.getHtmlContainer().querySelector("strong").textContent = (
                Swal.getTimerLeft() / 1000
              ).toFixed(0);
            }, 100);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then(() => {
          SetPopUpTimerRunning(false);
        });
      }
    };

    const idleInterval = setInterval(checkIdleStatus, 1000); // Check idle status every second
    //---> in every second it will check checkIdleStatus function

    return () => {
      // Clean up listeners and intervals
      activityEvents.forEach((event) =>
        window.removeEventListener(event, handleUserActivity)
      );
      clearInterval(idleInterval);
    };
  }, [handleUserActivity, lastActiveTime, popUpTimerRunning]);

  /* 
  --->
  1.as handleUserActivity is called during initial load: this useEffect also run
  2.every time it will change lastActiveTime
  */


  const SignOut = (e) => {
    let urls = `${SERVER_URI}/admin/update`;

    dispatch(
      userUpdate({
        adminId: curUserData._id,
        url: urls,
        teamId: curUserData.curTeam,
        sessionToken: "",
        logout: true,
      })
    ).then(() => {
      localStorage.removeItem("authToken");
      clearLocalStorage();
      document.body.classList = "no-app-pages body2 menu2";
      window.location.reload();
    });
  };

  useState(() => {
    if (!isActive) {
      SignOut();
    }
  }, [isActive]);


  // return (
  //   <div>
  //     <h1>User Activity Tracker</h1>
  //     <p>
  //       {isActive
  //         ? 'User is active on the website.'
  //         : 'User is inactive on the website.'}
  //     </p>
  //   </div>
  // );
  return null;
};

export default ActivityTracker;
