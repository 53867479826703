import React, { useEffect } from 'react'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PillComponent from '../PillComponent';

export default function PeopleList({ tagFilteredMegedData, GetAliasesName, teams, peopleSorted, clickedOnList, toggleEXTnNOCards, getSortIcon, isInfos, addNewPeoples, pCardHideOnchange, noteOnchange, handeSorts, setCombinedRefs, note, pnoteRef, handleSave, noteSaveData, spref, peopleSearch, setPeopleSearch, filteredPData, TagWiseSorting, statesLM, ScrollIntoView, setPepole, parentPeopleRef, setFocusedRef, setCardRef, workloadToggle, aggrigatedPersonFTE, toggleTagCards, compareMode, handleCheckboxSelection, IsChecked, handelFocused, lFromState, peopleSubmit, pref, fName, handleNameChange, pfNameHandel, setPepoles, setFName, peopleFrom, isTag, isEXT, isNO, tagFilteredObj, extNoFilteredObj, skills, roles, dRoles, domains, links }) {
    /* useEffect(() => {
        // Initialize PerfectScrollbar after the component mounts
        const container = document.querySelector('[data-board_id="people"] .scrollbar');
        if (container) {
            const ps = new window.PerfectScrollbar(container, {
                wheelSpeed: 1,
                wheelPropagation: true,
                minScrollbarLength: 10,
                suppressScrollY: false,
            });

            // Cleanup on component unmount
            return () => ps.destroy();
        }
    }, []); */

    return (
        <div
            className={
                !clickedOnList.listName.includes("people") && Object.keys(tagFilteredMegedData).length > 0 &&
                    tagFilteredMegedData.people &&
                    tagFilteredMegedData.people.length === 0
                    ? "board-col hide"
                    : "board-col"
            }
            data-board_id="people"
        >
            <div className="list list-bg-yellow" data-list_id="people">
                <div className="dropdown" style={{ position: "static" }}>
                    <h4 className="list-title">
                        <img alt="" src="images/icon-people.png" />
                        {GetAliasesName(teams, "People")} (<span>{peopleSorted.length}</span>)
                        {/* listId, pillType, reset = false, clickedOn = "list" */}
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu refresh-list"
                                    : "list-menu refresh-list hide"
                            }
                            href="#"
                            onClick={() => {
                                toggleEXTnNOCards("people", "", true, "list");
                            }}
                        >
                            <i className="fas fa-sync-alt"></i>
                        </a>
                        <a
                            className={
                                clickedOnList.isClicked &&
                                    clickedOnList.listName.includes("people")
                                    ? "list-menu dropdown-toggle hide"
                                    : "list-menu dropdown-toggle"
                            }
                            // className="list-menu dropdown-toggle"
                            data-toggle="dropdown"
                            href="#"
                        >
                            {getSortIcon("People")}
                            <i className="fas fa-ellipsis-v"></i>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right" role="menu">
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => isInfos("People")}
                                    className="mytpt-list-info"
                                >
                                    Info
                                </a>
                            </li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    type="button"
                                    onClick={() => addNewPeoples()}
                                >
                                    Add new person
                                </a>
                            </li>

                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    onClick={() => pCardHideOnchange("People")}
                                    className="hide_list"
                                >
                                    Hide list
                                </a>
                            </li>
                            <li role="presentation">
                                <a role="menuitem" style={{ paddingRight: "5px" }}>
                                    Show Notes
                                    <label className="switch">
                                        <input
                                            name="People"
                                            onChange={(e) => noteOnchange(e)}
                                            checked={note.People}
                                            className="dd-switch"
                                            type="checkbox"
                                        />
                                        <span className="slider"></span>
                                    </label>
                                </a>
                            </li>
                            <li role="presentation" className="divider"></li>
                            <li className="dropdown-header">Sort by:</li>
                            <li
                                role="presentation"
                                onClick={() => handeSorts("NewToOld", "People")}
                            >
                                <a role="menuitem" className="sortcards" data-sort_order="n">
                                    New to Old
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => handeSorts("OldToNew", "People")}
                            >
                                <a role="menuitem" className="sortcards" data-sort_order="nd">
                                    Old to New
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => handeSorts("AtoZ", "People")}
                            >
                                <a
                                    role="menuitem"
                                    className="sortcards"
                                    data-sort_order="asc"
                                >
                                    A-Z
                                </a>
                            </li>
                            <li
                                role="presentation"
                                onClick={() => handeSorts("ZtoA", "People")}
                            >
                                <a
                                    role="menuitem"
                                    className="sortcards"
                                    data-sort_order="desc"
                                >
                                    Z-A
                                </a>
                            </li>

                            <li role="presentation" className="divider"></li>
                            <li role="presentation">
                                <a
                                    role="menuitem"
                                    className="show-external"
                                    onClick={() => {
                                        toggleEXTnNOCards("people", "EXT", false);
                                    }}
                                >
                                    Show external
                                </a>
                            </li>
                        </ul>
                    </h4>
                </div>
                <Droppable droppableId="people" direction="vertical">
                    {(provided) => (
                        <div
                            // ref={provided.innerRef}
                            ref={setCombinedRefs(provided.innerRef, parentPeopleRef)}
                            {...provided.droppableProps}
                            className="card-list sortable-card-list scrollbar"
                        >
                            <div
                                className="form-group notes"
                                style={
                                    note.People ? { display: "block" } : { display: "none" }
                                }
                            >
                                <textarea
                                    className="notes"
                                    ref={pnoteRef}
                                    name="People"
                                    // defaultValue=''
                                    // value={noteSaveData.People}
                                    onBlur={(e) => handleSave(e)}
                                    // onBlur={(e) => saveNotes(e)}
                                    placeholder="Notes"
                                    maxLength="100"
                                    autoFocus={null}
                                    defaultValue={noteSaveData.People}
                                ></textarea>
                            </div>

                            <span className="clearable">
                                <input
                                    type="text"
                                    style={{ color: "black" }}
                                    ref={spref}
                                    value={peopleSearch}
                                    onChange={(e) => setPeopleSearch(e.target.value)}
                                    // onChange={(e) => toggleSearchCards('people', e.target.value)}
                                    className="form-control search_list transparent"
                                    name="search_list"
                                    placeholder="Start typing to search..."
                                    autoComplete="off"
                                />
                                {peopleSearch.length > 0 ? (
                                    <i
                                        className="clearable__clear"
                                        onClick={() => setPeopleSearch("")}
                                        style={{ display: "inline", color: "black" }}
                                    >
                                        ×
                                    </i>
                                ) : null}
                            </span>

                            {filteredPData && filteredPData.length > 0
                                ? filteredPData.map((e, index) => {
                                    if (isTag || isEXT || isNO) {
                                        let Proceed = TagWiseSorting(
                                            e,
                                            isTag,
                                            isEXT,
                                            isNO,
                                            tagFilteredObj,
                                            extNoFilteredObj,
                                            "people"
                                        );
                                        if (!Proceed) return;
                                    }

                                    return (
                                        <Draggable
                                            key={index}
                                            draggableId={`${e._id}`}
                                            index={index}
                                            isDragDisabled={
                                                statesLM.People !== "Parsonal" ? true : false
                                            }
                                        >
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    // onMouseDown={(e) => peopleDragStart(e, index)}
                                                    // onDragEnter={(e) => peopleDragEnter(e, index)}
                                                    // onDragEnd={peopleDrop}
                                                    key={e._id}
                                                    className={`card ${localStorage.getItem("latest-created") ===
                                                        e._id
                                                        ? "latest-created"
                                                        : ""
                                                        }`}
                                                    onClick={(event) => {
                                                        ScrollIntoView();
                                                        if (
                                                            event.target.classList.contains("card") ||
                                                            event.target.classList.contains("card-edit")
                                                        ) {
                                                            setPepole(e, filteredPData, index);


                                                            if (parentPeopleRef.current) {
                                                                const scrollTop =
                                                                    parentPeopleRef.current.scrollTop;
                                                                // console.log("Scroll position:", scrollTop);
                                                                setFocusedRef(scrollTop);
                                                            }
                                                            const scrollTop =
                                                                parentPeopleRef.current.scrollTop;
                                                            setCardRef(prevCardRef =>
                                                                prevCardRef.map(item =>
                                                                    item.type === "people" ? { ...item, val: scrollTop } : item
                                                                )
                                                            );
                                                        }
                                                    }}
                                                >
                                                    {
                                                        /* (e.availability > 0) && */
                                                        workloadToggle === true && (
                                                            <PillComponent
                                                                occupied={aggrigatedPersonFTE(
                                                                    skills,
                                                                    [...roles, ...dRoles],
                                                                    domains,
                                                                    links,
                                                                    e._id
                                                                )}
                                                                total={e.availability}
                                                            />
                                                        )
                                                    }
                                                    {e.workRole === "External" ? (
                                                        <span
                                                            onClick={(e) => {
                                                                toggleEXTnNOCards(
                                                                    "people",
                                                                    "EXT",
                                                                    false,
                                                                    "pill",
                                                                    e
                                                                );
                                                            }}
                                                            className="custom-badge blue ext-owner-pill"
                                                        >
                                                            EXT
                                                        </span>
                                                    ) : null}
                                                    <div className="static-relations">
                                                        {e.tags && e.tags.length > 0
                                                            ? e.tags.map((r, indexs) => (
                                                                <span
                                                                    key={indexs}
                                                                    onClick={() => toggleTagCards(r)}
                                                                    className="custom-badge green tags"
                                                                >
                                                                    {r}
                                                                </span>
                                                            ))
                                                            : null}
                                                    </div>
                                                    <a
                                    /* onClick={() => setPepole(e, filteredPData, index)} */ className="card-edit"
                                                    >{`${e.fname} ${e.lname}`}</a>
                                                    {compareMode.active &&
                                                        compareMode.compareTypes /*&& showCheckBox("people")*/ ? (
                                                        <input
                                                            type="checkbox"
                                                            id={e._id}
                                                            name={`${e.fname} ${e.lname}`}
                                                            onChange={() =>
                                                                handleCheckboxSelection(e._id, "people")
                                                            }
                                                            checked={IsChecked(e._id)}
                                                        />
                                                    ) : (
                                                        <a
                                                            className="card-focus"
                                                            onClick={() =>
                                                                handelFocused({
                                                                    card: "People",
                                                                    index: index,
                                                                    id: e._id,
                                                                })
                                                            }
                                                        >
                                                            <img alt="" src="./images/newicons/focus.svg" />
                                                        </a>
                                                    )}
                                                </div>
                                            )}
                                        </Draggable>
                                    );
                                })
                                : null}

                            {provided.placeholder}

                            <div
                                className="card2"
                                style={
                                    lFromState.Peoples
                                        ? { display: "block" }
                                        : { display: "none" }
                                }
                                draggable={false}
                            >
                                <form
                                    className="form-new-card"
                                    role="form"
                                    onSubmit={peopleSubmit}
                                >
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            ref={pref}
                                            name="card_name"
                                            placeholder="Add first name"
                                            data-mode="Standard mode"
                                            className="form-control"
                                            value={fName}
                                            onChange={handleNameChange}
                                            onKeyPress={pfNameHandel}
                                            autoComplete="off"
                                            required
                                        />
                                    </div>
                                    <div className="form-group" style={{ marginTop: "10px" }}>
                                        <button
                                            type="submit"
                                            className="btn btn-mytpt add-quick close-add-card"
                                        >
                                            Save
                                        </button>
                                        <a
                                            href="#"
                                            type="button"
                                            onClick={() => {
                                                // Added by Debashis on 13-12-2023 (updated 18-12-2023)
                                                let lastname = "";
                                                let firstname = "";
                                                if (fName.trim().indexOf(" ") >= 0) {
                                                    let nameArray = fName.split(" ");
                                                    lastname = nameArray.pop();
                                                    firstname = nameArray.join(" ");
                                                } else {
                                                    lastname = "";
                                                    firstname = fName;
                                                }
                                                setPepoles({ fname: firstname, lname: lastname });
                                                setFName("");
                                                lFromState.Peoples = false;
                                            }}
                                            className="btn btn-link underline open-tool"
                                        >
                                            Edit details
                                        </a>
                                        <a
                                            href="#"
                                            onClick={() => peopleFrom()}
                                            className="btn btn-link underline close-add-card"
                                        >
                                            Close
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                </Droppable>
                <a
                    className="btn-list add-card"
                    onClick={() => peopleFrom()}
                    data-list_id="people"
                    style={
                        !lFromState.Peoples ? { display: "block" } : { display: "none" }
                    }
                >
                    + Add new person
                </a>
            </div>
        </div>
    );
}
