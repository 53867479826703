export const showUpgradeNotification = (dispatch, Swal, myInfo, curUserData, freezeWindow, generateStripeUpgradeUrl) => {
    Swal.fire({
        title: "Want to Unlock This Feature?",
        html: `
            <p style="text-align: center;">This feature is included in a higher plan. Upgrade now to unlock it and get access to additional tools and benefits.</p><br>
            <a style="color: gray; text-align: center; text-decoration: underline;" href="https://www.teamdecoder.com/app-tour" target="_blank" rel="noopener noreferrer">Check out the full feature list</a>
        `,
        showCancelButton: true,
        confirmButtonText: "Upgrade",
        cancelButtonText: "Close",
        cancelButtonColor: "#EE7364",
    }).then((result) => {
        if (result.isConfirmed) {
            freezeWindow();
            dispatch(
                generateStripeUpgradeUrl({
                    userId: curUserData._id,
                    plan: "GO",
                    interval: "month",
                    email: curUserData.email,
                })
            );
        }
    });
};
