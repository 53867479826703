import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Reports from "../../Component/Reports/Reports";
import AllReport from "../../Component/Reports/AllReport";
import PeopleModal from "../../Component/Reports/Modal/PeopleModal";
import DomainModal from "../../Component/Reports/Modal/DomainModal";
import LinkModal from "../../Component/Reports/Modal/LinkModal";
import CircleModal from "../../Component/Reports/Modal/CircleModal";
import ProjectModal from "../../Component/Reports/Modal/ProjectModal";
import SkillModal from "../../Component/Reports/Modal/SkillModal";
import RoleModal from "../../Component/Reports/Modal/RoleModal";
import InfoModal from "../../Component/Reports/Modal/InfoModal";
import SideNav from "../../Component/SideNav/SideNav";

import { reportAuth, reportGetTeam, reportGetPeople, reportGetSkill, reportGetRole, reportGetCircle, reportGetDomain, reportGetLink, reportGetProject, reportGetMeeting, reportGetTeams } from "../../store/actions/reportAction";
import { getNote } from "../../store/actions/noteAction";
import { getInfo } from "../../store/actions/infoAction";
import { getCircle } from "../../store/actions/circleAction";
import { getProject } from "../../store/actions/projectAction";
import { getMember } from "../../store/actions/memberAction";
import { REPORT_ERROR_CLEAR } from "../../store/types/reportType";

import { GetAliasesName } from "../../HelperFunctions/GetAliasesName";

import { StructureDataForPurposeTree } from "../../HelperFunctions/PurposeTreeFunctions";

document.body.style = "";
document.body.classList = "";
const customStyles = {
  content: {
    // top: '50%',
    // left: '50%',
    // right: 'auto',
    // bottom: 'auto',
    // marginRight: '-50%',
    // padding: "0px",
    // transform: 'translate(-50%, -50%)',
    // background: "#FFFF00",
    // boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)"
    maxWidth: "unset",
    // width: "70%"
  },
};

function Person_Report() {
  const { error, reportError, successMessage, reportInfo, reportPlan, reportUserId, reportTeam, reportAllTeams, reportPeople, reportSkill, reportRole, reportDomain, reportLink, reportCircle, reportProject, reportMeeting } = useSelector((state) => state.authReport);

  const { peoples, cuPeopleId, peopleMessagee, peopleError } = useSelector((state) => state.peoples);
  const { skills, cuSkillId, nDaoamins, skillsMessagee, skillError, skillsMessage } = useSelector((state) => state.skills);
  const { roles, cuRoleId, dRoles, roleMessagee, roleError } = useSelector((state) => state.roles);
  const { domains, cuDomainId, dDomains, domainMessage, domainMessagee, domainError } = useSelector((state) => state.domains);
  const { links, cuLinkId, linkMessagee, linkError } = useSelector((state) => state.links);
  const { circles, dCircles, cuCircleId, circleMessagee, circleError } = useSelector((state) => state.circles);
  const { projects, cuProjectId, projectMessagee, projectError } = useSelector((state) => state.projects);

  const { members } = useSelector((state) => state.members);

  const { info } = useSelector((state) => state.infos);
  const { myInfo } = useSelector((state) => state.auth);

  const { secreatKey } = useParams();
  // const { teams} = useSelector((state) => state.teams);

  const dispatch = useDispatch();
  // create by ranjit
  let checkedInitialObject = [
    { name: "people", isChecked: false },
    { name: "skills", isChecked: false },
    { name: "roles", isChecked: false },
    { name: "domain", isChecked: false },
    { name: "links", isChecked: false },
    { name: "circle", isChecked: false },
    { name: "project", isChecked: false },
    { name: "mettings", isChecked: false },
  ];

  const [filterReport, setFilterReport] = useState([]);

  const [showPurposeTree, setShowPurposeTree] = useState(false);

  useEffect(() => {
    if (filterReport && filterReport.length === 0) {
      setFilterReport(checkedInitialObject)
    }
  }, [])

  useEffect(() => {
    if (peoples.length === 0) {
      checkedInitialObject.forEach(item => {
        if (item.name === 'people') {
          item.isChecked = true;
        }
      });
    }

    if (skills.length === 0) {
      checkedInitialObject.forEach(item => {
        if (item.name === 'skills') {
          item.isChecked = true;
        }
      });
    }

    if (roles.length === 0) {
      checkedInitialObject.forEach(item => {
        if (item.name === 'roles') {
          item.isChecked = true;
        }
      });
    }

    if (domains.length === 0) {
      checkedInitialObject.forEach(item => {
        if (item.name === 'domain') {
          item.isChecked = true;
        }
      });
    }

    if (links.length === 0) {
      checkedInitialObject.forEach(item => {
        if (item.name === 'links') {
          item.isChecked = true;
        }
      });
    }

    if (circles.length === 0) {
      checkedInitialObject.forEach(item => {
        if (item.name === 'circle') {
          item.isChecked = true;
        }
      });
    }

    if (projects.length === 0) {
      checkedInitialObject.forEach(item => {
        if (item.name === 'project') {
          item.isChecked = true;
        }
      });
    }
  }, [peoples, skills, roles, domains, links, circles, projects])

  const checkedHandler = (i, e) => {
    const updatedData = [...filterReport];
    updatedData[i].isChecked = e.target.checked;
    setFilterReport(updatedData);
  };

  useEffect(() => {
    if (secreatKey && secreatKey.length > 0) {
      dispatch(reportAuth({ secreatKey: secreatKey }));
      var root = document.getElementsByTagName("html")[0]; // '0' to assign the first (and only `HTML` tag)
      root.setAttribute("class", "");
    }
  }, [secreatKey]);

  useEffect(() => {
    if (reportInfo && reportInfo.teamId && reportUserId.length > 0) {
      // if (localStorage.getItem("teamId") === undefined || localStorage.getItem("teamId") !== "") {
        localStorage.setItem("teamId", reportInfo.teamId); // Used in purpose tree function
      // }

      dispatch(reportGetTeam({ teamId: reportInfo.teamId })); // Current team

      dispatch(reportGetTeams({ userId: reportUserId })); // All teams

      dispatch(getMember({ userId: myInfo.userId, teamId: reportInfo.teamId }));
      dispatch(getInfo({ teamId: reportInfo.teamId }));
      dispatch(getNote({ teamId: reportInfo.teamId }));
    }

    if (reportInfo && reportInfo.teamId && reportInfo.peopleId) {
      dispatch(reportGetPeople({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
      dispatch(reportGetSkill({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
      dispatch(reportGetRole({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
      dispatch(reportGetDomain({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
      dispatch(reportGetLink({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
      dispatch(reportGetCircle({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
      dispatch(reportGetProject({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
      dispatch(reportGetMeeting({ teamId: reportInfo.teamId, peopleId: reportInfo.peopleId }));
    }

    let curcircle = localStorage.getItem("circleSort");
    if (reportInfo && reportInfo.teamId && curcircle && curcircle.length > 0) {
      curcircle = curcircle.split(",");
      if (curcircle) {
        dispatch(getCircle({ userId: myInfo.userId, teamId: reportInfo.teamId, curcircle: curcircle }));
      }
    }

    let curproject = localStorage.getItem("projectSort");
    if (reportInfo && reportInfo.teamId && curproject && curproject.length > 0) {
      curproject = curproject.split(",");
      if (curproject) {
        dispatch(getProject({ userId: myInfo.userId, teamId: reportInfo.teamId, curproject: curproject }));
      }
    }
  }, [reportInfo, reportUserId]);

  const [reportPeopleOpen, setReportPeople] = useState(false);
  const [reportSkillOpen, setReportSkill] = useState(false);
  const [reportRoleOpen, setReportRole] = useState(false);
  const [reportDomainOpen, setReportDomain] = useState(false);
  const [reportLinkOpen, setReportLink] = useState(false);
  const [reportCircleOpen, setReportCircle] = useState(false);
  const [reportProjectOpen, setReportProject] = useState(false);
  const [reportinfoOpen, setReportinfoOpen] = useState(false);
  const [isAllView, setIsAllView] = useState(false);
  const [infoData, setInfoData] = useState({})

  const openReportPeople = () => setReportPeople(true);
  const closReportPeople = () => setReportPeople(false);
  const openReportSkill = () => setReportSkill(true);
  const closReportSkill = () => setReportSkill(false);
  const openReportRole = () => setReportRole(true);
  const closReportRole = () => setReportRole(false);
  const openReportDomain = () => setReportDomain(true);
  const closReportDomain = () => setReportDomain(false);
  const openReportLink = () => setReportLink(true);
  const closReportLink = () => setReportLink(false);
  const openReportCircle = () => setReportCircle(true);
  const closReportCircle = () => setReportCircle(false);
  const openReportProject = () => setReportProject(true);
  const closReportProject = () => setReportProject(false);
  const openInfo = () => setReportinfoOpen(true);
  const closeInfo = () => setReportinfoOpen(false);

  const [data, setData] = useState({});

  // console.log(data)
  // console.log(reportPeopleOpen)
  // console.log(reportPeople);

  //added by ranjit
  const isInfos = (data) => {
    if (data && data.length > 0 && info && info.length > 0) {
      for (let i = 0; i < info.length; i++) {
        if (info[i].infoCard === data) {
          setInfoData(info[i]);
          openInfo();
          break;
        }
      }
    }

    let kblink = "";
    switch (data) {
      case "People":
        kblink = "https://teamdecoder.tawk.help/article/people-tool";
        break;

      case "Skills":
        kblink = "https://teamdecoder.tawk.help/article/skills-tool";
        break;

      case "Roles":
        kblink = "https://teamdecoder.tawk.help/article/roles";
        break;

      case "Domains":
        kblink = "https://teamdecoder.tawk.help/article/domains";
        break;

      case "Links":
        kblink = "https://teamdecoder.tawk.help/article/links";
        break;

      case "Circles":
        kblink = "https://teamdecoder.tawk.help/article/circles-projects";
        break;

      case "Projects":
        kblink = "https://teamdecoder.tawk.help/article/circles-projects";
        break;
    }

    // window.location.href = kblink;
    window.open(kblink, "_blank");
  };

  //added by ranjit
  let peopleName = [];
  if (reportPeople && reportPeople.length > 0) {
    peopleName = reportPeople.filter((e) => {
      return e._id.toString() === reportInfo.peopleId;
    });
  }

  const SwitchView = (e) => {
    // console.log(e.target.checked);
    setIsAllView(e.target.checked);
    // console.log(checkedInitialObject)
    setFilterReport(checkedInitialObject)
  };

  // console.log("person report", isAllView);
  useEffect(() => {
    if (reportError) {
      Swal.fire({
        title: "Error",
        showClass: {
          popup: "animate_animated animate_fadeInDown",
        },
        hideClass: {
          popup: "animate_animated animate_fadeOutUp",
        },
        text: reportError,
      });
      dispatch({ type: REPORT_ERROR_CLEAR });
    }
  }, [reportError])
  //

  /* Purpose Tree (Read-only) */
  let [treeData, setTreeData] = useState([]);
  const options = {
    allowHtml: true,
  };

  /* Data formatting set data in purpose tree format */
  useEffect(() => {
    document.body.classList.add(`purpose-tree`);

    if (reportAllTeams && reportAllTeams.length > 0 && dCircles && dCircles.length > 0 && members && members.length > 0) {
      // console.log({ reportTeam, reportAllTeams });
      let data = StructureDataForPurposeTree(
        reportAllTeams,
        dCircles[0],
        circles,
        projects,
        skills,
        [...roles, ...dRoles],
        domains,
        links,
        members
      );
      data.unshift(["Name", "Manager", "ToolTip"]);
      // console.log({ data });
      setTreeData(data);
    }
  }, [reportAllTeams, circles, dCircles, projects, skills, roles, dRoles, domains, links, members]);

  // console.log(reportPlan);

  return (
    <div id="capture">
      <div className="app">
        {/* <!-- Main Header --> */}
        <header className="app-header">
          <div className="left">
            <div className="logo" style={{ marginTop: "8px" }}>
              <a href="">
                <img
                  // src="../images/new-logo-nobg-sm.png"
                  src="../../images/new-logo-sm-td.png"
                  className="teamdecoder"
                />
              </a>
            </div>

            <div className="dropdown">
              <div className="btn btn-mytpt" style={{ minWidth: '40px', minHeight: '32px' }}>{reportInfo.companyName}</div>
            </div>

            <div className="dropdown">
              <div className="btn btn-mytpt" style={{ minWidth: '40px', minHeight: '32px' }}>{reportTeam.team_name}</div>
            </div>

            {/*added by ranjit*/}
            {/* !isAllView &&  */peopleName && peopleName.length > 0 ? (
              <div className="dropdown">
                <div
                  className="btn btn-mytpt-red"
                  // onClick={() => peopleSetReport(peopleName[0])}
                  style={{ minWidth: '40px', minHeight: '32px' }}
                >
                  {peopleName[0].fname} {peopleName[0].lname}
                </div>
              </div>
            ) : null}

            <div className="dropdown">
              <div
                className="btn btn-mytpt list-btn dropdown-toggle"
                data-toggle="dropdown"
              >
                Lists
                <i style={{ marginLeft: "10px" }} className="fa fa-caret-down"></i>
              </div>
              <ul
                className="dropdown-menu checkbox-menu allow-focus"
                id="list-menu"
                role="menu"
              >
                <li className="dropdown-header">Select to hide list(s)</li>
                <li>
                  <label>
                    <input
                      className="list_name"
                      type="checkbox"
                      checked={filterReport[0] && filterReport[0].isChecked ? true : false}
                      id={0}
                      onChange={(e) => {
                        checkedHandler(0, e);
                      }}
                    />{" "}
                    People
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      className="list_name"
                      type="checkbox"
                      checked={filterReport[1] && filterReport[1].isChecked ? true : false}
                      id="skills"
                      onChange={(e) => {
                        checkedHandler(1, e);
                      }}
                    />{" "}
                    {/* Skills */}{GetAliasesName([reportTeam], "Skills")}
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      className="list_name"
                      type="checkbox"
                      id="roles"
                      checked={filterReport[2] && filterReport[2].isChecked ? true : false}
                      onChange={(e) => {
                        checkedHandler(2, e);
                      }}
                    />{" "}
                    {/* Roles */} {GetAliasesName([reportTeam], "Roles")}
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      className="list_name"
                      type="checkbox"
                      id="domain"
                      checked={filterReport[3] && filterReport[3].isChecked ? true : false}
                      onChange={(e) => {
                        checkedHandler(3, e);
                      }}
                    />{" "}
                    {/* Domains */}{GetAliasesName([reportTeam], "Domains")}
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      className="list_name"
                      type="checkbox"
                      id="links"
                      checked={filterReport[4] && filterReport[4].isChecked ? true : false}
                      onChange={(e) => {
                        checkedHandler(4, e);
                      }}
                    />{" "}
                    {/* Links */} {GetAliasesName([reportTeam], "Links")}
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      className="list_name"
                      type="checkbox"
                      id="circles"
                      checked={filterReport[5] && filterReport[5].isChecked ? true : false}
                      onChange={(e) => {
                        checkedHandler(5, e);
                      }}
                    />{" "}
                    {/* Circles */} {GetAliasesName([reportTeam], "Circles")}
                  </label>
                </li>
                <li>
                  <label>
                    <input
                      className="list_name"
                      type="checkbox"
                      checked={filterReport[6] && filterReport[6].isChecked ? true : false}
                      id="projects"
                      onChange={(e) => {
                        checkedHandler(6, e);
                      }}
                    />{" "}
                    {/* Projects */}{GetAliasesName([reportTeam], "Projects")}
                  </label>
                </li>
              </ul>
            </div>

            <div className="dropdown"
              style={{ padding: "0 5px", border: "1px solid #000", "borderRadius": "5px", height: "32px", "fontSize": "14px" }}>
              Mine
              <label className="switch">
                <input
                  name="view"
                  className="view"
                  type="checkbox"
                  checked={isAllView}
                  onChange={(e) => SwitchView(e)}
                />
                <span className="slider"></span>
              </label>All
            </div>
          </div>
          <div className="center"></div>
          <div className="right"></div>
        </header>

        <SideNav report={true} showPurposeTree={showPurposeTree} setShowPurposeTree={setShowPurposeTree} reportPlan={reportPlan} />

        {/* <!-- App Board --> */}
        <main className="app-board">
          {!showPurposeTree && isAllView &&
            <AllReport
              setData={setData}
              openReportPeople={openReportPeople}
              closReportPeople={closReportPeople}
              openReportSkill={openReportSkill}
              closReportSkill={closReportSkill}
              openReportRole={openReportRole}
              closReportRole={closReportRole}
              openReportDomain={openReportDomain}
              closReportDomain={closReportDomain}
              openReportLink={openReportLink}
              closReportLink={closReportLink}
              openReportCircle={openReportCircle}
              closReportCircle={closReportCircle}
              openReportProject={openReportProject}
              closReportProject={closReportProject}
              filterReport={filterReport} // added by ranjit
              setFilterReport={setFilterReport} // added by ranjit
              openInfo={openInfo} // added by ranjit
              closeInfo={closeInfo} // added by ranjit
              isInfos={isInfos} // added by ranjit
              teams={[reportTeam]}
            />
          }

          {!showPurposeTree && !isAllView &&
            <Reports
              setData={setData}
              openReportPeople={openReportPeople}
              closReportPeople={closReportPeople}
              openReportSkill={openReportSkill}
              closReportSkill={closReportSkill}
              openReportRole={openReportRole}
              closReportRole={closReportRole}
              openReportDomain={openReportDomain}
              closReportDomain={closReportDomain}
              openReportLink={openReportLink}
              closReportLink={closReportLink}
              openReportCircle={openReportCircle}
              closReportCircle={closReportCircle}
              openReportProject={openReportProject}
              closReportProject={closReportProject}
              filterReport={filterReport} // added by ranjit
              setFilterReport={setFilterReport} // added by ranjit
              openInfo={openInfo} // added by ranjit
              closeInfo={closeInfo} // added by ranjit
              isInfos={isInfos} // added by ranjit
              teams={[reportTeam]}
            />
          }

          {showPurposeTree &&
            <section className="board-body">
              <Chart
                chartType="OrgChart"
                data={treeData}
                options={options}
                width="100%"
                height="100%"
                // chartEvents={chartEvents}
                style={{
                  clickEvent: null,
                  pointerEvents: null
                }}
              />
            </section>}
        </main>
      </div>

      <Modal
        isOpen={reportPeopleOpen}
        onRequestClose={closReportPeople}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
      >
        <PeopleModal data={data} closePeople={closReportPeople} teams={[reportTeam]} />
      </Modal>

      <Modal
        isOpen={reportSkillOpen}
        onRequestClose={closReportSkill}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
      >
        <SkillModal data={data} closeSkill={closReportSkill} teams={[reportTeam]} />
      </Modal>

      <Modal
        isOpen={reportinfoOpen}
        onRequestClose={closeInfo}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
      >
        <InfoModal closeInfo={closeInfo} infoData={infoData} teams={[reportTeam]} />
      </Modal>

      <Modal
        isOpen={reportRoleOpen}
        onRequestClose={closReportRole}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
      >
        <RoleModal data={data} closeRole={closReportRole} teams={[reportTeam]} />
      </Modal>

      <Modal
        isOpen={reportDomainOpen}
        onRequestClose={closReportDomain}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
      >
        <DomainModal data={data} closeDomain={closReportDomain} teams={[reportTeam]} />
      </Modal>

      <Modal
        isOpen={reportLinkOpen}
        onRequestClose={closReportLink}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
      >
        <LinkModal data={data} closeLink={closReportLink} teams={[reportTeam]} />
      </Modal>

      <Modal
        isOpen={reportCircleOpen}
        onRequestClose={closReportCircle}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
      >

        <CircleModal data={data} closeCircle={closReportCircle} isAllView={isAllView} teams={[reportTeam]} />
      </Modal>

      <Modal
        isOpen={reportProjectOpen}
        onRequestClose={closReportProject}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        style={customStyles}
        isAllView={isAllView}
      >
        <ProjectModal data={data} closeProject={closReportProject} isAllView={isAllView} teams={[reportTeam]} />
      </Modal>
    </div>
  );
}

export default Person_Report;