import { GetAliasesName } from "./GetAliasesName";

let makeArrAndPush = (arr, type, id, color, purpose, parent, name) => {
    let arrElement = [
        {
            'v': `${arr.length}_${type}_${id}`,
            'f': `<div class="${color}"><h3>${name}</h3><div class="purpose-div">${purpose}</div></div>`
        },
        `${parent || ""}`,
        `${name}`
    ];
    arr.push(arrElement);
    return arr;
}

let FindAndMakeDataSet = (arr, circles, projects, skills, roles, domains, links, members, teamId, teams) => {
    // console.log({arr, circles, projects, skills, roles, domain, link, members, teamId})

    circles.forEach((sc) => {
        let head = `0_Team_${teamId}`;
        // console.log("******** new circle *********", { circleName: sc.circleName, head });
        /* for each circle */
        makeArrAndPush(arr, `circle`, sc._id, `green`, sc.purpose, head, `${sc.circleName === "Team Circle" ? "Entire Team": sc.circleName} (${GetAliasesName(teams, "Circles")})`);

        /* for member project */
        let targetedMembers = members.filter((sm) => sm.memberType === sc._id);
        head = `${arr.length - 1}_circle_${sc._id}`;

        targetedMembers.forEach((stm) => {
            let targetedProjects = stm.coreMembers.Projects;

            /* Project */
            targetedProjects.forEach((spm) => {
                let targetedProjectData = projects.find((spr) => spr._id === spm);
                // console.log({ head });
                // console.log("Project--->", targetedProjectData.projectName, targetedProjectData._id);
                // console.log({p:teamId, c:sc._id, cName:sc.circleName, t:"project",h:head,p:targetedProjectData.projectName});
                if(targetedProjectData && Object.keys(targetedProjectData).length > 0) {
                makeArrAndPush(arr, `project`, spm, `green`, targetedProjectData.purpose, head, `${targetedProjectData.projectName} (${GetAliasesName(teams, "Projects")})`);
                head = `${arr.length - 1}_project_${spm}`;
                }
            })

            /* Skills */
            let targetedSkills = stm.coreMembers.Skills;
            targetedSkills.forEach((s) => {
                let targetedSkillData = skills.find((ss) => ss._id === s);
                // console.log({ head });
                // console.log("Skill--->", targetedSkillData.skillName, targetedSkillData._id);
                // console.log({p:teamId, c:sc._id, t:"skill",h:head,s:targetedSkillData.skillName});
                if(targetedSkillData && Object.keys(targetedSkillData).length > 0) {
                makeArrAndPush(arr, `skill`, s, `blue`, targetedSkillData.purpose, head, `${targetedSkillData.skillName} (${GetAliasesName(teams, "Skills")})`);
                head = `${arr.length - 1}_skill_${s}`;
                }
            })
            /* Role */
            let targetedRoles = stm.coreMembers.Roles;
            targetedRoles.forEach((r) => {
                // console.log(r, roles)
                let targetedRoleData = roles.find((sr) => sr._id === r);
                // console.log({ head });
                // console.log("Role--->", targetedRoleData.roleName, targetedRoleData._id);
                // console.log({p:teamId, c:sc._id,cName:sc.circleName, t:"role",h:head,r:targetedRoleData.roleName});
                if (targetedRoleData && Object.keys(targetedRoleData).length > 0) {
                    makeArrAndPush(arr, `role`, r, `blue`, targetedRoleData.purpose, head, `${targetedRoleData.roleName} (${GetAliasesName(teams, "Roles")})`);
                    head = `${arr.length - 1}_role_${r}`;
                }
            })
            /* Domain */
            let targetedDomains = stm.coreMembers.Domains;
            targetedDomains.forEach((d) => {
                let targetedDomainData = domains.find((sd) => sd._id === d);
                // console.log({ head });
                // console.log("Domain--->", targetedDomainData.domainName, targetedDomainData._id);
                // console.log({p:teamId, c:sc._id,cName:sc.circleName,t:"domain",h:head,d:targetedDomainData.domainName});
                if (targetedDomainData && Object.keys(targetedDomainData).length > 0) {
                    makeArrAndPush(arr, `domain`, d, `blue`, targetedDomainData.purpose, head, `${targetedDomainData.domainName} (${GetAliasesName(teams, "Domains")})`);
                    head = `${arr.length - 1}_domain_${d}`;
                }
            })

            /* Link */
            let targetedLinks = stm.coreMembers.Links;
            targetedLinks.forEach((l) => {
                let targetedLinkData = links.find((sl) => sl._id === l);
                //   console.log({ head });
                //   console.log("Link--->", targetedLinkData.linkName, targetedLinkData._id);
                // console.log({p:teamId, c:sc._id, cName:sc.circleName,t:"link",h:head,l:targetedLinkData.linkName});
                if (targetedLinkData && Object.keys(targetedLinkData).length > 0) {
                    makeArrAndPush(arr, `link`, l, `blue`, targetedLinkData.purpose, head, `${targetedLinkData.linkName} (${GetAliasesName(teams, "Links")})`);
                    head = `${arr.length - 1}_link_${l}`;
                }
            })

            head = "";

        })


    })
};

export const StructureDataForPurposeTree = (teams, dCircles, circles, projects, skills, roles, domains, links, members) => {
    let teamData = teams ? teams.find((team) => team._id === localStorage.getItem("teamId")) : {};
    let arr = [];
    if (Object.keys(teamData).length === 0) {
        console.log("Opps! Something went wrong. Team not found")
    };
    /*team - top level */
    makeArrAndPush(arr, `Team`, /* teamData._id */ dCircles._id, `yellow`, /* teamData.purpose */dCircles.purpose, ``, teamData.team_name);
    FindAndMakeDataSet(arr, circles, projects, skills, roles, domains, links, members, /* teamData._id */dCircles._id, teams);
    return arr;
}