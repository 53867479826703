import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import PillComponent from "../../Component/Body/PillComponent";
import {
  aggrigatedPersonFTE,
  sumFTEBody_Focus,
} from "../../HelperFunctions/FTECalculation";

const RenderItem = ({
  peopleData,
  item,
  itemType,
  peopleId,
  index,
  workloadToggle,
  skills,
  roles,
  domains,
  links,
  handleModalOpen,
}) => {

  let [owners,setOwners] = useState([]);

  useEffect(()=>{
    if(itemType === "skill" || itemType === "role"){
      setOwners(item.owners);
    }else if(itemType === "domain"){
      let ownerData = [item.owners.owner];
      setOwners(ownerData);
    }else if(itemType === "link"){
      let ownerData = [item.owner];
      setOwners(ownerData);
    }

  },[itemType,item]);

  return (
    <>
      {
        owners.includes(peopleId) && (
          <Draggable
            key={item._id}
            draggableId={`people-${peopleId}-${itemType}-id-${item._id}-index-${index}`}
            index={index}
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className="card"
                onClick={() => handleModalOpen(item)}
              >
                <span key={index} className="custom-badge lightgray ">
                  {itemType.toUpperCase()}
                </span>
                {workloadToggle === true && (
                  <PillComponent
                    occupied={sumFTEBody_Focus(item.workload, peopleData._id)}
                    total={item.planTotal}
                    type="TEXT"
                  />
                )}
                {item.workRole === "External" ? (
                  <span className="custom-badge blue ext-owner-pill">EXT</span>
                ) : null}
                <div className="static-relations">
                  {item.tags && item.tags.length > 0
                    ? item.tags.map((tag, indexs) => (
                      <span key={indexs} className="custom-badge green tags">
                        {tag}
                      </span>
                    ))
                    : null}
                </div>
                <a className="card-edit">{item[`${itemType}Name`]}</a>
              </div>
            )}
          </Draggable>
        )
      }

    </>

  );
};

export default RenderItem;

