import React from "react";
import { numberInputOnWheelPreventChange } from "../../../HelperFunctions/StopInputScroll";

const WorkLoadSRDL = ({ handleChange, planTotal, status }) => {
  const colorCode = (planTotal, status) => {
    if (planTotal > status) {
      return { backgroundColor: "#f2de9e", color: "#bea252" };
    } else if (planTotal == status) {
      return { backgroundColor: "#9de7d3", color: "#498287" };
    } else if (planTotal < status) {
      return { backgroundColor: "#f0a69e", color: "#c86053" };
    }
  };
  return (
    <>
      <h4>Workload</h4>
      <div className="row workload-people">
        <div className="col-xs-9">
          <h4>Plan Total</h4>
          <p>FTE</p>
        </div>
        <div className="col-xs-3" style={{ paddingTop: "20px" }}>
          <div className="form-group">
            <input
              type="number"
              name="planTotal"
              readOnly={false}
              value={ !isNaN(planTotal) ? planTotal : ""}
              placeholder={"Type Value"}
              onChange={handleChange || (() => {})}
              className="form-control logit"
              autoComplete="off"
              step="any"
              onWheel={numberInputOnWheelPreventChange}
            />
          </div>
        </div>
      </div>
      <div className="row workload-people">
        <div className="col-xs-9">
          <h4>Status</h4>
          <p>FTE </p>
        </div>
        <div className="col-xs-3" style={{ paddingTop: "20px" }}>
          <div className="form-group">
            <input
              type="number"
              name="status"
              readOnly={true}
              value={status}
              className="form-control logit"
              autoComplete="off"
              style={colorCode(planTotal, status)}
              onWheel={numberInputOnWheelPreventChange}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkLoadSRDL;
