import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Navbar from "../../Component/Header/Navbar";
import Footer from '../../Component/Footer/Footer';
export default function LegalNotice() {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <div
        className="container vertical-center"
        style={{ "min-height": "calc(100vh - 100px)" }}
      >
        <div className="row" style={{ width: "100%" }}>
          <div className="col-lg-12 col-sm-12 col-12 np-padding text-center">
            <div className="col-lg-12 col-sm-12 col-12 np-padding text-center">
              <div
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                  paddingBottom: "15px",
                }}
              >
                <h2 style={{ textAlign: "left" }}></h2>
                <h2 style={{ textAlign: "left" }}>Legal Notice</h2>
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">
                    Service provider according to the German Telemedia Act
                    (TMG):
                  </span>
                </p>
              </div>
              <div
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                  paddingBottom: "15px",
                }}
              >
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">
                    <strong>teamdecoder</strong> is an offer by:
                  </span>
                  <strong>
                    <span className="C9DxTc ">
                      <br />
                      teamdecoder
                    </span>
                  </strong>
                  <span className="C9DxTc ">&nbsp;GmbH</span>
                </p>
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">
                    Leipziger Straße 48, 10117 Berlin
                  </span>
                </p>
              </div>
              <div
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                  paddingBottom: "15px",
                }}
              >
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">
                    {/* Email address:{" "} */}
                    <a href="mailto:helpdesk@teamdecoder.com">
                      helpdesk@teamdecoder.com
                    </a>
                  </span>
                </p>
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <a
                    className="XqQF9c"
                    href="http://contact.teamdecoder.com/"
                    target="_blank"
                    rel="noopener nofollow"
                  >
                    <span className="C9DxTc aw5Odc ">Contact form</span>
                  </a>
                </p>
              </div>
              <div
                style={{
                  maxWidth: "500px",
                  margin: "auto",
                  paddingBottom: "15px",
                }}
              >
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">
                    Represented by: Kai Platschke (CEO)
                  </span>
                </p>
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">
                    Registry Court: Amtsgericht Charlottenburg
                  </span>
                </p>
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">Registry number: HRB 250937 B</span>
                </p>
                <p
                  className="zfr3Q CDt4Ke "
                  dir="ltr"
                  style={{ textAlign: "left" }}
                >
                  <span className="C9DxTc ">VAT-ID: DE359773263</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
