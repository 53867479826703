import React, { useState, useEffect, useRef } from "react";
import Tags from "../Tags";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useDispatch, useSelector } from "react-redux";
import { TagsInput } from "react-tag-input-component";
import TextareaAutosize from "react-textarea-autosize";
// import { getTeam } from '../../../store/actions/teamAction';
import {
  createLink,
  getLink,
  updateLink,
  deleteLink,
} from "../../../store/actions/linkAction";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import { SERVER_URI } from "../../../config/keys";
import {
  LINK_SUCCESS_MESSAGE_CLEAR,
  LINK_ERROR_CLEAR,
  LINK_ID_CLEAR,
} from "../../../store/types/linkType";
import { TAGS_SUCCESS_MESSAGE_CLEAR } from "../../../store/types/tagsType";
import { getTags, tagCreate } from "../../../store/actions/tagsAction";
import { updateState, getState } from "../../../store/actions/stateAction";
import {
  historyGet,
  historyCreate,
} from "../../../store/actions/historyAction";
import {
  HISTORY_MESSAGE_CLEAR,
  HISTORY_DATAS_CLEAR,
} from "../../../store/types/historyType";
import {
  updateMember,
  getMember,
} from "../../../store/actions/memberAction";
import Objectives from "./Objectives";
import Tasks from "./Tasks";
import WorkLoadSRDL from "./WorkLoadSRDL";
import { sumFTE } from "../../../HelperFunctions/FTECalculation";
import { numberInputOnWheelPreventChange } from "../../../HelperFunctions/StopInputScroll";
import Documents from "./Documents";
import { GetAliasesName } from "../../../HelperFunctions/GetAliasesName";
import { IsFadeIn, IsFadeOut } from "../../../HelperFunctions/FadeInOutChecker";

export default function LinkModel({
  data,
  closeLink,
  CloseState,
  stateCloseState,
  setLinks,
  isNToP,
  focusMode,
  setFocused,
  compareMode,
  teams,
  setPurposetreeLoader
}) {
  const animatedComponents = makeAnimated();
  let dispatch = useDispatch();

  const { loading, successMessage, error, authenticate, myInfo } = useSelector(
    (state) => state.auth
  );
  const { peoples, peopleMessagee, peopleError } = useSelector(
    (state) => state.peoples
  );
  const { domains, domainMessage, domainError } = useSelector(
    (state) => state.domains
  );
  const { links, cuLinkId, linkMessage, linkMessagee, linkError } = useSelector(
    (state) => state.links
  );
  const { members, memberMessagee, memberError, memberMessage } = useSelector(
    (state) => state.members
  );
  const { circles, circleMessage, circleError } = useSelector(
    (state) => state.circles
  );
  const { projects, projectMessagee, projectError } = useSelector(
    (state) => state.projects
  );
  const { states, cuStateId, statesMessagee, statesError, statesMessage } =
    useSelector((state) => state.states);
  const { historys, historyMessagee, historyError, historyMessage } =
    useSelector((state) => state.historys);

  let adminId = myInfo.userId;
  let teamId = localStorage.getItem("teamId");
  let url = `${SERVER_URI}/user/link/create`;
  let urls = `${SERVER_URI}/user/link/update`;

  // Set Current Link Data for Members Data

  let memberIds = [];
  if (data) {
    members.map((e, index) => {
      let inc = e.coreMembers.Links.includes(data._id);
      let incs = e.extendedMembers.Links.includes(data._id);
      if (inc) {
        memberIds.push({ memberId: e.memberType, XTD: "" });
      }
      if (incs) {
        memberIds.push({ memberId: e.memberType, XTD: "XTD" });
      }
    });
  }

  if (data && memberIds.length > 0) {
    for (let i = 0; i < memberIds.length; i++) {
      for (let j = 0; j < circles.length; j++) {
        if (memberIds[i].memberId === circles[j]._id) {
          memberIds[i].memberName = circles[j].circleName + " " + `(${GetAliasesName(teams, "Circles").toLowerCase()})`/* "(circle)" */;
        }
      }
      for (let k = 0; k < projects.length; k++) {
        if (memberIds[i].memberId === projects[k]._id) {
          memberIds[i].memberName = projects[k].projectName + " " + `(${GetAliasesName(teams, "Projects").toLowerCase()})`/* "(project)" */;
        }
      }
    }
  }

  // Member S

  const cFskillSub = (data, popoverId, type) => {
    if (
      type === "core" &&
      popoverId &&
      popoverId.coreMembers &&
      popoverId.coreMembers.Links
    ) {
      let inc = popoverId.coreMembers.Links.includes(data._id);
      if (inc) {
        popoverId.coreMembers.Links = popoverId.coreMembers.Links.filter(
          (e) => {
            return e !== data._id;
          }
        );
      }
    } else if (
      type === "extend" &&
      popoverId &&
      popoverId.extendedMembers &&
      popoverId.extendedMembers.Links
    ) {
      let incs = popoverId.extendedMembers.Links.includes(data._id);
      if (incs) {
        popoverId.extendedMembers.Links =
          popoverId.extendedMembers.Links.filter((e) => {
            return e !== data._id;
          });
      }
    }
    if (popoverId) {
      popoverId.adminId = myInfo.userId;
      popoverId.url = `${SERVER_URI}/user/member/update`;
      // console.log(popoverId);
      let obj = {};
      obj.userId = myInfo.userId;
      obj.teamId = popoverId.teamId;
      dispatch(updateMember(popoverId)).then(() => {
        // dispatch(getMember(obj));
      });
    }
  };

  const memberHandel = (elements) => {
    let popoverId = {};
    if (elements && elements.memberId.length > 0) {
      for (let i = 0; i < members.length; i++) {
        if (members[i].memberType === elements.memberId) {
          let arr = {
            teamId: members[i].teamId,
            memberType: members[i].memberType,
            coreMembers: {
              People: members[i].coreMembers.People,
              Skills: members[i].coreMembers.Skills,
              Roles: members[i].coreMembers.Roles,
              Links: members[i].coreMembers.Links,
              Domains: members[i].coreMembers.Domains,
              Circles: members[i].coreMembers.Circles,
              Projects: members[i].coreMembers.Projects,
            },
            extendedMembers: {
              People: members[i].extendedMembers.People,
              Skills: members[i].extendedMembers.Skills,
              Roles: members[i].extendedMembers.Roles,
              Links: members[i].extendedMembers.Links,
              Domains: members[i].extendedMembers.Domains,
              Circles: members[i].extendedMembers.Circles,
            },
          };
          if (arr && arr.teamId.length > 0) {
            // setPopoverId(arr)
            popoverId = arr;
          }
          break;
        }
      }
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD === "XTD"
    ) {
      cFskillSub(link, popoverId, "extend");
    }
    if (
      popoverId &&
      popoverId.memberType &&
      popoverId.memberType.length > 0 &&
      elements.XTD !== "XTD"
    ) {
      cFskillSub(link, popoverId, "core");
    }
  };

  let arr = {
    _id: data && data._id ? data._id : null,
    teamId: data && data.teamId ? data.teamId : null,
    linkName: data && data.linkName ? data.linkName : "",
    purpose: data && data.purpose ? data.purpose : "",
    tasks: data && data.tasks ? data.tasks : [],
    objectives: data && data.objectives ? data.objectives : [],
    owner: data && data.owner ? data.owner : null,
    standIn: data && data.standIn ? data.standIn : null,
    memberIn: data && data.memberIn ? data.memberIn : [],
    tags: data && data.tags ? data.tags : [],
    newTag: [],
    adminId: adminId ? adminId : null,
    url: urls ? urls : "",
    teamId: teamId ? teamId : null,
    planTotal: data && data.planTotal ? data.planTotal : "",
    workload: data && data.workload ? data.workload : [],
    notes: data && data.notes ? data.notes : "",
  };

  const [link, setLink] = useState(arr);

  const handleChange = (e) => {
    // if (e.target.name  === "planTotal") {
    //   setLink({
    //     ...link,
    //     [e.target.name]: e.target.value === "" ? 0 : e.target.value < 0 ? 0 : e.target.value,
    //   });
    // }else{
    setLink({
      ...link,
      [e.target.name]: e.target.value,
    });
    // }
  };

  //added by ranjit
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default Enter behavior
    }
  };

  const centeredPlaceholderStyle = {
    lineHeight: "3rem",
    height: "2rem",
    padding: "0",
  };
  //

  //options skills
  var Data = [];
  peoples.map((e) => {
    Data.push({ value: `${e._id}`, label: `${e.fname} ${e.lname}` });
  });

  const options = Data;

  let taskData = [];
  if (link.tasks.length > 0) {
    link.tasks.map((e) => {
      taskData.push({ tasks: e });
    });
    taskData.push({ tasks: "" });
  }

  if (taskData.length === 0) {
    taskData.push({ tasks: "" });
  }

  const [formValues, setFormValues] = useState(taskData);

  let handleForm = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    let len = formValues.length;
    if (len > 0) {
      if (formValues[len - 1].tasks.length > 0) {
        setFormValues([...formValues, { tasks: "" }]);
      }
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const [isClicking, setIsCliking] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addFormFields(e);
      setIsCliking(true);
    }
    // e.preventDefault()
  };

  useEffect(() => {
    const textarea = document.getElementById(`task${formValues.length - 1}`);
    if (textarea && isClicking) {
      textarea.focus();
      setIsCliking(false);
    }
  }, [formValues, setFormValues]);

  let newTasks = [];
  if (formValues.length > 0) {
    formValues.map((e) => {
      if (e.tasks.length > 0) {
        newTasks.push(e.tasks);
      }
    });
  }

  link.tasks = newTasks;

  let cuOwner = {};
  if (arr.owner) {
    for (let i = 0; i < peoples.length; i++) {
      let inc = [data.owner].includes(peoples[i]._id);
      if (inc) {
        cuOwner = {
          value: `${peoples[i]._id}`,
          label: `${peoples[i].fname} ${peoples[i].lname}`,
        };
        break;
      }
    }
  }

  let cuStandIn = {};
  if (arr.standIn) {
    for (let i = 0; i < peoples.length; i++) {
      let inc = [data.standIn].includes(peoples[i]._id);
      if (inc) {
        cuStandIn = {
          value: `${peoples[i]._id}`,
          label: `${peoples[i].fname} ${peoples[i].lname}`,
        };
        break;
      }
    }
  }

  const [value, setValue] = React.useState(cuOwner || null);
  if (value && value.value) {
    link.owner = value.value;
  }

  const [values, setValues] = React.useState(cuStandIn || null);
  if (values && values.value) {
    link.standIn = values.value;
  }

  //added by indranil
  const handleLinkOwnerChange = (newValue) => {
    // console.log(link)
    if (newValue && Object.keys(newValue).length > 0) {
      setValue(newValue);
      setWorkLoadState([]);
    } else if (link.standIn) {
      Swal.fire({
        title: "Note",
        text: "If you delete the owner the standin will also be deleted!",
        // icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          setValue(null);
          setValues(null);
          setWorkLoadState([]);
        }
      });
    } else {
      setValue(null);
      setValues(null);
      setWorkLoadState([]);
    }
  };

  const handleLinkStandOut = (newValue) => {
    // console.log(newValue, value);
    if (newValue && newValue.value === value.value) {
      Swal.fire({
        title: "Error",
        text: "Link owner and stand-in cannot be the same person!",
        // icon: "warning",
        // showCancelButton: true,
        confirmButtonColor: "#3085d6",
        // cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
    } else if (newValue) {
      setValues(newValue);
    } else {
      setValues(null);
    }
  };

  let curTag = [];
  if (link.newTag && link.newTag.length > 0) {
    for (let i = 0; i < link.newTag.length; i++) {
      curTag.push({ userId: myInfo.userId, tags: [link.newTag[i]] });
    }
  }

  const currentTime = () => {
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    var d = new Date();
    var day = days[d.getDay()];
    var hr = d.getHours();
    var min = d.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "AM";
    if (hr > 12) {
      hr -= 12;
      ampm = "PM";
    }
    var date = d.getDate();
    var month = months[d.getMonth()];
    var year = d.getFullYear();
    let curTime =
      month + " " + date + ", " + year + " " + hr + ":" + min + " " + ampm;
    return curTime;
  };

  let historyState = [];

  if (data && Object.keys(data).length > 0) {
    if (data.linkName || link.linkName) {
      if (data.linkName !== link.linkName) {
        if (data.linkName.length > 0 && link.linkName.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Link Name",
            prev: data.linkName,
            next: link.linkName,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.purpose && link.purpose) {
      if (data.purpose !== link.purpose) {
        if (data.purpose === "" && link.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: link.purpose,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && link.purpose.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: link.purpose,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.purpose.length > 0 && link.purpose === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Purpose",
            prev: data.purpose,
            next: link.purpose,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.tasks || link.tasks) {
      if (data.tasks && link.tasks && data.tasks.join() !== link.tasks.join()) {
        if (
          data.tasks &&
          data.tasks.join() === "" &&
          link.tasks &&
          link.tasks.length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: link.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          link.tasks &&
          link.tasks.join().length > 0
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: link.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (
          data.tasks &&
          data.tasks.join().length > 0 &&
          link.tasks &&
          link.tasks.join() === ""
        ) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tasks",
            prev: data.tasks.join(),
            next: link.tasks.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.owner || link.owner) {
      let oldOwners = "";
      let newOwners = "";
      if (peoples && peoples.length > 0) {
        for (let i = 0; i < peoples.length; i++) {
          if (peoples[i]._id === data.owner) {
            oldOwners = `${peoples[i].fname} ${peoples[i].lname}`;
          }
          if (peoples[i]._id === link.owner) {
            newOwners = `${peoples[i].fname} ${peoples[i].lname}`;
          }
        }
      }
      if (oldOwners !== newOwners) {
        if (oldOwners === "" && newOwners.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldOwners.length > 0 && newOwners.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldOwners.length > 0 && newOwners === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldOwners,
            next: newOwners,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (data.standIn || link.standIn) {
      let oldStandIn = "";
      let newStandIn = "";
      if (peoples && peoples.length > 0) {
        for (let i = 0; i < peoples.length; i++) {
          if (peoples[i]._id === data.standIn) {
            oldStandIn = `${peoples[i].fname} ${peoples[i].lname}`;
          }
          if (peoples[i]._id === link.standIn) {
            newStandIn = `${peoples[i].fname} ${peoples[i].lname}`;
          }
        }
      }
      if (oldStandIn !== newStandIn) {
        if (oldStandIn === "" && newStandIn.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (oldStandIn.length > 0 && newStandIn.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (oldStandIn.length > 0 && newStandIn === "") {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Owner",
            prev: oldStandIn,
            next: newStandIn,
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
    if (
      (data.tags && data.tags.length > 0) ||
      (link.tags && link.tags.length > 0)
    ) {
      if (data.tags && link.tags && data.tags.join() !== link.tags.join()) {
        if (data.tags.join() === "" && link.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: link.tags.join(),
            hcTime: currentTime(),
            cardStatus: "added",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && link.tags.length > 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: link.tags.join(),
            hcTime: currentTime(),
            cardStatus: "changed",
          };
          historyState.push(obj);
        } else if (data.tags.length > 0 && link.tags.length === 0) {
          let obj = {
            cardId: data._id,
            teamId: teamId,
            field: "Tags",
            prev: data.tags.join(),
            next: link.tags.join(),
            hcTime: currentTime(),
            cardStatus: "removed",
          };
          historyState.push(obj);
        }
      }
    }
  }

  const handelUpdate = async () => {
    if (link._id) {
      // console.log("here 1");
      /* Indranil */
      link.owner = value ? value.value : null;
      link.standIn = values ? values.value : null;
      let linkSort = localStorage.getItem("linkSort");
      /*--------------------------- */
      if (
        link.workload &&
        link.workload.length > 0 &&
        link.workload[0] === null
      ) {
        link.workload = null;
      }
      /*----------------------------- */
      dispatch(updateLink({ ...link, linkSort })).then(() => {
        dispatch(historyCreate(historyState));
      });
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
    return true;
  };

  const handlePreviosNext = async (data) => {
    dispatch({ type: HISTORY_DATAS_CLEAR });
    let curUpdateData = [];
    if (links && links.length > 0 && isNToP && isNToP.length > 0) {
      for (let i = 0; i < isNToP.length; i++) {
        for (let j = 0; j < links.length; j++) {
          if (links[j]._id === isNToP[i]._id) {
            curUpdateData.push(links[j]);
            break;
          }
        }
      }
    }
    if (data === "prev") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (curUpdateData[i]._id === link._id && i >= 0) {
            localStorage.setItem("npState", "Link");
            let index = i === 0 ? curUpdateData.length - 1 : i - 1;
            await handelUpdate().then(() => {
              setLinks(curUpdateData[index], curUpdateData, 0, "Link");
            });
          }
        }
      }
    } else if (data === "next") {
      if (curUpdateData && curUpdateData.length > 0) {
        for (let i = 0; i < curUpdateData.length; i++) {
          if (
            curUpdateData[i]._id === link._id &&
            i <= curUpdateData.length - 1
          ) {
            localStorage.setItem("npState", "Link");
            let index = i < curUpdateData.length - 1 ? i + 1 : 0;
            await handelUpdate().then(() => {
              setLinks(curUpdateData[index], curUpdateData, 0, "Link");
            });
          }
        }
      }
    }
  };

  const linkCopy = (e) => {
    e.preventDefault();
    dispatch({ type: HISTORY_DATAS_CLEAR });
    setLink({
      ...link,
      _id: "",
      linkName: link.linkName + "(Copy)",
    });
    // dispatch(createLink(datas))
  };

  const [documents, setDocument] = useState();
  const linksSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("npState", "");
    //update
    let linkSort = localStorage.getItem("linkSort");
    link.documents = documents;
    if (link._id) {
      /* Indranil */
      link.owner = value ? value.value : null;
      link.standIn = values ? values.value : null;
      /*--------------------------- */
      if (
        link.workload &&
        link.workload.length > 0 &&
        link.workload[0] === null
      ) {
        link.workload = null;
      }
      /*----------------------------- */
      // console.log("link data 1st", link);
      dispatch(updateLink({ ...link, linkSort })).then(() => {
        dispatch(historyCreate(historyState));
      });
    } else {
      // console.log("here 2.1 -- create");

      //create
      dispatch(createLink({ ...link, linkSort }));
      // console.log(domain)
    }
    dispatch({ type: HISTORY_DATAS_CLEAR });
  };

  useEffect(() => {
    if (CloseState && CloseState === "Links") {
      stateCloseState("");
      //update
      localStorage.setItem("npState", "");
      //update
      let linkSort = localStorage.getItem("linkSort");
      if (link._id) {
        /* Indranil */
        link.owner = value ? value.value : null;
        link.standIn = values ? values.value : null;
        /*--------------------------- */
        if (
          link.workload &&
          link.workload.length > 0 &&
          link.workload[0] === null
        ) {
          link.workload = null;
        }
        /*----------------------------- */

        link.documents = documents;

        dispatch(updateLink({ ...link, linkSort })).then(() => {
          dispatch(historyCreate(historyState));
        });
      } else {
        //create
        link.documents = documents;
        dispatch(createLink({ ...link, linkSort }));
        // console.log(domain)
      }
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  }, [CloseState, stateCloseState]);

  const linksDelete = (e) => {
    // e.preventDefault();
    // Swal.fire({
    //   title: "Are you sure?",
    //   text: "You can still restore this from trash!",
    //   // icon: "warning",
    //   showCancelButton: true,
    //   confirmButtonColor: "#3085d6",
    //   cancelButtonColor: "#d33",
    //   confirmButtonText: "Yes, delete it!",
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     let obj = {};
    //     obj.teamId = localStorage.getItem("teamId");
    //     obj.adminId = link.adminId;
    //     obj.url = `${SERVER_URI}/user/person/delete`;
    //     obj.linkId = link._id;
    //     obj.softdelete = true;
    //     let objs = {};
    //     objs.teamId = localStorage.getItem("teamId");
    //     objs.userId = myInfo.userId;
    //     let cuLinks = states.Links.filter((e) => {
    //       return e !== obj.linkId;
    //     });
    //     // console.log(objs)
    //     dispatch(deleteLink(obj)).then(() => {
    //       dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
    //       dispatch({ type: LINK_ID_CLEAR });
    //       dispatch({ type: HISTORY_DATAS_CLEAR });
    //     });
    //     // closePeople()
    //   }
    // });
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You can choose to archive or delete this.",
      showDenyButton: true, // Enables a second button for archiving
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#ffc107", // Yellow color for the Archive button
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      denyButtonText: "Archive",
    }).then((result) => {
      let obj = {
        teamId: localStorage.getItem("teamId"),
        adminId: link.adminId,
        linkId: link._id,
      };

      let objs = {
        teamId: localStorage.getItem("teamId"),
        userId: myInfo.userId,
      };

      let cuLinks = states.Links.filter((e) => e !== obj.linkId);

      if (result.isConfirmed) {
        // Action for delete
        obj.url = `${SERVER_URI}/user/person/delete`;

        dispatch(deleteLink(obj)).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: LINK_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
        });
      } else if (result.isDenied) {
        // Action for archive
        obj.url = `${SERVER_URI}/user/person/delete`;
        obj.softdelete = true;

        dispatch(deleteLink(obj)).then(() => {
          dispatch(getState({ userId: myInfo.userId, teamId: obj.teamId }));
          dispatch({ type: LINK_ID_CLEAR });
          dispatch({ type: HISTORY_DATAS_CLEAR });
        });
      }
    });

  };

  // console.log("outside ue", linkMessagee);

  useEffect(() => {
    let obj = {};
    obj.teamId = localStorage.getItem("teamId");
    obj.userId = myInfo.userId;

    if (linkMessagee && linkMessagee.length > 0) {
      dispatch(getState(obj));
      dispatch({ type: LINK_ID_CLEAR });
      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });

      dispatch({ type: LINK_SUCCESS_MESSAGE_CLEAR });
      let curState = localStorage.getItem("npState");
      if (curState !== "Link") {
        closeLink();
      }
    }

    if (linkError) {
      Swal.fire({
        // icon: "error",
        title: "Error",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        text: linkError,
      });
      // alert.error(peopleError)
      dispatch({ type: LINK_ERROR_CLEAR });
      // dispatch(getDomain(obj));
      // closeLink();
    }
    dispatch(getTags({ userId: myInfo.userId }));
  }, [linkMessagee, linkError, cuLinkId]);

  const historysGet = (data) => {
    if (historys && historys.length === 0) {
      dispatch(historyGet({ cardId: data }));
    } else {
      dispatch({ type: HISTORY_DATAS_CLEAR });
    }
  };

  useEffect(() => {
    if (historyMessage) {
      dispatch({ type: HISTORY_MESSAGE_CLEAR });
    }
  }, [historyMessage && historys]);

  let historysMessage = [];

  if (historys && historys.length > 0) {
    for (let i = 0; i < historys.length; i++) {
      if (historys[i].cardStatus === "created") {
        let str = `<i>${historys[i].hcTime}</i> : Created`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "added") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} added ` +
          " (" +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "changed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} changed from ` +
          `<b>${historys[i].prev}</b>` +
          " to " +
          `<b>${historys[i].next}</b>`;
        historysMessage.push(str);
      } else if (historys[i].cardStatus === "removed") {
        let str =
          `<i>${historys[i].hcTime}</i> :  ${historys[i].field} removed ` +
          " (previously: " +
          `<b>${historys[i].next}</b>` +
          ")";
        historysMessage.push(str);
      }
    }
  }

  const CloseLinkModal = () => {
    setPurposetreeLoader((prev => ({ ...prev, link: false })));
    dispatch({ type: HISTORY_DATAS_CLEAR });
    closeLink();
  };

  const ref = useRef(null);

  const handleClick = () => {
    ref.current.focus();
  };

  //added by ranjit
  const onDragStart = (e, index) => {
    e.dataTransfer.setData("index", index);
  };

  const onDragOver = (e, index) => {
    e.preventDefault();
  };

  const onDrop = (e, index) => {
    const draggedIndex = e.dataTransfer.getData("index");
    const items = [...formValues];
    const draggedItem = items[draggedIndex];
    items.splice(draggedIndex, 1);
    items.splice(index, 0, draggedItem);
    setFormValues(items);
  };

  const CustomOption = ({ innerProps, label, value }) => {
    let flag = false;
    if (peoples && peoples.length > 0) {
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === value && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
    }
    let data = ``;
    if (flag) {
      data += `<span class="custom-badge blue">EXT</span>`;
    }
    data += label;
    return (
      <div {...innerProps} className="tdListItem">
        <span dangerouslySetInnerHTML={{ __html: data }} />
      </div>
    );
  };

  const isExtPerson = (Id) => {
    if (peoples && peoples.length > 0) {
      let flag = false;
      for (let i = 0; i < peoples.length; i++) {
        if (peoples[i]._id === Id && peoples[i].workRole === "External") {
          flag = true;
          break;
        }
      }
      return flag;
    }
  };

  const outerBoxClick = () => {
    if (
      formValues &&
      formValues.length > 0 &&
      formValues[formValues.length - 1].tasks &&
      formValues[formValues.length - 1].tasks.length > 0
    ) {
      addFormFields();
      setIsCliking(true);
    }
  };

  const [workloadState, setWorkLoadState] = useState(link.workload || []);

  const handleWorkloadChange = (sv, inputVal) => {
    if (inputVal > 1) {
      inputVal = 1;
    } else if (inputVal < 0) {
      inputVal = 0;
    }
    // Find the index of the item in workloadState that matches the ownerId (sv.value)
    const existingIndex =
      workloadState &&
      workloadState.findIndex(
        (item) => item && sv && item.ownerId === sv.value
      );

    // Create a new copy of the workloadState array
    let updatedWorkloadState = [...workloadState];

    if (existingIndex !== -1) {
      // If the item already exists, update its plan
      updatedWorkloadState[existingIndex] = {
        ...updatedWorkloadState[existingIndex],
        plan: inputVal,
      };
    } else {
      // If the item does not exist, add a new item
      if (sv && sv.value) {
        updatedWorkloadState.push({
          ownerId: sv.value,
          plan: inputVal,
        });
      } else {
        updatedWorkloadState = null;
      }
    }

    setLink({ ...link, workload: updatedWorkloadState });
    // Update the state with the new workloadState array
    // console.log("****workload******", updatedWorkloadState);
    setWorkLoadState(updatedWorkloadState);
  };

  const HandleSecretDownloadTool = () => {
    console.log(link)
    // Create the content of the file
    const fileContent =
      `Name: ${link.linkName}\nPurpose: ${link.purpose}\nTasks: ${link.tasks.join(',')}\nOwners: ${cuOwner.label}\nTags: ${link.tags.join(',')}`;

    // Create a Blob object from the content with file content and file type
    const blob = new Blob([fileContent], { type: 'text/plain' });

    // Create a URL for the Blob
    const fileUrl = URL.createObjectURL(blob);

    // Create a temporary anchor element
    const link_url = document.createElement('a');
    link_url.href = fileUrl;

    // Set the download attribute with a default file name
    link_url.download = `${link.linkName}_link.txt`;

    // Programmatically click the link to trigger the download
    link_url.click();

    // Clean up the object URL
    URL.revokeObjectURL(fileUrl);
  };

  return (
    <div
      id="linkModal"
      onClick={() => outerBoxClick()}
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <form className="form" onSubmit={(e) => linksSubmit(e)}>
          {/* Modal content*/}
          <div className="modal-content">
            <div className="modal-header header-blue">
              <div className="row ">
                <div className="col-xs-8 text-left">
                  <h4 className="modal-title">
                    <img
                      src="images/icon-link.png"
                      style={{
                        width: "17px",
                        marginTop: "-3px",
                        marginRight: "5px",
                      }}
                      onClick={HandleSecretDownloadTool}
                    />{" "}
                    {/* LINK */}{GetAliasesName(teams, "Links").toUpperCase()}
                  </h4>
                </div>

                {compareMode.active ||
                  (focusMode && focusMode.length > 0) ? null : (
                  <div className="col-xs-4 text-right">
                    <Tippy content={<span>Previous {/* Link */} {GetAliasesName(teams, "Links")}</span>}>
                      <button
                        style={{ marginRight: "10px", cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-prev"
                        onClick={() => handlePreviosNext("prev")}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                    </Tippy>
                    <Tippy content={<span>Next {/* Link */}{GetAliasesName(teams, "Links")}</span>}>
                      <button
                        style={{ cursor: "pointer" }}
                        type="button"
                        className="btn btn-mytpt-gray show-next"
                        onClick={() => handlePreviosNext("next")}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                    </Tippy>
                  </div>
                )}
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <TextareaAutosize
                      type="text"
                      ref={ref}
                      name="linkName"
                      value={link.linkName}
                      onKeyDown={handleKeyDown}
                      onChange={handleChange}
                      className="form-control tool-name logit"
                      placeholder={`+ Add ${GetAliasesName(teams, "Links").toLowerCase()} name`}
                      style={centeredPlaceholderStyle}
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <h4>Purpose</h4>
                    <TextareaAutosize
                      cacheMeasurements
                      type="text"
                      value={link.purpose}
                      onChange={handleChange}
                      className="form-control purpose logit"
                      name="purpose"
                      placeholder="+ Add purpose"
                    />
                  </div>

                  <div className="form-group tasks">
                    <Tasks
                      formValues={formValues}
                      onDragStart={onDragStart}
                      onDragOver={onDragOver}
                      onDrop={onDrop}
                      handleForm={handleForm}
                      handleKeyPress={handleKeyPress}
                      removeFormFields={removeFormFields}
                    />
                  </div>

                  <div className="form-group objectives">
                    <Objectives data={link} link={link} />
                  </div>

                  <WorkLoadSRDL
                    handleChange={handleChange}
                    planTotal={Number(parseFloat(link.planTotal).toString().replace(",", "."))}
                    status={Number(sumFTE(link.workload, [value]).toString().replace(",", "."))}
                  />

                  <div className="form-group">
                    <h4>Notes</h4>
                    <TextareaAutosize
                      className="form-control purpose logit"
                      name="notes"
                      defaultValue=''
                      value={link.notes}
                      onChange={handleChange}
                      placeholder="+ Add notes"
                      maxLength="100"
                      autoFocus={null}
                    />
                  </div>

                </div>

                <div className="col-md-6">
                  <div className="form-group link-owner ss">
                    <div className="row">
                      <div className="col-xs-9" style={{ paddingRight: 0 }}>
                        <h4>Owner</h4>
                        <Select
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="single-select-container"
                          // components={animatedComponents}
                          isClearable
                          isSearchable
                          onChange={(newValue) =>
                            handleLinkOwnerChange(newValue)
                          }
                          // value={cuOwner  && cuOwner.value && cuOwner.value.length > 0 ? [{
                          //   value: cuOwner.value,
                          //   label: (
                          //     <div style={{fontSize: "14px"}}>
                          //       {cuOwner.value && isExtPerson(cuOwner.value) ?
                          //         <span className="custom-badge blue"  >
                          //           EXT
                          //         </span>
                          //         : null}
                          //         {cuOwner.label}
                          //     </div>
                          //   ),
                          // }] : []}

                          // Added by Indranil on 11-12-2023
                          value={
                            value && value.value && value.value.length > 0
                              ? [
                                {
                                  value: value.value,
                                  label: (
                                    <div style={{ fontSize: "14px" }}>
                                      {/* {value.value && isExtPerson(value.value) ? (
                                      <span className="custom-badge blue">
                                        EXT
                                      </span>
                                    ) : null}
                                    {value.label} */}

                                      {value.value &&
                                        isExtPerson(value.value) ? (
                                        <>
                                          <span
                                            className="custom-badge blue"
                                            style={{
                                              display: "inline-block",
                                            }}
                                          >
                                            EXT
                                          </span>{" "}
                                          {value.label}
                                        </>
                                      ) : (
                                        value.label
                                      )}
                                      {/* fade-in and fade-out pills - START */}
                                      {IsFadeIn(value.value, peoples, link._id) && <span
                                        onClick={() => {
                                          // Yet to be developed
                                          // handleFadeInOut();
                                        }}
                                        className="custom-badge fade-in"
                                      >
                                        <img src="/images/fadein.png" />
                                      </span>}

                                      {/* fade-in and fade-out pills - END */}
                                      {IsFadeOut(value.value, peoples, link._id) && <span
                                        onClick={() => {
                                          // Yet to be developed
                                          // handleFadeInOut();
                                        }}
                                        className="custom-badge fade-out"
                                      >
                                        <img src="/images/fadeout.png" />
                                      </span>}
                                    </div>
                                  ),
                                },
                              ]
                              : []
                          }
                          options={options}
                          placeholder="+ Add owner"
                          components={{ Option: CustomOption }}
                        />
                      </div>

                      <div className="col-xs-3">
                        <h4>FTE</h4>
                        <input
                          type="number"
                          // id={`plan-${index}`}
                          className="form-control fte"
                          placeholder="Max: 1"
                          // name={`plan-${index}`}
                          value={
                            workloadState &&
                              workloadState.length > 0 &&
                              workloadState[0]
                              ? workloadState[0].plan
                              : ""
                          } // Bind the input value to planValue
                          step="any"
                          onChange={(e) =>
                            handleWorkloadChange(value, e.target.value)
                          }
                          onWheel={numberInputOnWheelPreventChange}
                          disabled={value && value.value ? false : true}
                        />
                      </div>
                    </div>
                  </div>

                  {value && value.value && value.value.length > 0 ? null : (
                    <div
                      className="form-group flag"
                      style={{ display: "block" }}
                    >
                      <label>
                        Links without owners will be flagged:{" "}
                        <span className="custom-badge red">NO</span>
                      </label>
                    </div>
                  )}

                  <div className="form-group link-stand-in ss">
                    <h4>Stand In</h4>
                    <Select
                      closeMenuOnSelect={true}
                      classNamePrefix="select"
                      className="single-select-container"
                      // components={animatedComponents}
                      isClearable
                      isSearchable
                      onChange={(newValue) => handleLinkStandOut(newValue)}
                      // value={cuStandIn  && cuStandIn.value && cuStandIn.value.length > 0 ? [{
                      //   value: cuStandIn.value,
                      //   label: (
                      //     <div style={{fontSize: "14px"}}>
                      //       {cuStandIn.value && isExtPerson(cuStandIn.value) ?
                      //         <span className="custom-badge blue"  >
                      //           EXT
                      //         </span>
                      //         : null}
                      //         {cuStandIn.label}
                      //     </div>
                      //   ),
                      // }] : []}

                      // Added by Indranil on 11-12-2023
                      value={
                        values && values.value && values.value.length > 0
                          ? [
                            {
                              value: values.value,
                              label: (
                                <div style={{ fontSize: "14px" }}>
                                  {values.value &&
                                    isExtPerson(values.value) ? (
                                    <span className="custom-badge blue">
                                      EXT
                                    </span>
                                  ) : null}
                                  {values.label}
                                </div>
                              ),
                            },
                          ]
                          : []
                      }
                      isDisabled={
                        !value || !value.value || value.value.length === 0
                          ? true
                          : false
                      }
                      components={{ Option: CustomOption }}
                      options={options}
                      placeholder="+ Add stand-in"
                    />
                  </div>

                  <div className="form-group member-in">
                    <h4>Member In</h4>
                    <div className="flag">
                      <label>This {/* link */GetAliasesName(teams, "Links").toLowerCase()}  is currently member in:</label>
                    </div>
                    <div
                      className="row"
                      style={{ marginRight: 0, marginLeft: 0 }}
                    >
                      {memberIds && memberIds.length > 0
                        ? memberIds.map((e, index) => {
                          if (
                            !e.memberName ||
                            e.memberName === "" ||
                            e.memberName === null
                          )
                            return;

                          return (
                            <div key={index} className="col-xs-12">
                              {e.memberName}
                              {e.XTD.length > 0 && e.XTD === "XTD" ? (
                                <span
                                  style={{
                                    display: "inlineBlock",
                                    marginBottom: "0",
                                  }}
                                  className="custom-badge lightgray person-pill text-red"
                                >
                                  <span className="text-red">XTD</span>
                                </span>
                              ) : null}
                              <a
                                className="remove"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "60%",
                                }}
                                data-member_list_id="circles"
                              >
                                <span
                                  onClick={() => memberHandel(e)}
                                  className="fa fa-times"
                                ></span>
                              </a>
                            </div>
                          );
                        })
                        : null}
                    </div>
                  </div>

                  <Documents
                    data={data}
                    link={link}
                    setDocumentData={setDocument}
                  />

                  <div className="form-group tags">
                    <h4>Tags</h4>
                    <Tags
                      data={arr}
                      onChange={handleChange}
                      link={link}
                      name="tags"
                    />
                  </div>

                </div>
              </div>
            </div>

            <div className="modal-footer">
              <div className="row">
                <div className="col-xs-4 text-left">
                  {localStorage.getItem("mode") !== "View" && (
                    <Tippy content={<span>Copy This {/* Link */} {GetAliasesName(teams, "Links")}</span>}>
                      <button
                        type="button"
                        onClick={(e) => linkCopy(e)}
                        className="btn btn-mytpt-gray clone"
                        data-id="linksModal"
                      >
                        <img alt="" src="./images/newicons/copy.svg" />
                      </button>
                    </Tippy>
                  )}

                  {localStorage.getItem("mode") !== "View" && link._id ? (
                    <Tippy content={<span>Delete This {/* Link */}{GetAliasesName(teams, "Links")}</span>}>
                      <button
                        type="button"
                        onClick={linksDelete}
                        className="btn btn-mytpt-gray delete"
                        data-id="linksModal"
                        data-post_type="domain"
                      >
                        <img alt="" src="./images/newicons/delete.svg" />
                      </button>
                    </Tippy>
                  ) : null}

                  <Tippy content={<span>View History</span>}>
                    <button
                      type="button"
                      onClick={() => historysGet(link._id)}
                      style={
                        historysMessage.length > 0
                          ? { backgroundColor: "#F3D068" }
                          : null
                      }
                      className="btn btn-mytpt-gray history"
                    >
                      <img alt="" src="./images/newicons/history.svg" />
                    </button>
                  </Tippy>
                </div>
                <div className="col-xs-8 text-right">
                  <Tippy content={<span>Close Tool</span>}>
                    <button
                      type="button"
                      onClick={CloseLinkModal}
                      className="btn btn-mytpt-red"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </Tippy>
                  {localStorage.getItem("mode") !== "View" && (
                    <Tippy content={<span>Save And Close</span>}>
                      <button
                        type="submit"
                        className="btn btn-mytpt save-close"
                        data-modal_id="linksModal"
                      >
                        <i className="fa fa-check"></i>
                      </button>
                    </Tippy>
                  )}
                </div>
              </div>
              {historysMessage.length > 0 ? (
                <div className="row activities">
                  <div className="col-xs-12">
                    <h4>History</h4>
                    {historysMessage.reverse().map((e, index) => {
                      return (
                        <p
                          key={index}
                          dangerouslySetInnerHTML={{ __html: e }}
                        ></p>
                      );
                    })}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
