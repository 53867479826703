import React, { useEffect } from "react";
import { useState, CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux';
import { USER_LOGIN_SUCCESS } from '../../store/types/authType'
import { useNavigate } from "react-router-dom";
import { SyncLoader } from "react-spinners";

var override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export default function Redirect() {
    const { loadings, successMessage, error, authenticate, myInfo } = useSelector(state => state.auth)
    let params = useParams()
    const dispatch = useDispatch();
    const navigate = useNavigate();
    localStorage.setItem('authToken', params.token)
    setTimeout(() => {
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: {
                token: params.token
            }
        })
    }, 2000)

    useEffect(() => {
        if (authenticate) {
            localStorage.setItem('authToken', params.token);
            setTimeout(() => {
                navigate("/");
                window.location.reload()
            }, 1000);
        }
    }, [authenticate])

    // console.log(params)

    return (
        <div className="page-loader">
            <SyncLoader color="#36d7b7" />
        </div>
    )
}
