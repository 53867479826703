import Tippy from "@tippyjs/react";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getuserMetaData } from "../../../store/actions/authAction";
import { useDispatch } from "react-redux";

export default function ReferralModal({ closeReferal }) {
  const { curUserData, userMeta } = useSelector((state) => state.auth);

  // console.log(myInfo, curUserData); 
  const dispatch = useDispatch();
  const [refData, setRefData] = useState({
    vercelRefUrl: null,
    token: null,
    customRefUrl: null,
    id: null,
  });
  const [copyLink, setCopyLink] = useState(false);

  const handleCopy = (e) => {
    e.preventDefault();
    setCopyLink(true);
    // console.log(refData.customRefUrl);
    navigator.clipboard
      .writeText(refData.customRefUrl)
      .then(() => {
        setCopyLink(false);
      })
      .catch((error) => {
        setCopyLink(false);
      });

    // if (isCopied) setCopyLink(true);

    // const timeoutId = setTimeout(() => {
    //   setCopyLink(false);

    //   // Optional: clear the timeout if necessary in case of unmounting
    //   clearTimeout(timeoutId);
    // }, 400);
  };

  useEffect(() => {
    if (userMeta && Object.keys(userMeta).length > 0) {
      const { rewardful } = userMeta;

      if (!rewardful) return;
      const persejson = JSON.parse(rewardful);
      const { links } = persejson;
      const [linkObject] = links;
      const { id, token, url } = linkObject;

      const customUrl = `https://app.teamdecoder.com/register?via=${token}`;

      setRefData({
        customRefUrl: customUrl,
        id,
        token,
        vercelRefUrl: url,
      });
    }
  }, [userMeta]);

  useEffect(() => {
    if (curUserData && Object.keys(curUserData).length > 0) {
      dispatch(getuserMetaData(curUserData._id));
    }
  }, [curUserData]);

  // console.log(refData)

  return (
    <div id="refModal">
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">CUSTOMER REFERRAL PROGRAM</h4>
          </div>
          <div className="modal-body">
            <form className="form">
              <div className="row">
                <div className="col-xs-12">
                  <h4>How does it work?</h4>
                  <div className="content">
                    <p>
                      For every person who creates a paid teamdecoder account
                      using your personal referral link (see below), you'll earn
                      20% credit on all their future purchases. This credit will
                      be used toward your own purchases, making it a rewarding way
                      to share teamdecoder with others.
                    </p>
                  </div>


                  <h4>Your personal link</h4>

                  <div className="admin-seats">
                    <input
                      type="text"
                      value={refData.customRefUrl}
                      className="form-control"
                      readOnly={true}
                    />
                    <button className="btn btn-mytpt"
                      onClick={
                        handleCopy
                      }
                      disabled={userMeta && !userMeta.rewardful ? true : false}
                    >
                      {!copyLink ? `Copy link` : "Copied"}
                    </button>
                  </div>
                  <p className="visit-our">
                    Visit our <b><a href="https://teamdecoder.tawk.help/article/referral-program" target="_blank">knowledge base</a></b> for more details.<br/>
                    <b><a href="mailto:helpdesk@teamdecoder.com">Contact us</a></b> if you are interested in our affiliate program.
                  </p>
                </div>
                {/* <div>
                </div> */}
              </div>
            </form>
          </div>
          <div className="modal-footer">
            <div className="row">
              <div className="col-md-12 text-right">
                <Tippy content={<span>Close Tool</span>}>
                  <button
                    type="button"
                    onClick={closeReferal}
                    data-tippy-content="Close Tool"
                    className="btn btn-mytpt btn-mytpt-red"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-times"></i>
                  </button>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
